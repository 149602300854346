<template>
  <main id="customerinfo">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Coupons
        </h2>
        <h1 style="padding-left: 4px;">
          Coupon Übersicht
        </h1>
        <div class="pad-16" />
        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10">
            <div class="grid-12" id="couponHeadline">
              <div class="col-sp-12 col-tb-8 row-sp-12">
                <h3>Aktuelle Coupons</h3>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <button class="button bgcolor-blue color-white" @click="$router.push({name: 'NotificationService'})">
                  Gutscheine versenden
                </button>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <button class="button bgcolor-blue color-white" @click="$router.push({name: 'Coupon'})">
                  Neu erstellen
                </button>
              </div>
            </div>
            <div class="grid-12" v-if="currentCoupons.length" id="couponOverview">
              <div class="col-sp-12 col-tb-3 row-sp-12">
                <h6>Code</h6>
              </div>
              <div class="col-sp-12 col-tb-3 row-sp-12">
                <h6>Titel</h6>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <h6>Von</h6>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <h6>Bis</h6>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12" />
              <div class="col-sp-12 row-sp-12">
                <coupon-list v-for="coupon in currentCoupons" :coupon="coupon" :key="coupon.id" />
              </div>
            </div>
            <div v-else>
              <p class="t-center">
                Keine aktuellen Coupons
              </p>
            </div>

            <hr>
            <div class="grid-12 couponExpiredHeadline" v-if="expiredCoupons.length">
              <div class="col-sp-12 col-tb-12 row-sp-12">
                <h3 style="margin-bottom: 12px;">
                  Abgelaufene Coupons
                </h3>
              </div>
              <div class="col-sp-12 col-tb-3 row-sp-12">
                <h6>Code</h6>
              </div>
              <div class="col-sp-12 col-tb-3 row-sp-12">
                <h6>Titel</h6>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <h6>Von</h6>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <h6>Bis</h6>
              </div>
              <div class="col-sp-12 row-sp-12">
                <coupon-list v-for="coupon in expiredCoupons" :coupon="coupon" :key="coupon.id" />
              </div>
            </div>
            <div v-else>
              <p class="t-center">
                Keine Abgelaufenen Coupons
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 300px" />
  </main>
</template>

<script>
import {aSyncData, stringToDate} from "@/modules/main";
import CouponList from "@/components/coupons/couponList";
import {configStore} from "@/store/configStore";

export default {
  name: "couponsOverviewView",
  components: {CouponList},
  title: "Coupons",
  setup() {
    const config = configStore()

    return {config}
  },
  data(){
    return {
      coupons: []
    }
  },
  computed: {
    currentCoupons(){
      return this.coupons.filter(c => stringToDate(c.validUntil) >= (new Date()))
    },
    expiredCoupons(){
      return this.coupons.filter(c => stringToDate(c.validUntil) < (new Date()))
    }
  },
  mounted() {
    this.getCoupons()
  },
  methods: {
    getCoupons(){
      aSyncData('coupons/getAll', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250){
              this.coupons = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    }
  }
}
</script>

<style scoped>

  #couponHeadline h3{
    font-size: 1.4em;
  }

  #couponOverview{
    align-items: baseline;
    margin-bottom: 16px;
  }

  .couponExpiredHeadline{
    margin-top: 16px;
  }

  #couponOverview h6, .couponExpiredHeadline h6{
    font-weight: 600;
    padding-bottom: 4px;
  }

  #couponHeadline button{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
    cursor: pointer;
    float: right;
  }

</style>