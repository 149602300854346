<template>
  <div class="grid-12 eventOverview" @click="expanded = !expanded" style="cursor: pointer;">
    <div class="col-sp-12 col-tb-4 row-sp-12">
      {{ (event.eventTitle).length > 25 ? event.eventTitle.substring(0,20) + '...' : event.eventTitle }}
    </div>
    <div class="col-sp-12 col-tb-4 row-sp-12 t-right">
      {{ (-reduced.sales / 100).toFixed(2) }} €
    </div>
    <div class="col-sp-12 col-tb-4 row-sp-12 t-right">
      {{ reduced.lastSale }}
    </div>
  </div>
  <div v-if="expanded" class="eventDetails">
    <hr>
    <div class="grid-12 eventDataHeadline">
      <div class="col-sp-12 col-tb-3 row-sp-12">
        <span>Name</span>
      </div>
      <div class="col-sp-12 col-tb-3 row-sp-12">
        <span>Email</span>
      </div>
      <div class="col-sp-12 col-tb-2 row-sp-12 t-right">
        <span>Umsatz</span>
      </div>
      <div class="col-sp-12 col-tb-3 row-sp-12 t-right">
        <span>Letzter Kauf</span>
      </div>
      <div class="col-sp-1 col-tb-1 row-sp-12" />
    </div>
    <sale-users v-for="customer in event.customer" :key="customer.userId" :customer="customer" @user-info="u => $emit('userInfo', u)" />
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {stringToDate, dateToString} from "@/modules/main";
import SaleUsers from "@/components/sales/saleUsers";

export default {
  name: "SalesByEventComp",
  components: {SaleUsers},
  props: {
    event: Object
  },
  emits: ['userInfo'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    reduced(){
      return {
        'sales': Object.values(this.event.customer).reduce((a, c) => {
          return a + c.sales.reduce((a2, c) => {
            return a2 + c.amount
          }, 0)
        }, 0),
        'lastSale': dateToString(new Date(Math.max(... Object.values(this.event.customer).map(c => {
          return c.lastSale
        }))))
      }
    },
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>
td{
  padding: 8px;
}
td img{
  vertical-align: middle;
}
tr{
  background-color: rgba(0,0,0,0.03);
  border-bottom: 4px solid #ffffff;
}
#editModal .vueModalInner{
  width: calc(100vw - 16px);
  max-width: 500px;
}
#editModal h3{
  font-size: 1.6em;
}
#editModal .field label{
  display: block;
}
#editModal .field input{
  display: block;
  width: calc(100% - 24px);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
#editModal .field select{
  display: block;
  width: calc(100%);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}

.eventOverview{
  margin: 8px 0;
}

.eventDetails{
  background-color: rgba(0,0,0,0.05);
}

.eventDetails span{
  font-weight: 600;
  display: block;
  padding: 6px 0;
}

.eventDataHeadline{
  margin-left: 12px;
}

</style>