<template>
  <main id="customerinfo">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Übersicht
        </h2>
        <h1 style="padding-left: 4px;">
          Umsätze
        </h1>
        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10" v-if="!this.config.loading">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-4 row-sp-12">
                <h3 style="padding-top: 2px;">
                  Übersicht
                </h3>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <form>
                  <div class="field">
                    <select v-model="filterEvent">
                      <option :value="0">
                        Alle Events
                      </option>
                      <option v-for="event in events" :value="event.eventId" :key="event.eventId">
                        {{ eventDateString(event.eventDate) + ' - ' + event.eventTitle }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="col-sp-12 col-tb-12 row-sp-12" v-if="filterEvent === 0">
                <label>Woche</label>
                <input type="radio" v-model="sorting" value="week">
                <label>Monat</label>
                <input type="radio" v-model="sorting" value="month">
                <label>Jahr</label>
                <input type="radio" v-model="sorting" value="year">
                <br>
                <label>Linie</label>
                <input type="radio" v-model="chartType" value="line">
                <label>Balken</label>
                <input type="radio" v-model="chartType" value="bar">
                <br>
                <label>Von</label>
                <input type="date" v-model="start">
                <label>Bis</label>
                <input type="date" v-model="end">
                <line-chart :chart-data="chartData" :options="chartOptions" v-if="chartType === 'line'" />
                <bar-chart :chart-data="chartData" :options="chartOptions" v-else />
              </div>
              <div class="col-sp-12 col-tb-12 row-sp-12" v-else>
                <doughnut-chart :chart-data="chartDataEvent" :options="chartOptions" />
              </div>
            </div>
          </div>
          <loader v-else />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData, stringToDate} from "@/modules/main";
import salesByUserComp from "@/components/sales/salesByUser";
import salesByEventComp from "@/components/sales/salesByEvent";

import { LineChart, BarChart, DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);


export default {
  name: "Sales",
  title: "Umsatzberichte",
  components: {
    LineChart,
    BarChart,
    DoughnutChart
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      sales: [],
      filterEvent: 0,
      sorting: 'week',
      chartType: 'line',
      chartColors: ['#F2A43A', '#f80000', '#ff7f05', '#eaff00', '#ff0077'],
      start: '',
      end: ''
    }
  },
  computed: {
    salesFiltered(){
      const start = stringToDate(this.start)
      const end = stringToDate(this.end)
      return this.sales.filter(s => {
        const current = stringToDate(s.eventDate)
        return current >= start && current <= end
      })
    },
    events(){
      return [...new Map(this.salesFiltered.map(s => [s.eventId, s])).values()]
    },
    chartOptions(){
      let title
      switch (this.sorting) {
        case "week":
          title = 'Wochenansicht'
          break

        case "month":
          title = "Monatsansicht"
          break

        case "year":
          title = "Jahresansicht"
          break
      }
      if(this.filterEvent) title = this.salesFiltered.find(s => s.eventId === this.filterEvent).eventTitle
      return {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
                text: title
          }
        }
      }
    },
    chartData(){
      const map = new Map()
      if(this.sorting === 'week'){
        this.salesFiltered.forEach(s => {
          const key = s.week + '/' + (s.year - 2000)
          if(map.has(key)){
            const entry = map.get(key)
            map.set(key, entry + parseInt(s.saleAmount))
          }else{
            map.set(key, parseInt(s.saleAmount))
          }
        })
      }else if(this.sorting === 'month'){
        this.salesFiltered.forEach(s => {
          const key = s.month + '/' + (s.year - 2000)
          if(map.has(key)){
            const entry = map.get(key)
            map.set(key, entry + parseInt(s.saleAmount))
          }else{
            map.set(key, parseInt(s.saleAmount))
          }
        })
      }else{
        this.salesFiltered.forEach(s => {
          const key = (s.year - 2000)
          if(map.has(key)){
            const entry = map.get(key)
            map.set(key, entry + parseInt(s.saleAmount))
          }else{
            map.set(key, parseInt(s.saleAmount))
          }
        })
      }
      return {
        labels: [...map.keys()],
        datasets: [
          {
            data: [...map.values()],
            label: 'Umsatz',
            borderColor: '#F2A43A',
            backgroundColor: 'rgba(242,164,58,0.4)',
            borderWidth: 2,
            borderRadius: 25,
            borderSkipped: false,
          }
        ],
      }
    },
    chartDataEvent(){
      console.log(this.sales)
      const map = new Map(this.salesFiltered.filter(s => s.eventId === this.filterEvent).map(s => [s.ticketTitle, s.saleAmount]))
      return {
        labels: [...map.keys()],
        datasets: [
          {
            data: [...map.values()],
            label: 'Umsatz',
            backgroundColor: Object.values(this.chartColors),
            borderWidth: 2,
            borderRadius: 25,
            borderSkipped: false,
          }
        ],
      }
    }
  },
  mounted() {
    this.getSales()
  },
  methods: {
    getSales(){
      const _this = this
      aSyncData('sales/getSalesByCategory', {organizerId: this.config.organizerId})
          .then(r => {
            //console.log(r.status, r.data)
            if(r.status === 250){
              _this.sales = r.data.sort((a, b) => {
                    return a.eventDate > b.eventDate
                  })
              const start = stringToDate(this.sales[0].eventDate)
              const end = stringToDate(this.sales[this.sales.length - 1].eventDate)
              _this.start = start.getFullYear() + '-' + String(start.getMonth() +1).padStart(2, '0') + '-' + (start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate())
              _this.end = end.getFullYear() + '-' + String(end.getMonth() +1).padStart(2, '0') + '-' + (end.getDate() < 10 ? ('0' + end.getDate()) : end.getDate())
              _this.config.loading = false
            }
          })
          .catch(e =>{
            console.log(e)
          })
    },
    eventDateString(date){
      const eventDate = stringToDate(date)
      return eventDate.getDate() + '.' + String(eventDate.getMonth() +1).padStart(2, '0')
    },
    download(){
      let csv = ''

      for(let row = 0; row < this.sales.length; row++){
        let keysAmount = Object.keys(this.sales[row]).length
        let keysCounter = 0

        // If this is the first row, generate the headings
        if(row === 0){

          // Loop each property of the object
          for(let key in this.sales[row]){
            // This is to not add a comma at the last cell
            // The '\r\n' adds a new line
            csv += key + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
            keysCounter++
          }
        }

        for(let key in this.sales[row]){
          csv += this.sales[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          keysCounter++
        }

        keysCounter = 0
      }

      let link = document.createElement("a")
      link.setAttribute("href", 'data:text/plain;charset=utf-8,' + encodeURI(csv));
      link.setAttribute("download", "sales.csv");

      link.click();
    }
  }
}
</script>

<style>
#customerinfo h1{
  font-size: 2em;
}
#customerinfo h3{
  font-size: 1.4em;
}
#customerinfo .field input{
  display: block;
  width: calc(100% - 24px);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
</style>