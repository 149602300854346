<template>
  <main id="customerinfo">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Coupons
        </h2>
        <h1 style="padding-left: 4px;">
          Coupon {{ this.couponId ? 'bearbeiten' : 'erstellen' }}
        </h1>
        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10">
            <div class="grid-12" id="couponEdit">
              <div class="col-sp-12 col-tb-10 row-sp-12" />
              <div class="col-sp-12 col-tb-12 row-sp-12">
                <div>
                  <div class="col-sp-12 col-tb-12 row-sp-12 t-right">
                    <div class="headlineAndButton">
                      <div style="cursor: pointer;" class="back backButton" id="back">
                        <router-link to="/coupons" class="color-blue">
                          Abbrechen
                        </router-link>
                      </div>
                    </div>
                  </div>

                  <div class="labelInputWrapper title">
                    <label for="title">Titel</label>
                    <input type="text" v-model="title" name="title" id="title" placeholder="Coupon Name">
                  </div>

                  <div class="labelInputWrapper description">
                    <label for="description">Beschreibung</label>
                    <textarea type="text" v-model="description" name="description" id="description" cols="35" rows="4" placeholder="Beschreibung" />
                  </div>

                  <div class="flex legalAndCodegenerating">
                    <div class="labelInputWrapper hint">
                      <label for="extra">Hinweise</label>
                      <textarea type="text" v-model="extra" name="extra" id="extra" cols="35" rows="1" placeholder="Kleingedrucktes" />
                    </div>

                    <div class="labelInputWrapper">
                      <label for="couponCode">Coupon Code</label>
                      <div class="flex couponcodeGenerating-wrapper">
                        <input type="text" v-model="code" placeholder="Couponcode" name="couponCode" id="couponCode">
                        <button class="button bgcolor-blue color-white" @click="generateCode(8)">
                          Generieren
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="flex period-wrapper">
                    <div class="flex period-fromTo-wrapper">
                      <label>Gültig von</label>
                      <input type="datetime-local" v-model="validFrom">
                    </div>
                    <div class="flex period-fromTo-wrapper">
                      <label>Gültig bis</label>
                      <input type="datetime-local" v-model="validUntil">
                    </div>
                  </div>
                  <div class="pad-8" />
                  <div class="bgcolor-lightgrey pad-16 br-10">
                    <h4>Wert-Details</h4>
                    <div class="details-wrapper">
                      <div class="detailsSum-Wrapper">
                        <div class="radiobuttons-wrapper">
                          <div>
                            <label for="amount">Betrag</label>
                            <input type="radio" v-model="discount.type" value="amount" id="amount">
                          </div>
                          <div>
                            <label for="percent">Prozent</label>
                            <input type="radio" v-model="discount.type" value="percent" id="percent">
                          </div>
                          <div>
                            <label for="xForY">x für y</label>
                            <input type="radio" v-model="discount.type" value="xForY" id="xForY">
                          </div>
                        </div>

                        <div v-if="discount.type !== 'xForY'" class="sum-label">
                          <div class="">
                            <label>{{ discount.type === 'amount' ? 'Betrag' : 'Prozent' }}</label>
                            <input type="number" v-model="discount.value" :step="discount.type === 'amount' ? '0.01' : '1'">{{ discount.type === 'amount' ? '€' : '%' }}
                          </div>
                          <div class="">
                            <label>Mindestbetrag</label>
                            <input type="number" v-model="discount.minAmount" step="0.01">€
                          </div>
                        </div>
                        <div v-else class="sum-label">
                          <input type="number" v-model="discount.x" step="1">
                          <label> für den Preis von </label>
                          <input type="number" v-model="discount.y" step="1"><br>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h4>Wie folgt einlösbar:</h4>
                  <div class="details-wrapper">
                    <div class="detailsTickets-wrapper bgcolor-lightgrey pad-16 br-10">
                      <h4>Events</h4>
                      <div class="events-radio">
                        <div class="event-radio-selection">
                          <input type="radio" v-model="events" value="all" id="eventsAll">
                          <label for="eventsAll">Alle</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="events" value="included" id="EventsIncluded">
                          <label for="EventsIncluded">Einschließen</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="events" value="excluded" id="eventsExcluded">
                          <label for="eventsExcluded">Ausschließen</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="events" value="none" id="eventsNone">
                          <label for="eventsNone">Keine</label>
                        </div>
                      </div>
                      <div class="events-radio-answer">
                        <div class="grid-12" v-if="events === 'included' || events === 'excluded'">
                          <ul class="col-sp-6 col-tb-6 row-sp-12 event-possibilities-list">
                            <h6>Auswahl der Events</h6>
                            <li v-for="event in items.events.filter(e => !eventsList.some(eL => eL.id === e.id))" :key="event.id" @click="addElement(eventsList, event)">
                              {{ event.title + ' - ' + event.startTime.substring(0, 10) }}
                            </li>
                          </ul>
                          <ul class="col-sp-6 col-tb-6 row-sp-12 event-selection-list">
                            <h6>Getroffene Auswahl</h6>
                            <li v-for="event in eventsList" :key="event.id" @click="removeElement(eventsList, event)">
                              {{ event.title + ' - ' + event.startTime.substring(0, 10) }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="details-wrapper">
                    <div class="detailsTickets-wrapper bgcolor-lightgrey pad-16 br-10">
                      <h4>Tickets</h4>
                      <div class="events-radio">
                        <div class="event-radio-selection">
                          <input type="radio" v-model="tickets" value="all" id="ticketsAll">
                          <label for="ticketsAll">Alle</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="tickets" value="included" id="ticketsIncluded">
                          <label for="ticketsIncluded">Einschließen</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="tickets" value="excluded" id="ticketsExcluded">
                          <label for="ticketsExcluded">Ausschließen</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="tickets" value="none" id="ticketsNone">
                          <label for="ticketsNone">Keine</label>
                        </div>
                      </div>

                      <div class="events-radio-answer">
                        <div class="grid-12" v-if="tickets === 'included' || tickets === 'excluded'">
                          <ul class="col-sp-6 col-tb-6 row-sp-12">
                            <h6>Auswahl der Events</h6>
                            <li v-for="ticket in items.tickets.filter(e => !ticketsList.some(eL => eL.id === e.id))" :key="ticket.id" @click="addElement(ticketsList, ticket)">
                              {{ ticket.ticketTitle + ' - ' + ticket.startTime.substring(0, 10) }}
                            </li>
                          </ul>
                          <ul class="col-sp-6 col-tb-6 row-sp-12">
                            <h6>Getroffene Auswahl</h6>
                            <li v-for="ticket in ticketsList" :key="ticket.id" @click="removeElement(ticketsList, ticket)">
                              {{ ticket.ticketTitle + ' - ' + ticket.startTime.substring(0, 10) }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="details-wrapper">
                    <div class="detailsLounges-wrapper bgcolor-lightgrey pad-16 br-10">
                      <h4>Lounges</h4>
                      <div class="events-radio">
                        <div class="event-radio-selection">
                          <input type="radio" v-model="lounges" value="all" id="loungesAll">
                          <label for="loungesAll">Alle</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="lounges" value="included" id="loungesIncluded">
                          <label for="loungesIncluded">Einschließen</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="lounges" value="excluded" id="loungesExcluded">
                          <label for="loungesExcluded">Ausschließen</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="lounges" value="none" id="loungesNone">
                          <label for="loungesNone">Keine</label>
                        </div>
                      </div>

                      <div class="events-radio-answer">
                        <div class="grid-12" v-if="lounges === 'included' || lounges === 'excluded'">
                          <ul class="col-sp-6 col-tb-6 row-sp-12">
                            <h6>Auswahl der Events</h6>
                            <li v-for="lounge in items.lounges.filter(e => !loungesList.some(eL => eL.id === e.id))" :key="lounge.id" @click="addElement(loungesList, lounge)">
                              {{ lounge.title }}
                            </li>
                          </ul>
                          <ul class="col-sp-6 col-tb-6 row-sp-12">
                            <h6>Getroffene Auswahl</h6>
                            <li v-for="lounge in loungesList" :key="lounge.id" @click="removeElement(loungesList, lounge)">
                              {{ lounge.title }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="details-wrapper">
                    <div class="drinks-wrapper bgcolor-lightgrey pad-16 br-10">
                      <h4>Getränke</h4>
                      <div class="events-radio">
                        <div class="event-radio-selection">
                          <input type="radio" v-model="drinks" value="all" id="drinksAll">
                          <label for="drinksAll">Alle</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="drinks" value="included" id="drinksIncluded">
                          <label for="drinksIncluded">Einschließen</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="drinks" value="excluded" id="drinksExcluded">
                          <label for="drinksExcluded">Ausschließen</label>
                        </div>
                        <div class="event-radio-selection">
                          <input type="radio" v-model="drinks" value="none" id="drinksNone">
                          <label for="drinksNone">Keine</label>
                        </div>
                      </div>

                      <div class="events-radio-answer">
                        <div class="grid-12" v-if="drinks === 'included' || drinks === 'excluded'">
                          <ul class="col-sp-6 col-tb-6 row-sp-12">
                            <h6>Auswahl der Getränke</h6>
                            <li v-for="drink in items.drinks.filter(e => !drinksList.some(eL => eL.id === e.id))" :key="drink.id" @click="addElement(drinksList, drink)">
                              {{ drink.drinkName + ' - ' + drink.categoryName + ' - ' + drink.menuName }}
                            </li>
                          </ul>
                          <ul class="col-sp-6 col-tb-6 row-sp-12">
                            <h6>Getroffene Auswahl</h6>
                            <li v-for="drink in drinksList" :key="drink.id" @click="removeElement(drinksList, drink)">
                              {{ drink.drinkName + ' - ' + drink.categoryName + ' - ' + drink.menuName }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="details-wrapper">
                  <div class="plattform-wrapper" style="display: none;">
                    <h4>Plattform</h4>
                    <div class="events-radio">
                      <div class="event-radio-selection">
                        <label for="plattformAll">Überall</label>
                        <input type="radio" v-model="platform" value="all" id="plattformAll">
                      </div>
                      <div class="event-radio-selection">
                        <label for="plattformIncluded">Online</label>
                        <input type="radio" v-model="platform" value="included" id="plattformIncluded">
                      </div>
                      <div class="event-radio-selection">
                        <label for="plattformExcluded">Offline</label>
                        <input type="radio" v-model="platform" value="excluded" id="plattformExcluded">
                      </div>
                    </div>
                  </div>
                  <div class="t-right">
                    <button class="button bgcolor-blue color-white" @click="save" :disabled="!code.length || !title.length || !validFrom || !validUntil" :class="{disabled: !code.length || !title.length || !validFrom || !validUntil}">
                      Speichern
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {aSyncData} from "@/modules/main";
import {configStore} from "@/store/configStore";
import {reactive} from "vue";

export default {
  name: "CouponView",
  title: "Coupon editieren/erstellen",
  props: {
    couponId: {
      type: Number,
      required: false
    }
  },
  setup() {
    const config = configStore()

    return {config}
  },
  data(){
    return{
      error: false,
      items: {},
      code: '',
      title: '',
      description: '',
      extra: '',
      validFrom: new Date().toISOString().slice(0,-8),
      validUntil: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0,-8),
      discount: {
        type: 'amount',
        minAmount: 0,
        value: 5,
        x: 3,
        y: 2
      },
      events: 'all',
      eventsList: [],
      tickets: 'all',
      ticketsList: [],
      lounges: 'all',
      loungesList: [],
      drinks: 'all',
      drinksList: [],
      platform: 'everywhere',
    }
  },
  watch: {
    events(){
      this.eventsList = []
    },
    tickets(){
      this.ticketsList = []
    },
    lounges(){
      this.loungesList = []
    },
    drinks(){
      this.drinksList = []
    },
    validFrom(){
      if(this.validFrom && this.validUntil && this.validFrom >= this.validUntil) this.validFrom = null
    },
    validUntil(){
      if(this.validFrom && this.validUntil && this.validFrom >= this.validUntil) this.validUntil = null
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      aSyncData('coupons/getData', {
        organizerId: this.config.organizerId,
        couponId: this.couponId
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.items.events = r.data.events
              this.items.tickets = r.data.tickets
              this.items.lounges = r.data.lounges
              this.items.drinks = r.data.drinks

              if(this.couponId){
                let coupon = r.data.coupon

                this.code = coupon.code
                this.title = coupon.title
                this.description = coupon.description
                this.extra = coupon.extra
                this.validFrom = coupon.validFrom
                this.validUntil = coupon.validUntil
                this.discount = coupon.data.discount

                this.events = coupon.data.events.type
                this.$nextTick(() => {
                  this.eventsList = this.items.events.filter(e => coupon.data.events.items.includes(e.id))
                })

                this.tickets = coupon.data.tickets.type
                this.$nextTick(() => {
                  this.ticketsList = this.items.tickets.filter(t => coupon.data.tickets.items.includes(t.id))
                })

                this.lounges = coupon.data.lounges.type
                this.$nextTick(() => {
                  this.loungesList = this.items.lounges.filter(l => coupon.data.lounges.items.includes(l.id))
                })

                this.drinks = coupon.data.drinks.type
                this.$nextTick(() => {
                  this.drinksList = this.items.drinks.filter(d => coupon.data.drinks.items.includes(d.id))
                })
              }
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    generateCode(length){
      let code = ''
      let counter = 0
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      while (counter < length) {
        //if(counter && counter%4 === 0)code += '-'
        code += chars.charAt(Math.floor(Math.random() * chars.length))
        counter++
      }
      this.code = code
    },
    addElement(list, element){
      if(!list.some(l => l.id === element.id)) list.push(element)
    },
    removeElement(list, element){
      const index = list.findIndex(l => l.id === element.id)
      if (index > -1) {
        list.splice(index, 1)
      }
    },
    save(){
      this.error = false
      aSyncData('coupons/addOrEdit', {
        couponId: this.couponId,
        organizerId: this.config.organizerId,
        code: this.code,
        title: this.title,
        description: this.description,
        extra: this.extra,
        validFrom: this.validFrom,
        validUntil: this.validUntil,
        data: JSON.stringify({
          discount: this.discount,
          events: {
            type: this.events,
            items: this.eventsList.map(i => i.id)
          },
          tickets: {
            type: this.tickets,
            items: this.ticketsList.map(i => i.id)
          },
          lounges: {
            type: this.lounges,
            items: this.loungesList.map(i => i.id)
          },
          drinks: {
            type: this.drinks,
            items: this.drinksList.map(i => i.id)
          },
          plattform: {
            type: this.platform,
          }
        })
      })
          .then(r => {
            console.log(r.data)
            if(r.status === 250){
              this.$router.push({name: 'CouponsOverview'})
            }else if(r.status === 251){
              this.error = true
            }
          })
    }
  }
}
</script>

<style scoped>

  .flex{
    display: flex;
  }

  .labelInputWrapper{
    display: flex;
    flex-wrap: wrap;
    column-gap: 100%;
  }

  #couponEdit .headlineAndButton{
    justify-content: space-between;
  }

  #couponEdit h4{
    font-size: 1.2em;
    margin-top: 20px;
  }

  #couponEdit .backButton button{
    background-color: unset;
    border: unset;
    font-size: 1em;
    font-weight: 400;
  }

  #couponEdit .title {
    margin-top: 20px;
  }

  #couponEdit .title label{
    font-weight: 400;
    font-size: 1.2em;
    padding-bottom: 10px;
  }

  #couponEdit .title input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  #couponEdit .description{
    margin-top: 20px;
  }

  #couponEdit .description textarea{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  #couponEdit .hint{
    max-width: 50%;
  }

  .legalAndCodegenerating{
    margin-top: 20px;
  }

  #couponEdit .legalAndCodegenerating{
    justify-content: space-between;
    column-gap: 20px;
  }

  #couponEdit .legalAndCodegenerating .labelInputWrapper{
    flex: 1;
  }

  #couponEdit .legalAndCodegenerating input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  #couponEdit .legalAndCodegenerating textarea{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  .couponcodeGenerating-wrapper{
    column-gap: 10px;
    flex: 1;
  }

  #couponEdit .labelInputWrapper .couponcodeGenerating-wrapper input {
    display: block;
    width: calc(100%);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
    flex: 1.3;
  }

  .couponcodeGenerating-wrapper button{
    display: block;
    border-radius: 10px;
    border: none;
    background-color: #2068ff!important;
    color: white;
    padding: 12px;
    margin-bottom: 8px;
    flex: 0.4;
  }

  .period-wrapper{
    column-gap: 20px;
    margin-top: 8px;
  }

  .period-fromTo-wrapper{
    flex-wrap: wrap;
  }

  .period-wrapper input{
    display: block;
    width: calc(100%);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  .details-wrapper{
    margin-top: 8px;
  }

  .detailsSum-Wrapper .radiobuttons-wrapper{
    display: flex;
    flex-wrap: wrap;
    column-gap: 100%;
    row-gap: 4px;
    margin-bottom: 8px;
  }

  .detailsSum-Wrapper .radiobuttons-wrapper div{
    display: flex;
    flex-direction: row-reverse;
    column-gap: 10px;
  }

  .detailsSum-Wrapper .sum-label{
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .detailsSum-Wrapper .sum-label input{

    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  .detailsSum-Wrapper .sum-label div{
    display: flex;
    column-gap: 8px;
    align-items: center;
  }

  .amount input{
    width: 100px;
  }

  .details-wrapper .events-radio{
    margin-bottom: 10px;
    margin-top: 6px;
    display: flex;
    column-gap: 12px;
  }

  .events-radio .event-radio-selection{
    display: flex;
    column-gap: 8px;
  }

  .details-wrapper .events-radio-answer ul li{
    margin: 6px 0;
  }

  .details-wrapper .events-radio-answer h6{
    font-weight: 600;
    padding-bottom: 6px;
  }


  @media (max-width: 576px){

    .legalAndCodegenerating{
      column-gap: 100%;
      flex-wrap: wrap;
    }

    #couponEdit .legalAndCodegenerating{
      column-gap: 100% !important;
      flex-wrap: wrap;
    }

    .period-wrapper{
      column-gap: 100%;
      flex-wrap: wrap;
    }

    #couponSubmit{
      margin-bottom: 34px;
    }

    .details-wrapper .events-radio{
      flex-wrap: wrap;
    }

  }

</style>