<template>
  <div class="grid-12">
    <div class="td col-sp-12 col-tb-3 row-sp-12 pad-8">
      <img :src="config.projectURL + 'assets/images/users/' + userItem.avatar" class="width-32 circular">
      <p>{{ userItem.firstname }} {{ userItem.lastname }}</p>
    </div>
    <div class="td col-sp-12 col-tb-3 row-sp-12 pad-8">
      <p>{{ userItem.email }}</p>
    </div>
    <div class="td col-sp-12 col-tb-3 row-sp-12 pad-8">
      <select v-model="roleId">
        <option v-for="(role, index) in roleItems" :key="index" :value="role.id">
          {{ role.title }}
        </option>
      </select>
    </div>
    <div class="td col-sp-12 col-tb-3 row-sp-12 pad-8 t-right">
      <button class="tiny bgcolor-blue color-white button" style="margin-right: 8px;" @click="saveRole" v-if="userItem.roleId != roleId">
        Speichern
      </button>
      <button class="tiny bgcolor-blue color-white button" @click="removeModal = true">
        X
      </button>
    </div>
  </div>
  <VueModal :show="removeModal" approve="entfernen" cancel="abbrechen" @close="removeModal = false" @cancel="removeModal = false" @approve="removeUser">
    <p class="t-center" style="font-size: 1.2em">
      Bist du sicher, dass du {{ userItem.firstname }} {{ userItem.lastname }} <br>aus {{ config.getCurrentOrganizer.organizerName }} entfernen möchtest?
    </p>
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "userItem",
  props: ["userItem", "roleItems"],
  emits: ['update'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      roleId: this.userItem.roleId,
      removeModal: false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    saveRole(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('userId', this.userItem.id)
      fD.append('roleId', this.roleId)
      fD.append('organizerId', this.config.organizerId)
      this.axios.post('usermanagement/changeRole', fD)
          .then((response) => {
            if(response.status === 250){
              _this.$emit('update')
            }
          })
          .catch(e => {console.log(e)})
    },
    removeUser(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('userId', this.userItem.id)
      fD.append('organizerId', this.config.organizerId)
      this.axios.post('usermanagement/removeUser', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.$emit('update')
            }
          })
          .catch(e => {console.log(e)})
    }
  }
}
</script>

<style scoped>
  .td p{
    display: inline-block;
    padding-top: 8px;
  }
  .td img{
    vertical-align: -8px;
    margin-right: 8px;
  }
  .grid-12{
    background-color: rgba(0,0,0,0.03);
    border-bottom: 4px solid #ffffff;
    border-radius: 10px;
  }
  .td select{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 8px 12px;
  }
  #editModal .vueModalInner{
    width: calc(100vw - 16px);
    max-width: 500px;
  }
  #editModal h3{
    font-size: 1.6em;
  }
  #editModal .field label{
    display: block;
  }
  #editModal .field input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #editModal .field select{
    display: block;
    width: calc(100%);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
</style>