<template>
  <main id="barMenu">
    <div class="content-1400">
      <div class="pad-16">
        <div class="pad-16" />
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Übersicht
        </h2>
        <div class="title-wrapper flex">
          <h1 style="padding-left: 4px;">
            Barkarten
          </h1>
        </div>

        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10">
            <div class="grid-12" id="barmenu-wrapper">
              <div class="col-sp-12 col-sd-12 row-sp-12">
                <h3 style="padding-bottom: 12px; font-size: 1.4em;">
                  Übersicht
                </h3>
                <div class="addBar flex">
                  <input placeholder="Name der Barkarte" type="text" v-model="name" @keyup.enter="addBarMenu">
                  <button class="button" @click="addBarMenu">
                    Hinzufügen
                  </button>
                </div>

                <h3 style="font-size: 1.4em; margin-top:30px;">
                  Auswahl Barkarten
                </h3>
                <div class="grid-12">
                  <div style="cursor: pointer;" class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-4" v-for="barMenu in barMenues" :key="barMenu.id" @click="$router.push({name: 'BarMenuDetails', params: {barMenuId: barMenu.id}})">
                    <div class="singleBarcard pad-12 br-10 t-center">
                      <svg width="48px" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 512 512" data-v-132370e2=""><path d="M154.6 6c40 0 72.6 32.6 72.6 72.6v.4h-21.7v-.4c0-28.1-22.9-50.9-50.9-50.9s-50.9 22.9-50.9 50.9c0 26.7 20.7 48.6 46.8 50.7V151C112.3 148.9 82 117.3 82 78.6 82 38.6 114.5 6 154.6 6z" fill="#2068ff" class="fill-000000" data-v-132370e2=""></path><path d="M330.8 87.6H159.1v138.8c0 62.9 46.7 114.7 106.2 120.7v143.1h-68.6V506h160.4v-15.7h-68.6V347.2c59.5-6 106.2-57.8 106.2-120.7V87.6H344l9.1-44.7c1.5-7.2 6.7-12.2 12.7-12.2H430v-13h-64.2c-12.3 0-22.7 9.3-25.4 22.6l-9.6 47.3zm-7.1 100.2 15.9-78.5H373v75.4l-49.3 3.1zm-111.1 35.3 3-16.8c.5-2.9 3.3-4.8 6.2-4.3l16.8 3c2.9.5 4.8 3.3 4.3 6.2l-3 16.8c-.5 2.9-3.3 4.8-6.2 4.3l-16.8-3c-2.9-.5-4.8-3.3-4.3-6.2zm113.8-113.8-16.1 79.3-14.4.9c1.3 2.6 1.1 5.8-.9 8.2L279.7 216c-2.6 3.1-7.3 3.6-10.4.9L251 201.6c-2.8-2.3-3.4-6.2-1.8-9.2l-68.4 4.2v-87.3h145.6z" fill="#2068ff" class="fill-000000" data-v-132370e2=""></path><path d="M248.6 181.6c-.9 3.2-4.2 5.2-7.4 4.3l-19-5c-3.2-.9-5.2-4.2-4.3-7.4l5-19c.9-3.2 4.2-5.2 7.4-4.3l19 5c3.2.9 5.2 4.2 4.3 7.4l-5 19zM265.3 172.9c2.6-3.1 7.3-3.6 10.4-.9l18.3 15.3c.8.6 1.4 1.4 1.8 2.3l-46.7 2.9c.2-.4.5-.8.8-1.2l15.4-18.4z" fill="#2068ff" class="fill-000000" data-v-132370e2=""></path></svg>
                      <h6 class="f-bold">{{ barMenu.name }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "barMenu",
  title: "Barkarten",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      barMenues: [],
      name: ''
    }
  },
  mounted() {
    this.getBarMenues()
  },
  methods: {
    getBarMenues(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.organizerId)
      this.axios.post('barMenu/getBarMenues', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.barMenues = r.data
            }
          })
    },
    addBarMenu(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.organizerId)
      fD.append('name', this.name)
      this.axios.post('barMenu/addBarMenu', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250){
              /*_this.barMenues.push({id: r.data.id, name: r.data.name})
              _this.name = ''*/
              _this.$router.push({name: 'BarMenuDetails', params: {barMenuId: r.data.id}})
            }
          })
    }
  }
}
</script>

<style scoped>

  .flex {
    display: flex;
  }

  .title-wrapper{
    justify-content: space-between;
    align-items: center;
  }

  .title-wrapper h1{
    font-size: 2em;
  }

  .button {
    background-color: #2068ff;
    color: white;
  }

  .addBar {
    align-items: center;
    column-gap: 8px;
    margin-bottom: 12px;
  }

  .addBar input{
    display: block;
    width: 40%;
    text-align: left;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
  }

  .addBar .button {
    width: 140px;
    padding: 12px 20px !important;
  }

  .selectBarcard-wrapper{
    margin-top: 12px;
    display: flex;
    column-gap: 12px;
    row-gap: 12px;
    flex-wrap: wrap;
  }

  .singleBarcard{
    /*flex: 1;*/
    background-color: rgba(0,0,0,0.05);
  }



</style>