<template>
  <main id="ticketScanner">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Übersicht
        </h2>
        <h1 style="padding-left: 4px;">
          Ticketscanner
        </h1>
        <div class="pad-8" />
        <div class="pad-8">
          <form>
            <div class="field">
              <label style="padding-left: 4px;">Event Auswählen</label>
              <select v-model="eventId" @change="getLastScans(); scannerTools = true;">
                <option value="-1" disabled>
                  Event...
                </option>
                <option v-for="event in events" :key="event.id" :value="event.id">
                  {{ event.title + ' - ' + event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] }}
                </option>
              </select>
            </div>
          </form>
          <div v-if="eventId == -1" class="bgcolor-blue color-white pad-40 br-10 t-center">
            <img src="assets/images/icons/scanner-white.svg" class="width-100">
            <h5 style="font-size: 1.4em;">
              Bitte wähle ein Event aus
            </h5>
          </div>
        </div>
        <div class="grid-12">
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-8">
            <section id="Scanner">
              <transition name="scanned">
                <div id="ticketChecked" class="overlay green" v-if="checkedAnimation">
                  <div class="t-center">
                    <div class="pad-24" />
                    <img src="assets/images/icons/check-white.svg" class="width-100">
                    <h5 style="font-size: 1.5em; margin-bottom: 16px; margin-top: 8px">
                      Ticket ist gültig
                    </h5>
                    <button @click="checkedAnimation = false" class="bgcolor-white color-black button">
                      Okay
                    </button>
                  </div>
                </div>
                <div class="overlay blue" v-else-if="cancelAnimation || startAnimation" />
              </transition>
              <div class="videoContainer">
                <video ref="scanner" />
                <transition name="error" id="error">
                  <div class="t-center error" v-if="error">
                    <div class="pad-24" />
                    <img src="assets/images/icons/error-white.svg" class="width-100">
                    <h5 style="font-size: 1.5em; margin-bottom: 16px; margin-top: 8px">
                      {{ error }}
                    </h5>
                    <button @click="error = false" class="bgcolor-white color-black button">
                      Okay
                    </button>
                  </div>
                </transition>
              </div>
            </section>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-8">
            <section class="bgcolor-white br-10" v-if="eventId != -1">
              <h2 class="pad-16" style="font-size: 1.4em;">
                Letzte Ticket-Scans
              </h2>
              <div style="overflow-x: scroll;">
                <table style="width: 100%;">
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>SecureCode</td>
                      <td>Zeitstempel</td>
                    </tr>
                  </thead>
                  <tbody>
                    <ticketItems v-for="titems in ticketItems" :ticket-items="titems" :key="titems.id" />
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import QrScanner from 'qr-scanner';
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import ticketItems from "@/components/ticketcenter/scans";

export default {
  name: "scanner",
  title: "TicketScanner",
  components: {
    ticketItems: ticketItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      qrScanner: null,
      error: false,
      checkedAnimation: false,
      startAnimation: false,
      cancelAnimation: false,
      ticketItems: [],
      eventId: -1,
      events: [],
      lastScans: [],
      scannerTools: false
    }
  },
  watch: {
    eventId(){
      this.startScanner()
    }
  },
  mounted() {
    const _this = this
    this.initScanner()
    this.getEvents()
  },
  beforeUnmount() {
    try {
      this.qrScanner.stop()
      this.qrScanner.destroy();
      this.qrScanner = null;
    }catch (e) {}
  },
  methods:{
    async initScanner(){
      const _this = this
      return new Promise(resolve => {
        this.qrScanner = new QrScanner(
            this.$refs.scanner,
            _this.scanned,
            {
              highlightScanRegion: true,
              highlightCodeOutline: true
            })
        this.qrScanner.setInversionMode('both');
        resolve()
      })
    },
    startScanner(){
      const _this = this
      this.qrScanner.start()
      this.startAnimation = true
      setTimeout(() => {
        _this.startAnimation = false
      }, 1000)
    },
    cancelScan(){
      const _this = this
      this.cancelAnimation = true
      setTimeout(() => {
        this.stopScanner()
        _this.cancelAnimation = false
      }, 1000)
    },
    stopScanner(){
      try {
        this.qrScanner.stop()
      }catch (e) {}
    },
    scanned(r){
      this.stopScanner()
      const _this = this
      const fD = new FormData()

      fD.append('sessionID', this.auth.sessionID)
      fD.append('secureHash',  btoa(r.data))
      fD.append('eventId',  this.eventId)
      this.axios.post('ticketCenter/scan', fD)
          .then(r => {
            if(r.status === 250) {
              _this.lastScans.push(r.data)
              _this.checkedAnimation = true
              _this.getLastScans()
            }else{
              _this.error = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            setTimeout(() => {
              _this.startScanner()
            }, 1000)
          })
    },
    getEvents(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId',  this.config.organizerId)
      this.axios.post('ticketCenter/getEvents', fD)
          .then(r => {
            console.log(r.data, r.status)
            if(r.status === 250) {
              _this.events = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getLastScans(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId',  this.eventId)
      this.axios.post('ticketCenter/getLastScans', fD)
          .then(r => {
            if(r.status === 250) {
              _this.ticketItems = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    }
  },
}
</script>

<style scoped>
#ticketScanner h1{
  font-size: 2em;
}
#ticketScanner h3{
  font-size: 1.2em;
}
main#ticketScanner{
  padding-bottom: 80px;
}
section#Scanner video{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
#ticketScanner .field select{
  display: block;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: rgba(255,255,255,1);
  padding: 12px;
  margin-bottom: 8px;
}
#Scanner{
  position: relative;
}
#Scanner #ticketChecked{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #00cc66;
  border-radius: 10px;
  z-index: 9;
}
#Scanner #error{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #BD081C;
  border-radius: 10px;
  z-index: 9;
}
.scanned-enter-active,
.scanned-leave-active{
  transition: opacity 1s ease;
}
.scanned-enter-from,
.scanned-leave-to {
  opacity: 0;
}

.error-enter-active{
  animation: bounce-in 0.5s;
}
.error-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
table{
}
table thead{
  background-color: rgba(0,0,0,0.05);
  font-weight: bold;
}
table thead tr td{
  padding: 4px;
}
</style>