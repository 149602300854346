<template>
  <div class="uploadelement col-sp-12 col-tb-4 row-sp-12" v-if="upload.uploaded">
    <div class="uploadelement-box">
      <div class="left-side bgcolor-white">
        <div class="pad-16">
          <div class="imageBox">
            <img :src="config.projectURL + config.eventMediaUploadPath + eventId + '/' + upload.name + '_thumb.jpg'" style="height: 100%; width: 100%" v-if="['jpg', 'jpeg', 'gif', 'png', 'webp'].includes(upload.extension)">
            <video :src="config.projectURL + config.eventMediaUploadPath + eventId + '/' + upload.name + '.' + upload.extension" style="height: 100%; width: 100%" playsinline controls v-else-if="['mp4', 'webm'].includes(upload.extension)" />
          </div>
        </div>
      </div>
      <div class="right-side bgcolor-petrol">
        <ul>
          <li @click="$emit('deleteMedia', upload.name)" class="tooltip">
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0V0z" fill="none" /><path d="M12 38c0 2.2 1.8 4 4 4h16c2.2 0 4-1.8 4-4V14H12v24zm4.93-14.24 2.83-2.83L24 25.17l4.24-4.24 2.83 2.83L26.83 28l4.24 4.24-2.83 2.83L24 30.83l-4.24 4.24-2.83-2.83L21.17 28l-4.24-4.24zM31 8l-2-2H19l-2 2h-7v4h28V8z" fill="#ffffff" class="fill-000000" /><path d="M0 0h48v48H0z" fill="none" /></svg>
            <span class="tooltiptext design2">Bild löschen</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Upload Element - Lädt -->
  <div class="uploadelement col-sp-12 col-tb-4 row-sp-12" v-else>
    <div class="uploadelement-box">
      <div class="loader bgcolor-white">
        <div class="loader-animation">
          <div class="process" :style="{width: upload.progress+'%'}">
            {{ upload.progress }}%
          </div>
        </div>
        <div class="t-center">
          <h6 v-if="upload.progress < 90 && !upload.fail">
            Lädt...
          </h6>
          <h6 v-else-if="upload.fail">
            Upload fehlgeschlagen
          </h6>
          <h6 v-else>
            Verarbeitet...
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "media",
  props: {
    upload: Array,
    eventId: Number
  },
  emits: ['deleteMedia'],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
}
</script>

<style scoped>
.uploadelement{
  position: relative;
  max-height: 200px;
  height: 200px;
  margin-bottom: 32px;
}
.uploadelement-box{
  position: relative;
  margin-right: 16px;
}
.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 8px;
  font-size: 1.2em;
  color: rgba(0,0,0,0.6);
  height: 100%;
}
.loader .loader-animation{
  width: calc(100% - 64px);
  height: 32px;
  position: absolute;
  top: 100px;
  left: 32px;
  background-color: rgba(0,0,0,0.05);
  border: 4px solid #2068ff;
  border-radius: 4px;
}
.loader .loader-animation .process{
  position: absolute;
  color: #ffffff;
  background-color: #2068ff33;
  top: 0;
  left: 0;
  height: 100%;
  text-align: center;
  line-height: 32px;
}
.loader h6{
  position: absolute;
  width: 100%;
  top: 160px;
}
.left-side{
  position: absolute;
  width: calc(100% - 32px);
  left: 0;
  top: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 0.9em;
  color: rgba(0,0,0,0.6);
  height: 220px;
}
.left-side .imageBox{
  overflow: hidden;
  width: 100%;
  height: 180px;
  margin-bottom: 16px;
  text-align: center;
  border-radius: 8px;
}
.left-side .imageBox img{
  width: auto;
  height: 100%;
  max-height: 200px;
  border-radius: 8px;
}

.right-side{
  position: absolute;
  width: 32px;
  right: 0;
  top: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
}
.right-side ul{
  margin: 0;
  padding: 0;
}
.right-side ul li{
  margin: 0;
  padding: 4px;
  list-style: none;
  text-align: center;
  vertical-align: middle;
  padding-right: 8px;
  height: 27px;
  background-color: #2068ff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.right-side ul li:hover{
  background-color: #2068ff;
}
.right-side ul li svg{
  width: 24px;
  cursor: pointer;
  vertical-align: middle;
}
</style>