<template>
  <div v-if="step === 1">
    <section>
      <div class="grid-12">
        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
          <h2 class="color-blue" style="padding-left: 4px; font-size: 1.2em;">
            Promotion Service
          </h2>
          <h1>Übersicht</h1>
        </div>
        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4 t-right">
          <button class="button bgcolor-blue color-white" @click="$router.push({name: 'NotificationService Birthday'})">
            Geburtstags-Benachrichtigung
          </button>
        </div>
      </div>
    </section>
    <div class="pad-8" />
    <section class="pad-4">
      <div class="bgcolor-white br-10 pad-16">
        <h3 style="font-size: 1.4em;" class="pad-16">
          Filter:
        </h3>
        <div class="grid-12">
          <transition-group name="filter-master">
            <transition :name="filterNameBool ? 'filter-left' : 'filter-right'" appear mode="out-in" tag="div" class="col-sp-12 col-sd-6 row-sp-12 pad-4" style="margin: 10px 0">
              <div v-if="!filterNameBool" style="display: flex" @click="filterNameBool = true">
                <div class="addFilter">
                  +
                </div>
                Nach Name
              </div>
              <div class="grid-12 filter" v-else>
                <div class="col-sp-10 col-sd-10 row-sp-12 field simple">
                  <input style="width: 100%; max-width: 100%; margin: unset;" type="text" v-model="filterName" placeholder="Nach Namen">
                </div>
                <div class="addFilter col-sp-2 col-sd-2 row-sp-12" @click="filterNameBool = false">
                  -
                </div>
              </div>
            </transition>
            <transition :name="filterGeoBool ? 'filter-left' : 'filter-right'" appear mode="out-in" tag="div" class="col-sp-12 col-sd-6 row-sp-12 pad-4" style="margin: 10px 0">
              <div v-if="!filterGeoBool" style="display: flex" @click="filterGeoBool = true">
                <span class="addFilter">
                  +
                </span>
                Nach Entfernung
              </div>
              <div class="grid-12 filter" v-else>
                <div class="col-sp-5 col-sd-5 row-sp-12 field simple">
                  <SimpleTypeahead
                    style="width: 100%; max-width: 100%; margin: unset;"
                    id="city_search"
                    placeholder="Suche nach Orten..."
                    :items="cities"
                    :min-input-length="1"
                    :item-projection="item => {return item.name}"
                    @select-item="item => {this.filterCity = item}"
                    @on-input="loadCities"
                  />
                </div>
                <transition name="filter-right">
                  <Slider v-model="filterDistance" :format="sliderFormat" class="col-sp-5 col-sd-5 row-sp-12 slider-yellow" :lazy="lazy" :min="0" :max="500" v-if="filterCity.id" :merge="50" />
                </transition>
                <span @click="filterGeoBool = false" class="addFilter col-sp-2 col-sd-2 row-sp-12">
                  -
                </span>
              </div>
            </transition>
            <transition :name="filterEventBool ? 'filter-left' : 'filter-right'" appear mode="out-in" tag="div" class="col-sp-12 col-sd-6 row-sp-12 pad-4" style="margin: 10px 0">
              <div v-if="!filterEventBool" style="display: flex" @click="filterEventBool = true">
                <div class="addFilter">
                  +
                </div>
                Nach Event
              </div>
              <div class="grid-12 filter" v-else>
                <div class="col-sp-5 col-sd-5 row-sp-12 field simple">
                  <select v-model="filterEvent">
                    <option :value="0">
                      Bitte Event wählen
                    </option>
                    <option v-for="event in events" :value="event.id" :key="event.id">
                      {{ dateString(event.startTime) + ' - ' + event.title }}
                    </option>
                  </select>
                </div>
                <div class="addFilter col-sp-1 col-sd-1 row-sp-12" @click="filterEventBool = false">
                  -
                </div>
                <transition name="filter-master" class="col-sp-6 col-sd-6 row-sp-12" tag="div">
                  <div v-if="!filterSalesByEventBool && filterEvent" style="display: flex" @click="filterSalesByEventBool = true">
                    <span class="addFilter">
                      +
                    </span>
                    Nach Event-Umsatz
                  </div>
                  <div class="grid-12 filter" v-else-if="filterEvent">
                    <Slider v-model="filterSalesByEvent" :format="formatSliderToolTip" class="slider-yellow col-sp-10 col-sd-10 row-sp-12" :lazy="lazy" :min="0" :max="filterSliderMaxValue" :merge="25" />
                    <span @click="filterSalesByEventBool = false" class="addFilter col-sp-2 col-sd-2 row-sp-12">
                      -
                    </span>
                  </div>
                </transition>
              </div>
            </transition>
            <transition :name="filterAverageSalesBool ? 'filter-left' : 'filter-right'" appear mode="out-in" tag="div" class="col-sp-12 col-sd-6 row-sp-12 pad-4" style="margin: 10px 0">
              <div v-if="!filterAverageSalesBool" style="display: flex" @click="filterAverageSalesBool = true">
                <span class="addFilter">
                  +
                </span>
                Nach durchschnittlichen Umsatz
              </div>
              <div class="grid-12" v-else>
                <Slider v-model="filterAverageSales" :format="formatSliderToolTip" class="slider-yellow col-sp-10 col-sd-10 row-sp-12" :lazy="lazy" :min="0" :max="filterSliderMaxValue" :merge="15" />
                <span @click="filterAverageSalesBool = false" class="addFilter col-sp-2 col-sd-2 row-sp-12">
                  -
                </span>
              </div>
            </transition>
            <transition :name="filterSalesBool ? 'filter-left' : 'filter-right'" appear mode="out-in" tag="div" class="col-sp-12 col-sd-6 row-sp-12 pad-4" style="margin: 10px 0">
              <div v-if="!filterSalesBool" style="display: flex" @click="filterSalesBool = true">
                <span class="addFilter">
                  +
                </span>
                Nach Gesamtumsatz
              </div>
              <div class="grid-12 filter" v-else>
                <Slider v-model="filterSales" :format="formatSliderToolTipBig" class="slider-yellow col-sp-10 col-sd-10 row-sp-12" :lazy="lazy" :min="0" :max="filterSliderMaxValue * 4" :merge="60" />
                <span @click="filterSalesBool = false" class="addFilter col-sp-2 col-sd-2 row-sp-12">
                  -
                </span>
              </div>
            </transition>
          </transition-group>
        </div>
      </div>
    </section>
    <section class="t-right pad-8">
      <button class="button bgcolor-blue color-white" @click="step = 2" :disabled="!chosenOnes.length" :class="{disabled: !chosenOnes.length}">
        Weiter
      </button>
    </section>
    <section>
      <div class="grid-12">
        <div class="col-sp-12 col-tb-6 col-sd-6 row-sp-12 pad-4">
          <div class="bgcolor-white br-10 pad-24">
            <div class="grid-12">
              <div class="col-sp-6 row-sp-12">
                <h4 style="font-size: 1.4em;">
                  Nutzerliste:
                </h4>
              </div>
              <div class="col-sp-6 row-sp-12 t-right">
                <div class="tooltip">
                  <button class="addFilter" @click="removeAll" :disabled="!checkRemoveAll" :class="{disabled: !checkRemoveAll}">
                    -
                  </button> <!-- removes all customer in this list from the chosen ones -->
                  <span class="tooltiptext tooltip-right">
                    Alle angezeigten Nutzer von den Ausgewählten entfernen.
                  </span>
                </div>
                <div class="tooltip">
                  <button class="addFilter" @click="choseAll" :disabled="!checkChoseAll" :class="{disabled: !checkChoseAll}">
                    +
                  </button> <!-- adds all customer in this list to the chosen ones -->
                  <span class="tooltiptext tooltip-top">
                    Alle angezeigten Nutzer zu den Ausgewählten hinzufügen.
                  </span>
                </div>
              </div>
            </div>
            <div class="pad-16" />
            <transition-group name="list" tag="ul">
              <li v-for="customerEntry in customerSelection.slice(0, 250)" :key="customerEntry.userId" @click="choseOne(customerEntry)" :class="{alreadyIn: chosenOnes.some(c => c.userId === customerEntry.userId )}" style="cursor: pointer;">
                <div class="grid-12">
                  <div class="col-sp-8 row-sp-12 pad-4">
                    {{ (customerEntry.firstname + ' ' + customerEntry.lastname).length > 30 ? (customerEntry.firstname + ' ' + customerEntry.lastname).substring(0,25)+"..." : customerEntry.firstname + ' ' + customerEntry.lastname }}
                  </div>
                  <div class="col-sp-4 row-sp-12 pad-4 t-right">
                    <div class="tooltip">
                      <span class="tooltiptext tooltip-top">Benachrichtigung via E-Mail{{ customerEntry.mail ? '' : ' nicht' }} möglich</span>
                      <img src="assets/images/icons/mail.png" class="mailPush" :class="{available: customerEntry.mail}">
                    </div>
                    <div class="tooltip">
                      <span class="tooltiptext tooltip-top">Benachrichtigung via Push{{ customerEntry.push ? '' : ' nicht' }} möglich</span>
                      <img src="assets/images/icons/push.png" class="mailPush" :class="{available: customerEntry.push}">
                    </div>
                  </div>
                </div>
              </li>
            </transition-group>
          </div>
        </div>
        <div class="col-sp-12 col-tb-6 col-sd-6 row-sp-12 pad-4">
          <div class="bgcolor-white br-10 pad-24">
            <div class="grid-12">
              <div class="col-sp-6 row-sp-12">
                <h4 style="font-size: 1.4em;">
                  Ausgewählte Nutzer:
                </h4>
              </div>
              <div class="col-sp-6 row-sp-12 t-right">
                <div class="tooltip">
                  <button class="addFilter" @click="chosenOnes = []" :disabled="!chosenOnes.length" :class="{disabled: !chosenOnes.length}">
                    -
                  </button><!-- removes all chosen ones -->
                  <span class="tooltiptext tooltip-top">
                    Alle Ausgewählten entfernen.
                  </span>
                </div>
              </div>
            </div>
            <div class="pad-16" />
            <transition-group name="list" tag="ul">
              <li v-for="chosenOne in chosenOnes" :key="chosenOne.userId" @click="removeOne(chosenOne.userId)" :class="{inCustomerList: customerSelection.some(s => s.userId === chosenOne.userId )}" style="cursor: pointer;">
                <div class="grid-12">
                  <div class="col-sp-8 row-sp-12 pad-4">
                    <!--:class="{inCustomerList: customerSelection.some(s => s.userId === chosenOne.userId )}"-->
                    {{ (chosenOne.firstname + ' ' + chosenOne.lastname).length > 30 ? (chosenOne.firstname + ' ' + chosenOne.lastname).substring(0,25)+"..." : chosenOne.firstname + ' ' + chosenOne.lastname }}
                  </div>
                  <div class="col-sp-4 row-sp-12 pad-4 t-right">
                    <div class="tooltip">
                      <span class="tooltiptext tooltip-top">Benachrichtigung via E-Mail{{ chosenOne.mail ? '' : ' nicht' }} möglich</span>
                      <img src="assets/images/icons/mail.png" class="mailPush" :class="{available: chosenOne.mail}">
                    </div>
                    <div class="tooltip">
                      <span class="tooltiptext tooltip-top">Benachrichtigung via Push{{ chosenOne.push ? '' : ' nicht' }} möglich</span>
                      <img src="assets/images/icons/push.png" class="mailPush" :class="{available: chosenOne.push}">
                    </div>
                  </div>
                </div>
              </li>
            </transition-group>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div v-else-if="step === 2">
    <section>
      <div class="grid-12">
        <div class="col-sp-12 row-sp-12 pad-4">
          <h2 class="color-blue" style="padding-left: 4px; font-size: 1.2em;">
            Nachricht versenden
          </h2>
          <h1>Promotion Center</h1>
        </div>
      </div>
    </section>
    <div class="pad-16" />
    <section class="bgcolor-white">
      <div class="grid-12">
        <div class="col-sp-6 col-sd-6 row-sp-12">
          <div class="grid-12">
            <div @click="mailBool = !mailBool" :class="{active: mailBool}" class="button br-0 color-white col-sp-12 col-sd-12 row-sp-12">
              Mail ({{ chosenOnes.reduce((counter, chosenOne) => {if(chosenOne.mail) counter++; return counter}, 0) }} Nutzer) / Coupons
            </div>
            <div class="col-sp-12 row-sp-12 pad-16">
              <transition name="filter-master">
                <div v-if="mailBool">
                  <div class="pad-16" />
                  <div class="field simple">
                    <label>Nur Coupons zuweisen</label>
                    <input type="checkbox" v-model="couponsOnly">
                  </div>
                  <div v-if="!couponsOnly">
                    <div class="pad-16" />
                    <div class="field simple">
                      <label>E-Mail-Design*</label>
                      <select v-model="mailTemplate" style="max-width: 100%;">
                        <option v-for="template in mailTemplates" :key="template.id" :value="template.id">
                          {{ template.name }}
                        </option>
                      </select>
                    </div>
                    <div class="pad-16" />
                    <div class="field simple">
                      <label for="mailSubject">Betreff*</label>
                      <input type="text" v-model="mailSubject" name="mailSubject" placeholder="Betreff..." style="max-width: calc(100% - 16px);">
                    </div>
                    <div class="pad-16" />
                    <div class="field simple">
                      <label for="mailGreeting">Begrüßung*</label>
                      <input type="text" v-model="mailGreeting" name="mailGreeting" placeholder="Hallo... " style="max-width: calc(100% - 16px);">
                    </div>
                    <div class="pad-16" />
                    <div class="field simple">
                      <label for="mailBody">Text*</label>
                      <textarea v-model="mailBody" name="mailBody" style="max-width: calc(100% - 16px);" placeholder="Text..." />
                    </div>
                    <div class="pad-16" />
                    <div class="field simple">
                      <label for="mailEnding">Abschieds-Grußformel*</label>
                      <textarea v-model="mailEnding" name="mailEnding" style="max-width: calc(100% - 16px);" placeholder="Dein Nachtaktivevents Team..." />
                    </div>
                    <div class="pad-16" />
                    <div class="field simple">
                      <label for="mailEvents">Events</label>
                      <select v-model="mailEvents" multiple name="mailEvents" style="max-width: 100%;">
                        <option v-for="event in events" :value="event.id" :key="event.id">
                          {{ dateString(event.startTime) + ' - ' + event.title }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="pad-16" />
                  <div class="field simple">
                    <label for="mailCoupons">Coupons</label>
                    <select v-model="mailCoupons" multiple name="mailCoupons" style="max-width: 100%;">
                      <option v-for="coupon in coupons" :key="coupon.id" :value="coupon.id">
                        {{ coupon.title + ' - ' + coupon.code }}
                      </option>
                    </select>
                  </div>
                  <div v-if="mailCoupons.length">
                    <div class="pad-16" />
                    <button class="button tiny bgcolor-blue color-white" @click="genPush()">
                      Push-Nachricht generieren
                    </button>
                  </div>


                  <div v-if="!couponsOnly">
                    <div class="pad-16" />
                    <div class="field simple">
                      <label for="mailAttachment">Anhang</label>
                      <input type="file" ref="mailAttachment" @change="onFileChange" accept="application/pdf,image/*" style="max-width: calc(100% - 16px);">
                      <button v-if="mailAttachment" @click="$refs.mailAttachment.value = null; mailAttachment = null">
                        Datei löschen
                      </button>
                    </div>
                    <div>
                      <div class="pad-16" />
                      <button class="button tiny bgcolor-blue color-white" @click="mailPreview" :disabled="!checkPreview" :class="{disabled: !checkPreview}">
                        E-Mail Vorschau
                      </button>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="col-sp-6 col-sd-6 row-sp-12">
          <div class="grid-12">
            <div @click="pushBool = chosenOnes.reduce((counter, chosenOne) => {if(chosenOne.push) counter++; return counter}, 0) ? !pushBool : false" :class="{active: pushBool}" class="button color-white col-sp-12 col-sd-12 row-sp-12 br-0">
              Push ({{ chosenOnes.reduce((counter, chosenOne) => {if(chosenOne.push) counter++; return counter}, 0) }} Nutzer)
            </div>
            <div class="col-sp-12 row-sp-12 pad-16">
              <transition name="filter-master">
                <div v-if="pushBool">
                  <div class="pad-16" />
                  <div class="field simple">
                    <label for="pushTitle">Titel*</label>
                    <input type="text" v-model="pushTitle" name="pushTitle" maxlength="64" placeholder="Title (max. 64 Zeichen)" style="max-width: calc(100% - 16px);">
                  </div>
                  <div class="pad-16" />
                  <div class="field simple">
                    <label for="pushBody">Text*</label>
                    <input type="text" v-model="pushBody" name="pushBody" maxlength="256" placeholder="Text (max. 256 Zeichen)" style="max-width: calc(100% - 16px);">
                  </div>
                  <div class="pad-16" />
                  <div class="field simple">
                    <label for="pushLink">Link</label>
                    <input type="text" v-model="pushLink" name="pushLink" placeholder="http://www....." style="max-width: calc(100% - 16px);">
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="col-sp-12 row-sp-12 pad-16" />
        <button @click="step = 1" class="col-sp-6 col-sd-6 row-sp-12 bgcolor-black color-white button br-0">
          Zurück
        </button>
        <button @click="send" class="col-sp-6 col-sd-6 row-sp-12 bgcolor-blue color-white button br-0" :disabled="!checkSend" :class="{disabled: !checkSend}">
          Senden
        </button>
      </div>
    </section>
  </div>
  <VueModal :show="mailPreviewModal" approve="schließen" @approve="mailPreviewModal = false" @close="mailPreviewModal = false" min-size="medium" max-size="large">
    <div v-html="mailPreviewHTML" />
  </VueModal>
  <VueModal :show="sendModal" approve="schließen" @approve="$router.go()" @close="$router.go()">
    Deine Nachrichten wurden gesendet.
  </VueModal>
</template>

<script>
import {aSyncData, calcDistance, stringToDate} from "@/modules/main"
import {authStore} from "@/store/authStore"
import {configStore} from "@/store/configStore"
import {userStore} from "@/store/userStore"
import Slider from '@vueform/slider'
import SimpleTypeahead from "vue3-simple-typeahead";

export default {
  name: "NotificationServiceManual",
  components: {
    Slider,
    SimpleTypeahead,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      sendModal: false,
      step: 1,
      lazy: true,
      filterSliderMaxValue: 251,
      filterNameBool: false,
      filterName: '',
      filterEventBool: false,
      filterEvent: 0,
      filterSalesByEventBool: false,
      filterSalesByEvent: [0, 10000],
      filterAverageSalesBool: false,
      filterAverageSales: [0, 10000],
      filterSalesBool: false,
      filterSales: [0, 10000],
      filterGeoBool: false,
      filterCitySearchString: '',
      filterCity: {},
      filterDistance: [0, 50],
      sliderFormat: {suffix: 'km', decimal: 0},
      cities: [],
      events: [],
      coupons: [],
      geoPositions: [],
      sales: [],
      customer: [],
      chosenOnes: [],
      couponsOnly: false,
      mailPreviewModal: false,
      mailPreviewHTML: '',
      mailBool: false,
      mailTemplate: 1,
      mailTemplates: [],
      mailEvents: [],
      mailCoupons: [],
      mailSubject: '',
      mailGreeting: '',
      mailBody: '',
      mailEnding: '',
      mailAttachment: null,
      pushBool: false,
      pushTitle: '',
      pushBody: '',
      pushLink: this.config.projectURLApp,
      maxListCount: 200
    }
  },
  computed: {
    customerSelection(){
      const _this = this
      let unique = []
      let ok = true

      let candidates = this.customer.filter(c => {
        if(unique.includes(c.userId) || unique.length >= this.maxListCount) return false

        //filter by search string
        if(this.filterNameBool && !(c.firstname + ' ' + c.lastname).toLowerCase().includes(this.filterName.toLowerCase())) return false

        //filter by event
        if(this.filterEventBool && this.filterEvent && c.eventId !== this.filterEvent) return false

        //filter by total sales
        if(this.filterSalesBool && (this.filterSales[0] > 0 || this.filterSales[1] !== this.filterSliderMaxValue * 4) && this.sales.has(c.userId) && (this.sales.get(c.userId).sales < _this.filterSales[0] || (this.sales.get(c.userId).sales > _this.filterSales[1] && _this.filterSales[1] < _this.filterSliderMaxValue * 4))) return false

        //filter by average sales
        if(this.filterAverageSalesBool && (this.filterAverageSales[0] > 0 || this.filterAverageSales[1] !== this.filterSliderMaxValue) && this.sales.has(c.userId) && (this.sales.get(c.userId).average < _this.filterAverageSales[0] || (this.sales.get(c.userId).average > _this.filterAverageSales[1] && _this.filterAverageSales[1] < _this.filterSliderMaxValue))) return false

        //filter by event sales
        if(this.filterSalesByEventBool && this.filterEvent && (this.filterSalesByEvent[0] !== 0 || this.filterSalesByEvent[1] !== this.filterSliderMaxValue) && (c.sales < _this.filterSalesByEvent[0] || (c.sales > _this.filterSalesByEvent[1] && _this.filterSalesByEvent[1] < _this.filterSliderMaxValue))) return false


        //filter by distance
        if(this.filterGeoBool && this.filterCity.id){
            try {
              ok = !_this.geoPositions.filter(gP => {
                return gP.userId === c.userId
              }).some(c => {
                let distance = calcDistance(c.lat, c.lon, _this.filterCity.lat, _this.filterCity.lon)
                return distance >= _this.filterDistance[0] && distance <= _this.filterDistance[1]
              })
            }catch (e) {
              return false
            }
        }
        if(ok) unique.push(c.userId)
        return ok
      })

      //remove double entries
      //return [...new Map(candidates.map(c => [c.userId, c])).values()]
      return candidates
    },
    checkChoseAll(){
      return this.customerSelection.some(s => !this.chosenOnes.some(c => c.userId === s.userId))
    },
    checkRemoveAll(){
      return this.customerSelection.some(s => this.chosenOnes.some(c => c.userId === s.userId))
    },
    checkSend(){
      return (this.mailBool || this.pushBool) && (!this.mailBool || (this.mailSubject.length && this.mailGreeting.length && this.mailBody.length && this.mailEnding.length && !this.couponsOnly) || (this.couponsOnly && this.mailCoupons.length)) && (!this.pushBool || (this.pushTitle.length && this.pushBody.length))
    },
    checkPreview(){
      return this.mailGreeting.length && this.mailBody.length && this.mailEnding.length
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      const _this = this
      aSyncData('notificationService/getData', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250){
              _this.events = r.data.events
              _this.coupons = r.data.coupons
              _this.mailTemplates = r.data.mailTemplates
              _this.geoPositions = r.data.geoPositions
              _this.sales = new Map(r.data.sales.map(s => [s.userId, s]))
              _this.customer = r.data.customer
              _this.lazy = r.data.customer.length > 1000
            }
          })
    },
    dateString(eventDate){
      let date = stringToDate(eventDate)
      return date.getDate() + '.' + (date.getMonth()+1)
    },
    formatSliderToolTip(position){
        return position >= this.filterSliderMaxValue ? '&infin;' : Math.round(position) + '€'
    },
    formatSliderToolTipBig(position){
      return position >= this.filterSliderMaxValue * 4 ? '&infin;' : Math.round(position) + '€'
    },
    choseOne(chosenOne){
      /*
      if(!chosenOne.push && !chosenOne.mail){
        alert('Weder E-Mail noch Push möglich')
        return
      }
      */

      if(!this.chosenOnes.some(e => e.userId === chosenOne.userId)) {
        this.chosenOnes.push(chosenOne)
      }else{
        this.removeOne(chosenOne.userId)
      }
    },
    choseAll(){
      this.customerSelection.forEach(c => {
        if((c.push || c.mail) && !this.chosenOnes.some(e => e.userId === c.userId)) this.chosenOnes.push(c)
      })
    },
    removeOne(id){
      this.chosenOnes = this.chosenOnes.filter(e => e.userId !== id)
    },
    removeAll(){
      const _this = this
      this.chosenOnes = this.chosenOnes.filter(e => !_this.customerSelection.some(c => c.userId === e.userId))
    },
    loadCities(event){
      const _this = this
      this.filterCitySearchString = event.input
      aSyncData('events/cities', {search: event.input})
          .then(r => {
            if(r.status === 250 && _this.filterCitySearchString === event.input) _this.cities = r.data
          })
          .catch(e => {console.error(e)})
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.mailAttachment = files[0]
    },
    genPush(){
      this.pushBool = true
      this.pushTitle = 'Neue Gutscheine!'
      this.pushBody = 'Wir haben neue Gutscheine für dich.'
      this.pushLink = this.config.projectURLApp + 'coupons'
    },
    mailPreview(){
      const _this = this
      aSyncData('notificationService/mailPreview', {
        organizerId: this.config.organizerId,
        mailTemplate: this.mailTemplate,
        mailGreeting: this.mailGreeting,
        mailBody: this.mailBody,
        mailEnding: this.mailEnding,
        mailEvents: JSON.stringify(this.mailEvents),
        mailCoupons: JSON.stringify(this.mailCoupons)
      })
          .then(r => {
            if(r.status === 250){
              _this.mailPreviewHTML = r.data
              _this.mailPreviewModal = true
            }else{
              console.log(r)
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    send(){
      const _this = this
      aSyncData('notificationService/send', {
        organizerId: this.config.organizerId,
        chosenOnes: JSON.stringify(this.chosenOnes),
        couponsOnly: this.couponsOnly,
        mailBool: this.mailBool,
        pushBool: this.pushBool,
        mailSubject: this.mailSubject,
        mailTemplate: this.mailTemplate,
        mailGreeting: this.mailGreeting,
        mailBody: this.mailBody,
        mailEnding: this.mailEnding,
        mailEvents: JSON.stringify(this.mailEvents),
        mailCoupons: JSON.stringify(this.mailCoupons),
        mailAttachment: this.mailAttachment,
        pushTitle: this.pushTitle,
        pushBody: this.pushBody,
        pushLink: this.pushLink,
      },{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(r => {
            console.log(r)
            _this.sendModal = true
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
//TODO https://github.com/vueform/slider#multiple-slider css
</script>

<style scoped>

#notificationService h1{
  font-size: 2em;
}

@import "@vueform/slider/themes/default.css";
.filter-master-leave-from,
.filter-right-enter-from,
.filter-left-leave-to{
  transform: translateX(-200px)
}

.filter-master-enter-to,
.filter-right-leave-to,
.filter-left-enter-from{
  transform: translateX(200px)
}

.filter-master-move,
.filter-master-enter-active,
.filter-master-leave-active,
.filter-left-move,
.filter-left-enter-active,
.filter-left-leave-active,
.filter-right-move,
.filter-right-enter-active,
.filter-right-leave-active {
  transition: transform .3s ease,
  opacity .3s ease
}

.filter-master-leave-active{
  position: absolute;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease!important;
}

.list-enter-from,
.list-leave-to {
  opacity: 0!important;
  transform: translateX(30px)!important;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

.removeFilter,
.addFilter{
  width: 24px;
  height: 24px;
  background-color: rgba(0,0,0,0.05);
  border: none;
  margin: 0 4px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-top: -4px;
  padding-top: 2px;
  vertical-align: middle;
}
.slider-yellow {
  --slider-connect-bg: #2068ff;
  --slider-tooltip-bg: #2068ff;
  --slider-handle-ring-color: rgba(32, 104, 255, 0.19);
  margin: 0 20px;
}
.removeChosen{
  padding: 0 5px;
  background-color: grey;
  text-align: center;
}
.alreadyIn{
  opacity: 0.5;
}
.inCustomerList{
  color: #2068ff;
}
.active{
  background-color: #2068ff;
}
button, .button{
  text-align: center;
}
.mailPush{
  opacity: 0.4;
  width: 24px;
}
.available{
  opacity: 1;
}
</style>