<template>

  <div style="margin: 24px 0;" class="category-overview">
    <div class="grid-12" id="barmenu-wrapper">
      <div class="col-sp-12 col-tb-6 col-sd-6 row-sp-12 ">
        <input type="text" v-model="name" @input="$emit('editCategory', category.id, name)" class="transparent">
      </div>
      <div class="col-sp-12 col-tb-6 col-sd-6 row-sp-12">
        <div class="categoryOrder flex">
          <div @click="$emit('listOrder', index, true)" class="button border-radius" :disabled="!index" :class="{disabled: !index}">
            nach oben
          </div>
          <div @click="$emit('listOrder', index, false)" class="button border-radius" :disabled="index + 1 >= amount" :class="{disabled: index + 1 >= amount}">
            nach unten
          </div>
          <div @click="deleteModal = true" class="button border-radius" style="background-color: #2068ff!important; color: white;">
            X
          </div>
        </div>
      </div>
    </div>

  </div>
  <div style="margin: 20px">
    <div class="grid-12" style="column-gap: 6px; margin-bottom: 12px;">
      <div class="col-sp-12 col-sd-2 col-tb-2 row-sd-12 row-sp-12 barcardTitle">Name</div>
      <div class="col-sp-12 col-sd-4 col-tb-4 row-sd-12 row-sp-12 barcardTitle">Beschreibung</div>
      <div class="col-sp-12 col-sd-1 col-tb-1 row-sd-12 row-sp-12 barcardTitle">Maßangabe</div>
      <div class="col-sp-12 col-sd-1 col-tb-2 row-sd-12 row-sp-12 barcardTitle"></div>
      <div class="col-sp-12 col-sd-4 col-tb-4 row-sd-12 row-sp-12 barcardTitle">Preis</div>

    </div>
    <div class="productsInputfields" v-for="(item, index1) in items" :key="item.id">
      <div class="grid-12" style="column-gap: 6px;">
        <div class="col-sp-12 col-sd-2 row-sd-12 row-sp-12">
          <input type="text" v-model="item.name" placeholder="Name" @change="editBarItem(item.id)">
        </div>
        <div class="col-sp-12 col-sd-4 row-sd-12 row-sp-12">
          <input type="text" v-model="item.caption" placeholder="Beschreibung" @change="editBarItem(item.id)">
        </div>
        <div class="col-sp-6 col-sd-1 row-sd-12 row-sp-12">
          <input type="number" v-model="item.amount" placeholder="Maßangabe" @change="editBarItem(item.id)">
        </div>
        <div class="col-sp-6 col-sd-1 row-sd-12 row-sp-12">
          <select v-model="item.unit" @change="editBarItem(item.id)">
            <option>ml</option>
            <option>cl</option>
            <option>l</option>
            <option>g</option>
          </select>
        </div>
        <div class="col-sp-12 col-sd-1 row-sd-12 row-sp-12">
          <input type="text" v-model="item.price" placeholder="€" @change="editBarItem(item.id)">
        </div>
        <div class="col-sp-4 col-sd-1 row-sd-12 row-sp-12" @click="listOrder(index1, false)" :disabled="items.length - 1 === index1" :class="{disabled: items.length - 1 === index1}">
          <button>runter</button>
        </div>
        <div class="col-sp-4 col-sd-1 row-sd-12 row-sp-12" @click="listOrder(index1, true)" :disabled="!index1" :class="{disabled: !inde1}">
          <button>hoch</button>
        </div>
        <div class="col-sp-4 col-sd-1 row-sd-12 row-sp-12" @click="deleteBarItem(item.id)" >
          <button style="background-color: #2068ff; color: white;">x</button>
        </div>
      </div>
    </div>
    <h3 style=" font-weight: 600; font-size: 0.9em; margin-bottom: 6px">Hinzufügen</h3>
    <div class="grid-12 newProductsInputfields" style="column-gap: 6px;">
      <div class="col-sp-12 col-sd-2 row-sd-12 row-sp-12">
        <input type="text" v-model="itemName" placeholder="Name">
      </div>
      <div class="col-sp-12 col-sd-4 row-sd-12 row-sp-12">
        <input type="text" v-model="itemCaption" placeholder="Beschreibung">
      </div>
      <div class="col-sp-6 col-sd-1 row-sd-12 row-sp-12">
        <input type="number" v-model="itemAmount" placeholder="Maßangabe">
      </div>
      <div class="col-sp-6 col-sd-1 row-sd-12 row-sp-12">
        <select v-model="itemUnit">
          <option>ml</option>
          <option>cl</option>
          <option>l</option>
          <option>g</option>
        </select>
      </div>
      <div class="col-sp-12 col-sd-1 row-sd-12 row-sp-12">
        <input type="text" v-model="itemPrice" placeholder="€" @keyup.enter="addBarItem()">
      </div>
      <div class="col-sp-1 col-sd-1 row-sd-12 row-sp-12"></div>
      <div class="col-sp-12 col-sd-2 row-sd-12 row-sp-12" @click="addBarItem()" >
        <button class="button">Hinzufügen</button>
      </div>
    </div>
  </div>
  <hr class="separator">
  <VueModal :show="deleteModal" approve="löschen" cancel="abbrechen" @close="deleteModal = false" @cancel="deleteModal = false" @approve="$emit('deleteCategory', category.id)">
    Bist du sicher, dass du die Kategorie inkl. aller Getränke löschen möchtest?
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "barCategory",
  props: {
    category: Object,
    index: Number,
    amount: Number
  },
  emits: ['deleteCategory', 'editCategory', 'listOrder'],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      items: [],
      name: this.category.name,
      deleteModal: false,
      itemName: '',
      itemCaption: '',
      itemUnit: 'ml',
      itemAmount: null,
      itemPrice: null
    }
  },
  mounted() {
    this.getBarItems()
  },
  methods: {
    getBarItems(){
      aSyncData('barMenu/getBaritemsByBarCategory', {barCategoryId: this.category.id})
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              r.data.forEach(i => {
                i.price = i.price.toFixed(2)
                this.items.push(i)
              })
              this.items = r.data.sort((a, b) => {return a.listOrder - b.listOrder})
            }
          })
    },
    addBarItem() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barCategoryId', this.category.id)
      fD.append('name', this.itemName)
      fD.append('caption', this.itemCaption)
      fD.append('unit', this.itemUnit)
      fD.append('amount', this.itemAmount)
      fD.append('price', this.itemPrice.replace(',', '.'))
      this.axios.post('barMenu/addBarItem', fD)
          .then(r => {
            if(r.status === 250){
              r.data.price = r.data.price.toFixed(2)
              _this.items.push(r.data)
              _this.itemName = ''
              _this.itemCaption = ''
              _this.itemUnit = 'ml'
              _this.itemAmount = null
              _this.itemPrice = null
            }
          })
    },
    deleteBarItem(barItemId){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barItemId', barItemId)
      this.axios.post('barMenu/deleteBarItem', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.items =_this.items.filter(c => {
                return c.id !== barItemId
              })
            }
          })
    },
    editBarItem(barItemId){
      const item = this.items.find(i => i.id === barItemId)
      console.log(item)
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barItemId', barItemId)
      fD.append('name', item.name)
      fD.append('caption', item.caption)
      fD.append('unit', item.unit)
      fD.append('amount', item.amount)
      fD.append('price', item.price.replace(',', '.'))
      this.axios.post('barMenu/editBarItem', fD)
          .then(r => {
            console.log(r)
          })
    },
    listOrder(index, moveUp){
      for(let i = 0; i < this.items.length; i++ ){
        if(!moveUp && i === index){
          this.items[i].listOrder = i + 1;
        }else if(!moveUp && (i - 1) === index){
          this.items[i].listOrder = i - 1;
        }else if(moveUp && (i + 1) === index){
          this.items[i].listOrder = i + 1;
        }else if(moveUp && i === index){
          this.items[i].listOrder = i - 1;
        }else{
          this.items[i].listOrder = i;
        }
      }
      this.items = this.items.sort((a, b) => {return a.listOrder - b.listOrder})

      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barItems', JSON.stringify(this.items))
      this.axios.post('barMenu/changeOrderOfBarItems', fD)
    }
  }
}
</script>

<style scoped>
  .border-radius{
    border-radius: 5px;
  }

  .category-overview{
    justify-content: space-between;
  }

  .category-overview input{
    display: block;
    width: 100%;
    text-align: left;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    flex: 1;
  }

  .categoryOrder{
    justify-content: flex-end;
    text-align: center;
    column-gap: 10px;
  }

  .categoryOrder .button{
    background-color: rgba(0,0,0,0.05) !important;
  }

  .separator {
    opacity: 0.3;
  }

  .productsInputfields{
    margin: 6px 0;

  }

  .productsInputfields input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
  }

  .productsInputfields select{
    width: 100%;
    display: block;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  .newProductsInputfields input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
  }

  .newProductsInputfields select {
    width: 100%;
    display: block;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  .newProductsInputfields .button {
    background-color: #2068ff;
    color: white;
    width: 100%;
  }

  .productsInputfields .disabled {
    background-color: unset !important;
  }

  .productsInputfields button{
    display: block;
    width: 100%;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
  }

  .barcardTitle{
    font-weight: 600;
    font-size: 0.9em;
  }


  @media (max-width: 991px) {

    #barmenu-wrapper input{
      width: calc(100% - 24px);
      margin-bottom: 12px;
    }

    .productsInputfields select, .productsInputfields input, .productsInputfields button, .newProductsInputfields select, .newProductsInputfields input, .newProductsInputfields button{
      margin-bottom: 8px;
    }

    .newProductsInputfields .button{
      width: 100%;
    }

  }
</style>