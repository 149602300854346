<template>
  <div class="grid-12 userInformation">
    <div class="col-sp-12 col-tb-2 row-sp-12">
      {{ (event.eventTitle).length > 25 ? event.eventTitle.substring(0,20) + '...' : event.eventTitle }}
    </div>
    <div class="col-sp-12 col-tb-3 row-sp-12">
      {{ eventStart }}
    </div>
    <div class="col-sp-12 col-tb-3 row-sp-12">
      {{ lastSale }}
    </div>
    <div class="col-sp-12 col-tb-2 row-sp-12">
      {{ (-1 * event.amount / 100).toFixed(2) }} €
    </div>
    <div class="col-sp-12 col-tb-2 row-sp-12">
      {{ typ }}
    </div>
  </div>
</template>

<script>
import {dateToString, stringToDate} from "@/modules/main";
//import Transaction from "@/components/sales/transaction";

export default {
  name: "saleEvents",
  //components: {Transaction},
  props: {
    event: Object
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    eventStart(){
      return dateToString(stringToDate(this.event.eventDate))
    },
    lastSale(){
      return dateToString(stringToDate(this.event.transactionDate))
    },
    typ(){
      try {
        const meta = JSON.parse(this.event.meta)
        switch (meta.type) {
          case 'drinks':
            return 'Getränke'

          case 'tickets':
            return 'Tickets'

          case 'lounges':
            return 'Lounge Reservierung'

          case 'tip':
            return 'Trinkgeld'

          default:
            return 'Sonstiges'
        }
      }catch (e)  {
        return 'Sontiges'
      }
    }
  }
}
</script>

<style scoped>

.userInformation{
  margin: 4px 0;
}

  @media (max-width: 600px){
    .userInformation{
      margin: 4px 0 12px;
    }
  }


</style>