<template>
  <main id="customerinfo">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Übersicht
        </h2>
        <h1 style="padding-left: 4px;">
          Umsätze & Kundeninformationen
        </h1>
        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10" v-if="!this.config.loading">
            <div class="grid-12" id="overviewAndSearch">
              <div class="col-sp-12 col-tb-8 row-sp-12">
                <h3 style="padding-top: 2px;">
                  Übersicht
                </h3>
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 t-right">
                <form>
                  <div class="field">
                    <input type="search" placeholder="Suche nach Kunden..." v-model="filterUser" style="float: right; width: 100%;">
                  </div>
                </form>
              </div>
            </div>
            <div class="grid-12 grid-end" id="selectorAndPeriod">
              <div class="col-sp-12 col-tb-4 row-sp-12">
                <form>
                  <div class="field" id="partyselector">
                    <label>Auswahl der Party:</label>
                    <select v-model="filterEvent">
                      <option value="0">
                        Alle
                      </option>
                      <option v-for="event in events" :value="event.id" :key="event.id">
                        {{ event.title }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12">
                <div class="grid-12">
                  <div class="col-sp-12 col-tb-12 row-sp-12">
                    <div class="flex" id="flex-selectPeriod">
                      <form>
                        <div class="field">
                          <label>Von:</label>
                          <input type="date" v-model="filterStart" :min="new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().substring(0, 10)">
                        </div>
                      </form>
                      <form>
                        <div class="field">
                          <label>Bis:</label>
                          <input type="date" v-model="filterEnd" :max="new Date().toISOString().substring(0, 10)">
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12" />
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <div class="field" id="downloadButton">
                  <button @click="download" class="button" style="float: right;">
                    Download
                  </button>
                </div>
              </div>
            </div>
            <div class="grid-12" id="groupSelector">
              <div class="col-sp-12 col-tb-12 row-sp-12">
                <form>
                  <div class="field">
                    <div class="flex " id="flex-group">
                      <h3>Gruppieren</h3>
                      <div class="selectGroupEvents flex">
                        <input type="radio" v-model="group" name="group" value="event">
                        <label>Events</label>
                      </div>
                      <div class="selectGroupEvents flex">
                        <input type="radio" v-model="group" name="group" value="user">
                        <label>User</label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div v-if="group === 'user'">
              <div class="grid-12">
                <div class="col-sp-12 col-tb-3 row-sp-12">
                  <div class="tablesHeading">
                    Name
                  </div>
                </div>
                <div class="col-sp-12 col-tb-3 row-sp-12">
                  <div class="tablesHeading">
                    E-Mail
                  </div>
                </div>
                <div class="col-sp-12 col-tb-2 row-sp-12">
                  <div class="tablesHeading">
                    Umsatz
                  </div>
                </div>
                <div class="col-sp-12 col-tb-3 row-sp-12">
                  <div class="tablesHeading">
                    Letzer Kauf
                  </div>
                </div>
                <div class="col-sp-12 col-tb-1 row-sp-12" />
              </div>
              <sales-by-user-comp v-for="customer in salesByUser" :key="customer.userId" :customer="customer" @user-info="u => userInfoId = u" />
            </div>
            <div v-else>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-4 row-sp-12">
                  <div class="tablesHeading">
                    Event
                  </div>
                </div>
                <div class="col-sp-12 col-tb-4 row-sp-12 t-right">
                  <div class="tablesHeading">
                    Gesamt-Umsatz
                  </div>
                </div>
                <div class="col-sp-12 col-tb-4 row-sp-12 t-right">
                  <div class="tablesHeading">
                    Zeitstempel
                  </div>
                </div>
                <div class="col-sp-12 col-tb-1 row-sp-12" />
              </div>
              <sales-by-event-comp v-for="event in salesByEvent" :key="event.eventId" :event="event" @user-info="u => userInfoId = u" />
            </div>
          </div>
          <loader v-else />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import salesByUserComp from "@/components/sales/salesByUser";
import salesByEventComp from "@/components/sales/salesByEvent";
import {aSyncData, stringToDate} from "@/modules/main";
import Loader from "@/components/basic/loader";

export default {
  name: "customerInfoView",
  title: "Kundeninformation",
  components: {
    Loader,
    salesByUserComp,
    salesByEventComp
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      sales: [],
      filterEvent: 0,
      filterUser: null,
      filterStart: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().substring(0, 10),
      filterEnd: new Date().toISOString().substring(0, 10),
      group: 'user',
      userInfoId: 0
    }
  },
  computed: {
    salesByUser(){
      let user = {}
      this.salesFiltered.forEach(s => {
        if(!(s.userId in user)) user[s.userId] = {userId: s.userId, firstname: s.firstname, lastname: s.lastname, email: s.email, events: {}}
        if(!(s.eventId in user[s.userId].events)) user[s.userId].events[s.eventId] = {eventId: s.eventId, eventStart: s.eventStart, eventTitle: s.eventTitle, sales: []}
        user[s.userId].events[s.eventId].sales.push(s)
        if(!('lastSale' in user[s.userId].events[s.eventId]) || user[s.userId].events[s.eventId].lastSale < stringToDate(s.date)) user[s.userId].events[s.eventId].lastSale = stringToDate(s.date)
      })
      console.log(user)
      return user
    },
    salesByEvent(){
      let user = {}
      this.salesFiltered.forEach(c => {
        if(!(c.eventId in user)) user[c.eventId] = {eventId: c.eventId, eventStart: c.eventStart, eventTitle: c.eventTitle, customer: {}}
        if(!(c.userId in user[c.eventId].customer)) user[c.eventId].customer[c.userId] = {userId: c.userId, firstname: c.firstname, lastname: c.lastname, email: c.email, sales: []}
        user[c.eventId].customer[c.userId].sales.push(c)
        if(!('lastSale' in user[c.eventId].customer[c.userId]) || user[c.eventId].customer[c.userId].lastSale < stringToDate(c.date)) user[c.eventId].customer[c.userId].lastSale = stringToDate(c.date)
      })
      return user
    },
    events(){
      let events = []
      this.sales.forEach(s => {
        if(!events.some(e => e.id === s.eventId)) events.push({id: s.eventId, title: s.eventTitle, start: s.eventStart})
      })
      return events
    },
    salesFiltered(){
      let user =  this.sales.filter(s => {
        const start = stringToDate(s.eventStart)
        return start >= new Date(this.filterStart) && start <= new Date(this.filterEnd)
      })

      if(this.filterUser){
        user = user.filter(s => {
          return s.firstname.includes(this.filterUser) || s.lastname.includes(this.filterUser) || s.email.includes(this.filterUser)
        })
      }

      if(this.filterEvent){
        user = user.filter(s => {
          return s.eventId === this.filterEvent
        })
      }

      return user
    },
  },
  mounted() {
    this.getSales()
  },
  methods: {
    getSales(){
      const _this = this
      console.log(this.config.organizerId)
      aSyncData('sales/getSales', {
        organizerId: this.config.organizerId,
        start: new Date(this.filterStart).toISOString(),
        end: new Date(this.filterEnd).toISOString()
      })
          .then(r => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.sales = r.data
              _this.config.loading = false
            }
          })
    },
    download(){
      let csv = ''

      for(let row = 0; row < this.salesFiltered.length; row++){
        let keysAmount = Object.keys(this.salesFiltered[row]).length
        let keysCounter = 0

        // If this is the first row, generate the headings
        if(row === 0){

          // Loop each property of the object
          for(let key in this.salesFiltered[row]){
            // This is to not add a comma at the last cell
            // The '\r\n' adds a new line
            csv += key + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
            keysCounter++
          }
        }

        for(let key in this.salesFiltered[row]){
          csv += this.salesFiltered[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          keysCounter++
        }

        keysCounter = 0
      }

      let link = document.createElement("a")
      link.setAttribute("href", 'data:text/plain;charset=utf-8,' + encodeURI(csv));
      link.setAttribute("download", "sales.csv");

      link.click();
    },
  }
}
</script>

<style>
.flex{
  display: flex;
}

#flex-selectPeriod{
  justify-content: flex-start;
  column-gap: 10px;
}

#flex-group{
  column-gap: 20px;
  margin-top: 6px;
  align-items: baseline;
}

.grid-end{
  align-items: flex-end;
}

.selectGroupEvents{
  align-items: baseline;
  column-gap: 10px;
}

#customerinfo h1{
  font-size: 2em;
}
#customerinfo h3{
  font-size: 1.4em;
}
#customerinfo .field input{
  display: block;
  width: 100% !important;
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
  box-sizing: border-box;
}

#overviewAndSearch{
  justify-content: space-between;
  margin-bottom: 20px;
}

#selectorAndPeriod{
  margin-bottom: 12px;
  column-gap: 10px;
}

#groupSelector{
  margin-bottom: 20px;
}

#partyselector select, #downloadButton button{
  display: block;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
  box-sizing: border-box;
  margin-right: 8px;
}

.tablesHeading{
  font-weight: 600;
  padding-bottom: 6px;
}

</style>