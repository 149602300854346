<template>
  <div class="grid-12 bgcolor-lightgrey pad-8" style="cursor: pointer; margin-bottom: 8px;">
    <div class="col-sp-12 col-tb-3 row-sp-12 couponsDataActive" @click="$router.push({name: 'Coupon', params: {couponId: coupon.id}})">
      {{ coupon.code }}
    </div>
    <div class="col-sp-12 col-tb-3 row-sp-12 couponsDataActive" @click="$router.push({name: 'Coupon', params: {couponId: coupon.id}})">
      {{ coupon.title }}
    </div>
    <div class="col-sp-12 col-tb-2 row-sp-12 couponsDataActive" @click="$router.push({name: 'Coupon', params: {couponId: coupon.id}})">
      {{ dateFormat(coupon.validFrom) }}
    </div>
    <div class="col-sp-12 col-tb-2 row-sp-12 couponsDataActive" @click="$router.push({name: 'Coupon', params: {couponId: coupon.id}})">
      {{ dateFormat(coupon.validUntil) }}
    </div>
    <div class="col-sp-12 col-tb-2 row-sp-12 t-right couponsDataActive" @click="deleteModal = true">
      <button class="button bgcolor-lightgrey color-grey tiny">
        Löschen
      </button>
    </div>
  </div>
  <VueModal :show="deleteModal" @cancel="deleteModal = false" @close="deleteModal = false" cancel="Abbrechen" approve="löschen" @approve="deleteCoupon">
    <p class="f-bold">
      Möchtest du den Coupon wirklich löschen?
    </p>
  </VueModal>
</template>

<script>
import {aSyncData, dateToString, stringToDate} from "@/modules/main";

export default {
  name: "couponList",
  props: {
    coupon: Object
  },
  data(){
    return{
      deleteModal: false
    }
  },
  methods:{
    dateFormat(date){
      return dateToString(stringToDate(date))
    },
    deleteCoupon(){
      aSyncData('coupons/deleteCoupon', {couponId: this.coupon.id})
          .then(r => {
            if(r.status === 250) this.$router.go()
          })
          .catch(e => {console.error(e)})
    }
  }
}
</script>

<style scoped>

  .deleteButton{
    display: block;
    width: 100px;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 8px 12px;
    margin-bottom: 8px;
    text-align: center;
    cursor: pointer;
  }

  .couponsDataActive{
    margin: 6px 0;
  }

  @media (max-width: 663px){
    .couponsDataActive{
      margin: 4px 0;
    }
  }


</style>