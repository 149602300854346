<template>
  <section class="content-1400">
    <div class="pad-16">
      <div class="dropZone" @drop.prevent="fileDrop" @dragenter.prevent @dragover.prevent @click="$refs.file.click()">
        <div class="infoText">
          <svg width="80px" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 512 512"><path d="m457.6 140.2-82.5-4-4.8-53.8c-1-11.3-11.1-19.2-22.9-18.3l-296 24.3c-11.8 1-20.3 10.5-19.4 21.7l21.2 235.8c1 11.3 11.2 19.2 22.9 18.3l15-1.2-2.4 45.8c-.6 12.6 9.2 22.8 22.4 23.5L441.3 448c13.2.6 24.1-8.6 24.8-21.2L480 163.5c.6-12.5-9.3-22.7-22.4-23.3zm-355 5.3-7.1 134.8L78.1 305l-16-178v-1c.5-5 4.3-9 9.5-9.4l261-21.4c5.2-.4 9.7 3 10.5 7.9 0 .2.3.2.3.4 0 .1.3.2.3.4l2.7 30.8-219-10.5c-13.2-.4-24.2 8.8-24.8 21.3zm334.1 236.9L390 327.1l-27.5-32.7c-2.4-2.9-6.3-5.3-10.6-5.5-4.3-.2-7.5 1.5-11.1 4.1l-16.4 11.9c-3.5 2.1-6.2 3.5-9.9 3.3-3.6-.2-6.8-1.6-9.1-3.8-.8-.8-2.3-2.2-3.5-3.4l-42.8-48.9c-3.1-3.9-8.2-6.4-13.8-6.7-5.7-.3-11.2 2.1-14.8 5.6L129.4 359.8l-6.8 7.4.3-6.8 6.8-128.9 3.3-62.9v-1c1.4-5.4 6.2-9.3 11.9-9l204.2 9.8 28.7 1.4 58.3 2.8c5.8.3 10.3 4.7 10.4 10.2 0 .2.3.3.3.5s.3.3.3.5l-10.4 198.6z" fill="#2068ff" class="fill-000000" /><path d="M373.2 262.3c19.4 0 35.2-15.8 35.2-35.2s-15.7-35.2-35.2-35.2c-19.4 0-35.2 15.7-35.2 35.2s15.7 35.2 35.2 35.2z" fill="#2068ff" class="fill-000000" /></svg>
          <br>
          Medien hier ablegen oder zum Auswählen klicken
        </div>
      </div>
    </div>
    <input type="file" @change="fileDrop" accept=".jpeg, .jpg, .png, .mp4, .webm, .webp" multiple style="display: none" ref="file">
    <VueModal :show="formatModal" approve="ok" @approve="formatModal = false" @close="formatModal = false">
      Es werden nur folgende Formate unterstützt: JPG, PNG, WebP, WebM sowie MP4
    </VueModal>
    <loader v-if="loading" />
    <div class="mediaContainer" v-else>
      <h1 style="font-size: 1.8em" class="pad-16">
        Medien
      </h1>
      <div class="pad-16">
        <div class="grid-12">
          <media v-for="singleMedia in media" :key="singleMedia.name" :upload="singleMedia" :event-id="eventId" @delete-media="deleteMedia" />
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: right; margin-right: 20px;">
      <router-link to="/events" class="button" style="background-color: #2068ff; color: white;">
        Zurück
      </router-link>
    </div>
  </section>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import Loader from "@/components/basic/loader";
import Media from "@/components/events/media";

export default {
  name: "eventMedia",
  components: {Media, Loader},
  title: 'Eventmedia',
  props: {
    eventId: Number
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      loading: true,
      formatModal: false,
      media: []
    }
  },
  mounted() {
    this.loadMedia()
  },
  methods: {
    loadMedia(){
      const _this = this
      let fD = new FormData();
      fD.append('sessionID', this.auth.sessionID);
      fD.append('eventId', this.eventId);
      this.axios.post('events/getMedia',fD)
          .then(r => {
            if(r.status === 250){
              _this.media = r.data
              _this.loading = false
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    deleteMedia(name){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId', this.eventId)
      fD.append('fileName', name)
      this.axios.post('events/deleteMedia', fD)
          .then(r => {
            if(r.status === 250){
              _this.media = _this.media.filter(e => {
                return e.name !== name
              })
            }
          })
    },
    fileDrop(e){
      let tempFiles = e.target.files || e.dataTransfer.files
      let files = [];

      for(let i = 0; i < tempFiles.length; i++){
        if(!['jpg', 'jpeg', 'gif', 'png', 'webp', 'mp4', 'webm'].includes(tempFiles[i].name.split('.').pop().toLowerCase())){
          this.formatModal = true
        }else{
          files.push(tempFiles[i])
        }
      }

      for(let i = files.length - 1; i >= 0; i--){
        this.media.unshift({name: files[i].name})
      }

      for(let i = 0; i < files.length; i++){
        let fD = new FormData();
        fD.append('sessionID', this.auth.sessionID);
        fD.append('eventId', this.eventId);
        fD.append('upload', files[i]);
        this.axios.post('events/upload',fD,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                this.media[i].progress = Math.floor(progressEvent.loaded*90 / totalLength)
                console.log(Math.floor(progressEvent.loaded*90 / totalLength))
              }
            }
        )
            .then(r => {
              console.log(r)
              if(r.status === 250){
                console.log(this.media, this.media[i], i)
                this.media[i] = {
                  name: r.data.name,
                  extension: r.data.extension,
                  uploaded: true,
                  progress: 100
                }
              }

            })
            .catch(e => {
              console.log(e)
              this.media[i].progress = 0
              this.media[i].fail = true
            })
      }
    }
  }
}
</script>

<style scoped>
  .dropZone{
    width: 100%;
    height: 400px;
    background-color: #2068ff10;
    border: #2068ff dashed;
    border-radius: 10px;
    text-align: center;
    color: #2068ff;
    position: relative;
    cursor: pointer;
  }
  .infoText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>