<template>
  <main id="barMenuDetails">
    <div class="content-1400">
      <div class="pad-16">
        <div class="pad-8" />
        <router-link to="/barmenu" class="button color-white secondary">Zurück zur Übersicht</router-link>
        <div class="pad-8" />

        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Bearbeitung
        </h2>
        <div class="editBarCardName" style="margin-bottom: 12px;">
          <div class="grid-12">
            <div class="col-sp-12 col-sd-4 row-sp-12">
              <h1 style="padding-left: 4px; font-size: 2em;">
                {{ name }}
              </h1>
            </div>
            <div class="col-sp-12 col-sd-8 row-sp-12 t-right">
              <div v-if="editName" class="editMenuBarCardName flex">
                <input type="text" v-model="tempName" @keyup.enter="changeName">
                <div class="flex" style="column-gap: 10px;">
                  <button @click="editName = false" class="button cancelButton">
                    abbrechen
                  </button>
                  <button @click="changeName" class="button">
                    Speichern
                  </button>
                </div>
              </div>
              <div v-else @click="editName = true">
                <button class="button ">Umbenennen</button>
              </div>

            </div>
          </div>
        </div>
        <div class="bgcolor-white pad-16 br-10">
          <div class="categoriesEdit">
            <div class="grid-12">
              <div class="col-sp-12 col-sd-2 row-sp-12">
                <h2 style="font-size: 1.4em;">Kategorien</h2>
              </div>
              <div class="col-sp-12 col-sd-10 row-sp-12">
                <div class="categoriesEditButton flex">
                  <input type="text" v-model="categoryName" @keyup.enter="addBarCategory">
                  <button @click="addBarCategory" class="button">
                    Hinzufügen
                  </button>
                </div>
              </div>
            </div>


          </div>


          <bar-category v-for="(category, index) in categories" :key="category.id" :index="index" :category="category" :quantity="categories.length" @edit-category="editCategory" @delete-category="deleteCategory" @list-order="listOrder" />
          <div class="flex barcardDelete-wrapper" style="justify-content: flex-end">
            <button class="button" @click="deleteModal = true" style="background-color: #2068ff; color: white; margin-top: 12px;">
              Barkarte löschen
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>

  <VueModal :show="deleteModal" approve="löschen" cancel="abbrechen" @close="deleteModal = false" @cancel="deleteModal = false" @approve="deleteBarMenu" style="background-color: rgba(0,0,0,0.05)">
    <h2 style="margin: 20px 0 8px; font-weight: 600; font-size: 1.4em;">Barkarte Löschen?</h2>
    Bist du sicher, dass du die barkarte inkl. aller Getränke und Kategorien löschen möchtest?
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import BarCategory from "@/components/barMenu/barCategory";

export default {
  name: "barMenu",
  components: {BarCategory},
  title: "Barkarte",
  props: {
    barMenuId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      barMenues: [],
      editName: false,
      deleteModal: false,
      name: '',
      tempName: '',
      categories: [],
      categoryName: ''
    }
  },
  computed: {
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    getDetails(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      this.axios.post('barMenu/getBarMenu', fD)
          .then(r => {
            if (r.status === 250) {
              _this.name = r.data.name
              _this.tempName = r.data.name
              _this.getCategories()
            }else{
              _this.$router.push({name: 'BarMenuOverview'})
            }
          })
    },
    getCategories(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      this.axios.post('barMenu/getBarCategoriesByBarMenu', fD)
          .then(r => {
            if (r.status === 250) {
              _this.categories = r.data.sort((a, b) => {return a.listOrder - b.listOrder})
            }
          })
    },
    changeName() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      fD.append('name', this.tempName)
      this.axios.post('barMenu/editBarMenu', fD)
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              _this.name = r.data
              _this.tempName = r.data
              _this.editName = false
            }
          })
    },
    addBarCategory() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      fD.append('name', this.categoryName)
      this.axios.post('barMenu/addBarCategory', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.categoryName = ''
              _this.categories = r.data.sort((a, b) => {return a.listOrder - b.listOrder})
            }
          })
    },
    deleteCategory(categoryId){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barCategoryId', categoryId)
      this.axios.post('barMenu/deleteBarCategory', fD)
          .then(r => {
            if(r.status === 250){
              _this.categories =_this.categories.filter(c => {
                return c.id !== categoryId
              })
            }
          })
    },
    editCategory(categoryId, name){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barCategoryId', categoryId)
      fD.append('name', name)
      this.axios.post('barMenu/editBarCategory', fD)
          .then(r => {
            if(r.status === 250){
              _this.categories.forEach(c => {
                if(c.id === categoryId){
                  c.name = name
                }
              })
            }
          })
    },
    listOrder(index, moveUp){
      for(let i = 0; i < this.categories.length; i++ ){
        if(!moveUp && i === index){
          this.categories[i].listOrder = i + 1;
        }else if(!moveUp && (i - 1) === index){
          this.categories[i].listOrder = i - 1;
        }else if(moveUp && (i + 1) === index){
          this.categories[i].listOrder = i + 1;
        }else if(moveUp && i === index){
          this.categories[i].listOrder = i - 1;
        }else{
          this.categories[i].listOrder = i;
        }
      }
      this.categories = this.categories.sort((a, b) => {return a.listOrder - b.listOrder})

      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barCategories', JSON.stringify(this.categories))
      this.axios.post('barMenu/changeOrderOfBarCategories', fD)
    },
    deleteBarMenu(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      this.axios.post('barMenu/deleteBarMenu', fD)
          .then(r => {
            console.log(r.data, r.status)
            if(r.status === 250){
              _this.$router.push({name: 'BarMenuOverview'})
            }
          })
    }
  }
}
</script>

<style scoped>
  button{
    cursor: pointer;
  }
  .flex {
    display: flex;
  }

  .editBarCardName {
    align-items: center;
    column-gap: 12px;
    justify-content: start
  }

  .editBarCardName button {
    background-color: rgba(0,0,0,0.05);
    color: black;
  }

  .editMenuBarCardName{
    column-gap: 12px;
    flex-wrap: wrap;
    justify-content: end;
    row-gap: 10px;
  }

  .editMenuBarCardName input {
    display: block;
    width: 40%;
    text-align: left;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
  }

  .cancelButton{
    background-color: unset !important;
    border: 2px solid #2068ff;
    color: #2068ff !important;
  }

  .categoriesEdit{
    align-items: center;
  }

  .categoriesEdit h2{
    flex: 1;
  }

  .categoriesEditButton {
    column-gap: 8px;
    flex: 1;
    justify-content: end;
  }

  .categoriesEditButton input{
    display: block;
    width: 100%;
    text-align: left;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
  }

  .categoriesEditButton button{
    background-color: #2068ff;
    color: white;
  }
  .barcardDelete-wrapper button {
    color: #2068ff !important;
    background-color: unset !important;
    border: 2px solid #2068ff !important;
  }

  @media (max-width: 767px){
    .barcardDelete-wrapper{
      margin-bottom: 70px;
    }
  }

  @media (max-width: 991px){
    .barcardDelete-wrapper button {
      width: 100% !important;
    }
  }


</style>