<template>
  <div class="grid-12 eventData">
    <div class="col-sp-12 col-tb-3 row-sp-12">
      {{ (customer.firstname + ' ' + customer.lastname).length > 25 ? (customer.firstname + ' ' + customer.lastname).substring(0,20) + '...' : (customer.firstname + ' ' + customer.lastname) }}
    </div>
    <div class="col-sp-12 col-tb-3 row-sp-12">
      {{ (customer.email).length > 30 ? (customer.email).substring(0,25) + '...' : (customer.email) }}
    </div>
    <div class="col-sp-12 col-tb-2 row-sp-12 t-right">
      {{ (-saleByUser/100).toFixed(2) }} €
    </div>
    <div class="col-sp-12 col-tb-3 row-sp-12 t-right">
      {{ lastSale }}
    </div>
    <div class="col-sp-1 col-tb-1 row-sp-12 t-center">
      <span class="button tiny color-white" @click.stop.prevent="$router.push({name: 'Customer', params: {userId: customer.userId}})">Info</span>
    </div>
  </div>
  <div v-if="expanded" style="background-color: #2068ff">
    <transaction v-for="sale in customer.sales" :transaction="sale" :key="sale.id" />
  </div>
</template>

<script>
import {dateToString, stringToDate} from "@/modules/main";
import Transaction from "@/components/sales/transaction";

export default {
  name: "saleUsers",
  components: {Transaction},
  props: {
    customer: Object
  },
  emits: ['userInfo'],
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    lastSale(){
      return dateToString(this.customer.lastSale)
    },
    saleByUser(){
      return this.customer.sales.reduce((a, c) => {
        return a + c.amount
      }, 0)
    }
  }
}
</script>

<style scoped>

  .eventData{
    margin: 4px 0 4px 12px;
  }

</style>