<template>
  <main id="lounge">
    <div class="content-1400">
      <div class="pad-16">
        <div class="pad-16" />
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Übersicht
        </h2>
        <div class="title-wrapper flex">
          <h2 style="padding-left: 4px;">
            Lounges
          </h2>
          <button class="button" @click="newModal = !newModal">
            Lounge hinzufügen
          </button>
        </div>

        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10">
            <div class="grid-12" id="loungeAdd">
              <div class="col-sp-12 col-sd-12 row-sp-12">
                <h3 style="padding-bottom: 12px; font-size: 1.4em;">
                  Übersicht
                </h3>
                <div class="grid-12">
                  <lounge v-for="lounge in lounges" :key="lounge.id" :lounge="lounge" @edit-lounge="editLounge" @delete-lounge="deleteLounge" />
                </div>
                <VueModal :show="newModal" @close="resetLoungeForm" @cancel="resetLoungeForm" @approve="addLounge" :cancel="'Abbrechen'" :approve="loungeTemplateId ? 'Änderungen speichern' : (title.length ? 'Lounge erstellen' : null)">
                  <div class="t-left">
                    <div class="label-wrapper">
                      <label for="title">Titel: </label>
                      <input type="text" name="title" v-model="title" id="title" placeholder="Titel">
                    </div>
                    <div class="label-wrapper">
                      <label for="description">Beschreibung: </label>
                      <textarea type="text" name="description" v-model="description" id="description" placeholder="Beschreibung" />
                    </div>
                    <div class="label-wrapper">
                      <label for="price">Standardpreis: </label>
                      <input type="text" name="price" v-model="price" id="price" placeholder="Standardpreis">
                    </div>
                    <div class="label-wrapper">
                      <label for="quantity">Standardanzahl: </label>
                      <input type="text" name="quantity" v-model="quantity" id="quantity" placeholder="Standardanzahl">
                    </div>
                    <div class="label-wrapper">
                      <label for="quantity">Maximale Gäste: </label>
                      <input type="number" name="maxGuests" v-model="maxGuests" id="maxGuests">
                    </div>
                  </div>
                  <cropper-upload :init="false" :ratio="1" @cropped="c => image = c" button-text="Lounge-Bild hochladen" />
                  <img :src="config.projectURL + 'assets/images/lounges/' + oldImage + '.jpg'" v-if="oldImage && !image">
                </VueModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import {aSyncData} from '@/modules/main.js'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import Lounge from "@/components/lounges/lounge";
import CropperUpload from "@/components/images/cropperUpload";

export default {
  name: 'lounges',
  components: {
    Lounge,
    CropperUpload
  },
  title: 'Lounges',
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      lounges: [],
      newModal: false,
      loungeTemplateId: 0,
      title: '',
      description: '',
      price: 0,
      quantity: 1,
      image: null,
      maxGuests: 10,
      oldImage: null
    }
  },
  mounted() {
    this.getLounges()
  },
  methods: {
    getLounges() {
      const _this = this
      aSyncData('lounges/getTemplates', {organizerId: this.config.organizerId})
          .then(r => {
            console.log(r.data)
            if (r.status === 250) {
              _this.lounges = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    addLounge() {
      const _this = this
      aSyncData(this.loungeTemplateId ? 'lounges/editTemplate' : 'lounges/addTemplate', {
        organizerId: this.config.organizerId,
        loungeTemplateId: this.loungeTemplateId,
        title: this.title,
        description: this.description,
        price: this.price,
        quantity: this.quantity,
        maxGuests: this.maxGuests,
        loungeImage: this.image
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              _this.lounges = r.data
              _this.resetLoungeForm()
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    editLounge(lounge){
      console.log(lounge)
      this.loungeTemplateId = lounge.id
      this.title = lounge.title
      this.description = lounge.description
      this.price = lounge.price
      this.quantity = lounge.quantity
      this.maxGuests = lounge.maxGuests
      this.newModal = true
      this.oldImage = lounge.image
    },
    resetLoungeForm(){
      this.newModal = false
      this.loungeTemplateId = 0
      this.title = ''
      this.description = ''
      this.price = 0
      this.quantity = 1
      this.maxGuests = 10
      this.image = null
      this.oldImage = null
    },
    deleteLounge(loungeTemplateId){
      const _this = this
      aSyncData('lounges/deleteTemplate', {
        organizerId: this.config.organizerId,
        loungeTemplateId: loungeTemplateId,
      })
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              _this.lounges = r.data
              _this.resetLoungeForm()
            }
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style scoped>

  .flex {
    display: flex;
  }

  .title-wrapper{
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
  }
  .title-wrapper h2{
    font-size: 2em;
  }

  .label-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    column-gap: 100%;
  }

  .label-wrapper input, .label-wrapper textarea{
    display: block;
    width: 60%;
    text-align: left;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  .button {
    background-color: #2068ff;
    color: white;
  }

  @media (max-width: 991px) {
    #loungeAdd input, #loungeAdd textarea, #loungeAdd .button {
      width: 100%;
    }
  }

</style>
