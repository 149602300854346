<template>
  <main class="content-1400" id="notificationService">
    <div class="pad-16">
      <router-view />
    </div>
  </main>
</template>

<script>

export default {
  name: "NotificationService",
  title: "Nachrichtendienst",
}
</script>

<style scoped>
</style>