<template>
  <tr>
    <td>#{{ transactionItems.transactionId }}</td>
    <td>{{ transactionItems.firstname }} {{ transactionItems.lastname }}</td>
    <td>{{ covertTimestamp }}</td>
    <td class="t-right">
      <span class="label">{{ (transactionItems.amount / 100).toFixed(2).replace("-","") }}€</span>
    </td>
  </tr>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "TransactionItems",
  props: ["transactionItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertTimestamp: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      const _this = this
      let timestampArr = this.transactionItems.date.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.covertTimestamp = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
  }
}
</script>

<style scoped>
tr td{
  padding: 4px;
  font-size: 0.9em;
}
span.label{
  background-color: #9bf2d233;
  color: #67cba6;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: .9em;
}
</style>