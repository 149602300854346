<template>
  <button v-for="lounge in unselectedLounges" :key="lounge.id" @click="$emit('addLounge', lounge.id)" style="margin: 4px;" class="button color-white bgcolor-blue">
    {{ lounge.title }}
  </button>
  <section class="grid-12">
    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4" v-for="lounge in lounges" :key="lounge.id">
      <div class="bgcolor-lightgrey br-10 pad-16">
        <div class="pad-40 br-10" :style="{'background-image': 'url(' + config.projectURL + 'assets/images/lounges/' + lounge.image + '_thumb.jpg)'}" style="background-size: cover; background-color: #00000033; background-repeat: no-repeat; background-position: center center;" />
        <h5 class="f-bold" style="font-size: 1.2em; margin-bottom: 8px;">
          {{ lounge.title }}
        </h5>
        <div class="field" style="margin-bottom: 8px;">
          <label>Preis:</label>
          <input type="text" v-model="lounge.price" placeholder="Preis in €">
        </div>
        <div class="field" style="margin-bottom: 8px;">
          <label>Verfügbar bis <span style="font-size: 0.8em;">(leerlassen bei keiner Beschränkung)</span></label>
          <input type="datetime-local" v-model="lounge.availableUntil" placeholder="Verfügbar bis (leerlassen bei keiner Beschränkung)">
        </div>
        <div class="field" style="margin-bottom: 8px;">
          <label>Anzahl</label>
          <input type="number" v-model.number="lounge.quantity" placeholder="Anzahl">
        </div>
        <div class="field" style="margin-bottom: 8px;">
          <label>Maximale Gäste</label>
          <input type="number" v-model.number="lounge.maxGuests">
        </div>
        <div class="pad-4" />
        <button class="button bgcolor-black color-white" style="width: 100%;" @click="$emit('removeLounge', lounge.id)">
          Lounge entfernen
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "loungeSelect",
  props: {
    loungeTemplates: Array,
    lounges: Array
  },
  emits: ['addLounge','removeLounge', 'priceLounge'],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  computed: {
    unselectedLounges() {
      return this.loungeTemplates.filter(lT => {
        return !(this.lounges.some(l => l.id === lT.id))
      })
    }
  }
}
</script>

<style scoped>

</style>