<template>
  <main id="createTicket">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Übersicht
        </h2>
        <h1 style="padding-left: 4px;display: inline;">
          Neue Ticket-Variante erstellen
        </h1>
        <div class="pad-4" />
        <div class="grid-12">
          <div class="col-sp-12 col-sd-12 row-sp-12">
            <div class="pad-4">
              <div class="bgcolor-white pad-16 br-10 eventDataForm">
                <h3 style="padding-top: 2px; padding-left: 4px">
                  Ticketdaten
                </h3>
                <h4 style="padding-left: 4px;">
                  Allgemein
                </h4>
                <div class="grid-12">
                  <div class="col-sp-12 row-sp-12 pad-4">
                    <div class="field">
                      <label>Event auswählen</label>
                      <select v-model="eventId">
                        <eventOptionItems v-for="eitems in eventOptionItems" :event-option-items="eitems" :key="eitems.id" />
                      </select>
                    </div>
                  </div>
                  <div class="col-sp-12 row-sp-12 pad-4">
                    <div class="field">
                      <input type="text" placeholder="Ticketname (max. 20 Zeichen)" maxlength="20" required v-model="ticketTitle">
                    </div>
                  </div>
                  <div class="col-sp-12 row-sp-12 pad-4">
                    <div class="field">
                      <textarea type="text" rows="4" placeholder="Beschreibung & Informationen" v-model="ticketDescription" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-4">
              <div class="bgcolor-white pad-16 br-10 eventDataForm">
                <div class="grid-12">
                  <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                    <div class="field">
                      <label for="start">Verkaufsstart</label>
                      <input type="datetime-local" v-model="sellStart" required>
                    </div>
                  </div>
                  <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                    <div class="field">
                      <label for="end">Verkaufsende</label>
                      <input type="datetime-local" v-model="sellEnd" required>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-4">
              <div class="bgcolor-white pad-16 br-10 eventDataForm">
                <div class="grid-12">
                  <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                    <div class="field">
                      <label for="price">Gebühr <span style="color: rgba(0,0,0,0.6); font-size: 0.8em;">(inkl. MwSt.)</span></label>
                      <select v-model="feeType">
                        <option value="none">
                          Keine
                        </option>
                        <option value="ticket">
                          Pro Ticket
                        </option>
                        <option value="order">
                          Pro Bestellung
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                    <div class="field" v-if="feeType != 'none'">
                      <label for="fee">Gebühr</label>
                      <input type="number" min="0.00" max="10000.00" step="0.01" id="fee" required placeholder="Ticketgebühr (€)" v-model="fee">
                    </div>
                  </div>
                </div>
              </div>
              <div class="bgcolor-white pad-16 br-10 eventDataForm">
                <div class="grid-12">
                  <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                    <div class="field">
                      <label for="price">Brutto-Endpreis <span style="color: rgba(0,0,0,0.6); font-size: 0.8em;">(inkl. MwSt.)</span></label>
                      <input type="number" min="0.00" max="10000.00" step="0.01" id="price" required placeholder="Ticketpreis (€)" v-model="price">
                    </div>
                  </div>
                  <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                    <div class="field">
                      <label for="quantity">Verfügbare Tickets</label>
                      <input type="number" id="quantity" v-model="quantity" required placeholder="Anzahl an Verfügbaren Tickets">
                    </div>
                  </div>
                  <div class="col-sp-12 row-sp-12 pad-8">
                    <div class="field">
                      <input id="personal" type="checkbox" v-model="personal">
                      <label for="personal" style="font-size: 0.9em">  Tickets sind personengebunden und können nicht weitergegeben werden</label>
                    </div>
                  </div>
                </div>
                <p class="t-center color-red" style="font-size: 0.8em;" v-if="ticketError != ''">
                  {{ ticketError }}
                </p>
              </div>
              <div class="t-right">
                <div class="pad-4" />
                <button @click="createTicket" class="button bgcolor-blue color-white">
                  Ticket erstellen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import eventOptionItems from "@/components/ticketcenter/eventOptionItems";

export default {
  name: "ticketCreate",
  title: 'Create Ticket',
  components: {
    eventOptionItems: eventOptionItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      eventOptionItems: [],
      ticketTitle: '',
      ticketDescription: '',
      price: 0,
      quantity: 0,
      eventId: null,
      personal: 0,
      sellStart: null,
      sellEnd: null,
      feeType: 'none',
      fee: 0,
      ticketError: '',
    }
  },
  watch: {
    eventId(){
      this.eventOptionItems.forEach(e => {
        if(e.id === this.eventId){
          this.sellEnd = e.endTime.replace(' ', 'T')
        }
      })
    }
  },
  mounted() {
    this.config.loading = false
    let date = new Date()
    this.sellStart = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate().length === 1 ? date.getDate() : ('0' + date.getDate())) + 'T' + date.getHours() + ':' + date.getMinutes()

    this.getEventOptionItems()
  },
  methods: {
    createTicket(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('ticketTitle', this.ticketTitle)
      fD.append('ticketDescription', this.ticketDescription)
      fD.append('price', this.price)
      fD.append('quantity', this.quantity)
      fD.append('eventId', this.eventId)
      fD.append('personal', this.personal)
      fD.append('sellStart', this.sellStart)
      fD.append('sellEnd', this.sellEnd)
      fD.append('feeType', this.feeType)
      fD.append('fee', this.fee)
      console.log('tst')
      if (this.validateTicketCreation()) {
        console.log('Test2')
        this.axios.post('ticketcenter/createTicket', fD)
            .then(r => {
              console.log(r)
              if (r.status === 250) {
                _this.$router.push('Ticketcenter')
              }
            })
      }
    },
    validateTicketCreation(){
      this.ticketError = '';
      if(!this.ticketTitle.length >= 1 && !this.ticketTitle.length <= 20){
        this.ticketError = 'Bitte gebe einen Ticket-Titel ein, der maximal 20 Zeichen beträgt.';
      }else if(this.sellStart == null || this.sellEnd == null){
        this.ticketError = 'Bitte gebe einen Verkaufsstart und Ende an.';
      }else if(!(this.quantity >= 0) || this.price === ''){
        this.ticketError = 'Bitte gebe einen Ticketpreis und Menge an.';
      }else{
        this.ticketError = '';
        return true;
      }
    },
    getEventOptionItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('ticketcenter/getEventOptionItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventOptionItems = response.data

              if(_this.eventOptionItems.length){
                _this.eventId = _this.eventOptionItems[0].id

                _this.eventOptionItems.forEach(e => {
                  if(e.id === _this.eventId){
                    _this.sellEnd = e.endTime.replace(' ', 'T')
                  }
                })
              }
            }
          })
    },
  }
}
</script>

<style scoped>
#createTicket h1{
  font-size: 2em;
}
#createTicket h3{
  font-size: 1.2em;
}

#createTicket h4{
  font-size: 0.9em;
  color: rgba(0,0,0,0.6);
  margin-top: -4px;
  margin-bottom: 8px;
}
#uploadBox{
  border-radius: 10px;
  border: 2px dashed rgba(0,0,0,0.1);
  text-align: center;
  padding: 52px 32px;
}
#uploadBox h5{
  font-size: 0.9em;
  color: rgba(0,0,0,0.4);
  margin-bottom: 16px;
}
.eventDataForm .field input,
.eventDataForm .field textarea,
.eventDataForm .field select{
  display: block;
  padding: 12px;
  width: 100%;
  max-width: calc(100% - 20px);
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
}
.eventDataForm .field select{
  max-width: 100%;
}
.eventDataForm .field input[type=checkbox]{
  width: auto;
  display: inline;
  margin-right: 2px;
}
#ticketBox{
  border-radius: 10px;
  border: 2px dashed rgba(0,0,0,0.1);
  text-align: center;
  padding: 52px 32px;
  margin-top: 16px;
}
#ticketBox h5{
  font-size: 0.9em;
  color: rgba(0,0,0,0.4);
  margin-bottom: 16px;
}
</style>