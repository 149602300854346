<template>
  <div class="grid-12 list-elements">
    <div class="col-sp-12 col-tb-3 row-sp-12">
      {{ (customer.firstname + ' ' + customer.lastname).length > 25 ? (customer.firstname + ' ' + customer.lastname).substring(0,20) + '...' : (customer.firstname + ' ' + customer.lastname) }}
    </div>
    <div class="col-sp-12 col-tb-3 row-sp-12">
      <!--{{ (customer.email).length > 30 ? (customer.email).substring(0,25) + '...' : (customer.email) }}-->
    </div>
    <div class="col-sp-12 col-tb-2 row-sp-12">
      {{ (-reduced.sales/100).toFixed(2) }} €
    </div>
    <div class="col-sp-12 col-tb-3 row-sp-12">
      {{ reduced.lastSale }}
    </div>
    <div class="col-sp-12 col-tb-1 row-sp-12 link" @click.stop.prevent="$emit('userInfo', customer.userId)">
      <button class="button tiny color-white" @click="$router.push({name: 'Customer', params: {userId: customer.userId}})" v-if="customer.userId">
        Info
      </button>
    </div>
  </div>
  <div v-if="expanded" style="background-color: red">
    <hr>
    <div class="grid-12">
      <div class="col-sp-12 col-tb-3 row-sp-12">
        Event
      </div>
      <div class="col-sp-12 col-tb-3 row-sp-12">
        Datum
      </div>
      <div class="col-sp-12 col-tb-3 row-sp-12">
        Letzter Kauf
      </div>
      <div class="col-sp-12 col-tb-3 row-sp-12">
        Umsatz
      </div>
    </div>
    <sale-events v-for="event in customer.events" :key="event.eventId" :event="event" />
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {stringToDate, dateToString} from "@/modules/main";
import SaleEvents from "@/components/sales/saleEvents";

export default {
  name: "SalesByUserComp",
  components: {SaleEvents},
  props: {
    customer: Object
  },
  emits: ['userInfo'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    reduced(){
      return {
        'sales': Object.values(this.customer.events).reduce((a, c) => {
          return a + c.sales.reduce((a2, c) => {
            return a2 + c.amount
          }, 0)
        }, 0),
        'lastSale': dateToString(new Date(Math.max(... Object.values(this.customer.events).map(c => {
          return c.lastSale
        }))))
      }
    },
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>
  td{
    padding: 8px;
  }
  td img{
    vertical-align: middle;
  }
  tr{
    background-color: rgba(0,0,0,0.03);
    border-bottom: 4px solid #ffffff;
  }
  #editModal .vueModalInner{
    width: calc(100vw - 16px);
    max-width: 500px;
  }
  #editModal h3{
    font-size: 1.6em;
  }
  #editModal .field label{
    display: block;
  }
  #editModal .field input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #editModal .field select{
    display: block;
    width: calc(100%);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  .list-elements{
    margin: 8px 0;
  }

  .list-elements .link{
    font-weight: 600;
  }
</style>