<template>
  <article class="club" @click="updateOrganizerId" :class="{active: config.organizerId == clubItems.id}">
    <div class="grid-12">
      <div class="col-sp-12 col-tb-4 row-sp-12">
        <img :src="config.projectURL + 'assets/images/eventhoster/'+clubItems.logo">
      </div>
      <div class="col-sp-12 col-tb-8 row-sp-12">
        <h4>{{ clubItems.organizerName }}</h4>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {reactive} from "vue";

export default {
  name: "clubItems",
  props: ["clubItems"],
  emits:  ['update'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    updateOrganizerId(){
      this.config.organizerId = this.clubItems.id
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('clubId', this.clubItems.id)
      this.axios.post(this.config.projectURL + 'profil/updateOrganizerId', fD)
          .then(response => {
            this.$emit('update')
          })
          .catch(e => {console.log(e)})
    },
  }
}
</script>

<style scoped>
.club{
  margin-top: 8px;
  cursor: pointer;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 8px 4px;
  transition: 0.3s;
}
.club.active{
  border: 2px solid #2068ff;
  color: #2068ff!important;
}
.club:hover{
  background-color: rgba(0,0,0,0.05);
}
.club img{
  max-width: 100%;
  width: 100%;
  padding-right: 8px;
}
.club h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  font-weight: 400;
  margin-top: 8px;
  padding-left: 8px;
}
</style>