<template>
  <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
    <div class="lounge bgcolor-lightgrey pad-16 br-10">
      <div class="loungeInfo">
        <div class="backgroundImg" :style="{'background-image': 'url(' + config.projectURL + 'assets/images/lounges/' + lounge.image + '_thumb.jpg)'}" />
        <div class="f-bold" style="font-size: 1.2em; margin: 12px 0 8px;">
          {{ lounge.title }}
        </div>
        <div style="padding-bottom: 8px; text-overflow:ellipsis; white-space: nowrap; max-width: 220px; overflow: hidden">
          {{ lounge.description }}
        </div>
        <div>Standardpreis: {{ lounge.price }} €</div>
        <div>Standardanzahl: {{ lounge.quantity }}</div>
      </div>
      <div class="flex button-wrapper">
        <button class="button" @click="$emit('editLounge', lounge)">
          Lounge bearbeiten
        </button>
        <button class="buttonDelete button" @click="deleteModal = !deleteModal">
          Lounge entfernen
        </button>
      </div>
    </div>
  </div>
  <VueModal :show="deleteModal" @approve="$emit('deleteLounge', lounge.id)" approve="Löschen" cancel="abbrechen" @close="deleteModal = false" @cancel="deleteModal = false">
    Bist du sicher, dass du die Lounge löschen willst?
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "lounge",
  props: {
    lounge: JSON
  },
  emits: ['editLounge', 'deleteLounge'],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      deleteModal: false
    }
  },

}
</script>

<style scoped>
/*div.lounge{*/
/*  position: relative;*/
/*  display: inline-block;*/
/*  width: 250px;*/
/*  height: 300px;*/
/*}*/

.button{
  background-color: #2068ff;
  color: white;
}

@media (max-width: 991px) {
  .button {
    width: 100%;
  }
}

.buttonDelete{
  background-color: black;
  color: white;
}

.button-wrapper{
  flex-wrap: wrap;
  column-gap: 100%;
  row-gap: 6px;
}

.loungeInfo{
  margin-bottom: 12px;
}

.backgroundImg{
  padding-top: 56.50%;
  border-radius: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

</style>