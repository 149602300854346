<template>
  <footer>
    <ul>
      <li>
        <router-link to="/dashboard">
          <img src="assets/images/icons/home-white.svg">
          <span>Dashboard</span>
        </router-link>
      </li>
      <li>
        <router-link to="/ticketcenter/scanner">
          <img src="assets/images/icons/ticket-white.svg">
          <span>Ticket-Scan</span>
        </router-link>
      </li>
      <li className="middle">
        <router-link to="/events/create">
          <img src="assets/images/icons/plus-white.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/eventlypay">
          <img src="assets/images/icons/charge-white.svg">
          <span>EventlyPay</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profil">
          <img style="filter: none;" :src="config.projectURL + 'assets/images/users/'+user.avatar" class="image circular">
          <span>Profil</span>
        </router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Footer",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  computed: {},

}
</script>

<style scoped>
footer{
  display: none;
}
@media (max-width: 768px) {
  footer {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
    min-height: 48px;
    padding: 8px 0 max(8px, calc(0.75 * env(safe-area-inset-bottom))) 0;
    background-color: #FFFFFF;
  }

  footer ul {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  footer ul li {
    width: 20%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    text-align: center;
  }

  footer ul li a {
    display: block;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  footer ul li a img {
    width: 24px;
    display: block;
    margin: 0 auto;
    filter: brightness(0.4);
  }

  footer ul li a span {
    width: 24px;
    display: inline;
    font-size: 0.8em;
    margin: 0 auto;
  }

  footer ul li.middle {
    position: relative;
    margin-top: -40px;
  }

  footer ul li.middle img {
    background-color: #2068ff;
    border-radius: 90%;
    width: 24px;
    height: 24px;
    margin-top: 0px;
    padding: 16px;
    filter: none;
  }
}
</style>