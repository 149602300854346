<template>

  <main id="orders">
    <div class="content-1400">
      <div class="pad-16">
        <div class="pad-16" />
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Übersicht
        </h2>
        <div class="title-wrapper flex">
          <h2 style="padding-left: 4px;">
            Bestellungen
          </h2>
        </div>

        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10">
            <div class="grid-12" id="ordersMenu">
              <div class="col-sp-12 col-sd-12 row-sp-12">
                <div>
                  <h2 style="padding-bottom: 8px;">Ausgewähltes Event</h2>
                  <select v-model="eventId" class="eventSelection">
                    <option :value="null">
                      Bitte Event auswählen
                    </option>
                    <option v-for="event in events" :key="event.id" :value="event.id">
                      {{ event.title }}
                    </option>
                  </select>
                  <div class="orderInformation">
                    <h2>Offene Bestelungen</h2>
                    <div v-if="!orders.filter(o => o.status === 'open').length">
                      Keine offene Bestellungen
                    </div>
                  </div>
                  <div class="grid-12">
                    <order-comp v-for="order in orders.filter(o => o.status === 'open')" :key="order.id" :order="order" @fulfill="fulfill" />
                  </div>
                  <loader v-if="loading" />
                  <div v-show="!loading">


                    <div v-if="orders.filter(o => o.status !== 'open').length">
                      <div class="orderInformation">
                        <h2>Archiv</h2>
                      </div>
                      <div class="grid-12">
                        <order-comp v-for="order in orders.filter(o => o.status !== 'open')" :key="order.id" :order="order" />
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>







</template>

<script>
import Loader from "@/components/basic/loader";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";
import OrderComp from "@/components/order/orderComp";
import {notificationStore} from "@/store/notificationStore";

export default {
  name: "orderView",
  components: {OrderComp, Loader},
  setup() {
    const auth = authStore()
    const config = configStore()
    const notifications = notificationStore()
    const user = userStore()

    return {auth, config, notifications, user}
  },
  title: "Bestellungen",
  data(){
    return{
      orders: [],
      events: [],
      eventId: null,
      loading: true,
      interval: null
    }
  },
  watch: {
    eventId(){
      this.loadOrders()
    }
  },
  mounted() {
    this.loadEvents()
    this.notifications.pushToken()
    this.notifications.$subscribe(() => {
      this.loadOrders()
    })
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    loadEvents(){
      this.loading = true
      aSyncData('orders/getEvents', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250) {
              this.events = r.data
              if(this.events.length) {
                this.eventId = this.events[0].id
                this.interval = setInterval(() => this.loadOrders(), 5*1000)
              }else{
                this.loading = false
              }
            }
          })
          .catch(e => {
            console.error(e)
            this.loading = false
          })
    },
    loadOrders(){
      if(!this.eventId) return
      aSyncData('orders/getOrders', {eventId: this.eventId})
          .then(r => {
            if(r.status === 250) this.orders = r.data
          })
          .catch(e => {console.error(e)})
          .finally(() => {this.loading = false})
    },
    loadOrder(orderId){
      aSyncData('orders/getOrder', {orderId: orderId})
          .then(r => {
            if(r.status === 250 && o.eventId === this.eventId) {
              if(!this.orders.some(o => o.id === r.data.id)){
                this.orders.push(r.data)
              }
            }
          })
          .catch(e => {console.error(e)})
    },
    fulfill(id){
      const date = new Date()
      let order = this.orders.find(o => o.id === id)
      order.status = 'fulfilled'
      order.delivered = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    }
  }
}
</script>

<style scoped>

.title-wrapper h2{
  font-size: 2em;
}

#orders .eventSelection{
  display: block;
  width: 30%;
  text-align: left;
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
}

.orderInformation{
  margin: 16px 0 12px;
  font-size: 1.4em;
  font-weight: 400;
  text-align: center;
}

</style>