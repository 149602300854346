<template>
  <main id="ticketcenter">
    <router-view />
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Ticketcenter",
  title: "Ticketcenter",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style>
main#ticketcenter{
  padding-top: 40px!important;
  padding-bottom: 80px;
}
</style>