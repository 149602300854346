<template>
  <div id="createEvent" class="content-1400">
    <div class="pad-16">
      <h1 style="padding-left: 4px;display: inline;">
        Neues Event erstellen
      </h1>
      <div class="pad-4" />
      <div class="grid-12">
        <div class="col-sp-12 col-sd-4 row-sp-12">
          <div class="pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <section id="uploadBox" style="position: relative; z-index: 2;">
                <h3>Titelbild auswählen</h3>
                <h5>PNG oder JPEG (1200 x 640px)</h5>
                <cropper-upload @cropped="c => {this.titleImage = c; this.titleImageTemplate = false}" :ratio="1.875" ref="cropper">
                  <button class="button bgcolor-blue color-white tiny">
                    Eigene Datei hochladen
                  </button>
                </cropper-upload>
                <div class="divider">
                  oder
                </div>
                <button @click="imageTemplatesModal = 1" class="button bgcolor-black color-white tiny">
                  Aus Vorlagen auswählen
                </button>
              </section>
              <img class="responsive-img br-10" style="position: relative; z-index: 1; margin-top: -100%;" :src="config.projectURL + 'assets/images/events/templates/' + titleImageTemplate" v-if="titleImageTemplate">
            </div>
          </div>
          <div class="pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <h3 style="padding-left: 4px">
                Ticketmanager
              </h3>
              <div class="grid-12">
                <div class="col-sp-6 row-sp-12">
                  <h4 style="padding-left: 4px; padding-top: 4px;">
                    Sind Tickets erforderlich?
                  </h4>
                </div>
                <div class="col-sp-3 row-sp-12" @click="ticketSale = 1;">
                  <input id="radio1" type="radio" value="1" name="ticketSale" checked v-model="ticketSale">
                  <label for="radio1" style="padding-left: 4px;">Ja</label>
                </div>
                <div class="col-sp-3 row-sp-12" @click="ticketSale = 0; ticketImage = null">
                  <input id="radio2" type="radio" value="0" name="ticketSale" v-model="ticketSale">
                  <label for="radio2" style="padding-left: 4px;">Nein</label>
                </div>
              </div>
              <div v-if="ticketSale == true">
                <ticketItems v-for="titems in ticketItems" :ticket-items="titems" :key="titems.id" @update="getDraftTicketItems()" />
              </div>
              <section id="ticketBox" v-if="ticketSale == true">
                <div v-if="ticketItems == '' && (ticketSaleLink == null || ticketSaleLink == '')">
                  <h3>Tickets anlegen</h3>
                  <h5>Verkaufe direkt über unsere Plattform Tickets für dein Event oder hinterlege deinen aktuellen Ticketshop.</h5>
                  <button class="button bgcolor-blue color-white tiny" @click="createTicketModal = true;">
                    Tickets erstellen
                  </button>
                  <div class="divider">
                    oder
                  </div>
                  <button class="button bgcolor-black color-white tiny" @click="linkedTicketshopModal = true;">
                    Ticketshop verlinken
                  </button>
                </div>
                <div class="t-center" v-else-if="ticketItems != ''">
                  <button class="button bgcolor-blue color-white tiny" @click="createTicketModal = true;">
                    Weiteres Ticket erstellen
                  </button>
                </div>
                <div class="t-center" v-else>
                  <button class="button bgcolor-black color-white tiny" @click="linkedTicketshopModal = true;">
                    Link zum Ticketshop anpassen
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="col-sp-12 col-sd-8 row-sp-12">
          <div class="pad-4">
            <div class="bgcolor-white pad-16 br-10 eventDataForm">
              <h3 style="padding-top: 2px; padding-left: 4px">
                Eventdaten
              </h3>
              <h4 style="padding-left: 4px;">
                Allgemein
              </h4>
              <div class="grid-12">
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <input type="text" placeholder="Titel (max. 40 Zeichen)" maxlength="40" v-model="title">
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <input type="text" placeholder="Subtitel (max. 40 Zeichen)" maxlength="40" v-model="subTitle">
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <textarea type="text" rows="12" placeholder="Beschreibung & Informationen" v-model="description" />
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <label for="category">Kategorie</label>
                    <select id="category" name="category" v-model="category">
                      <option value="" selected>
                        Kategorie auswählen
                      </option>
                      <option value="Club">
                        Club
                      </option>
                      <option value="Rave">
                        Rave
                      </option>
                      <option value="Konzert">
                        Konzert
                      </option>
                      <option value="Straßenfest">
                        Straßenfest
                      </option>
                      <option value="Festival">
                        Festival
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <label for="labels">Eigenschaften</label>
                    <select id="labels" multiple v-model="labels">
                      <option :value="citems.id" v-for="citems in labelOptions" :key="citems.id">
                        {{ citems.emoji }} {{ citems.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <label>Barkarte</label>
                    <select v-model="barMenu">
                      <option :value="null">
                        Keine
                      </option>
                      <option :value="menu.id" v-for="menu in barMenus" :key="menu.id">
                        {{ menu.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <input id="eventlyPay" type="checkbox" v-model="eventlyPay">
                    <label for="eventlyPay" style="font-size: 0.9em"> Zahlungen mit <a href="#" class="color-blue">eventlyPay</a>  ist auf dem Event möglich</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pad-4">
            <div class="bgcolor-white pad-16 br-10 eventDataForm">
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label for="startTime">Start</label>
                    <input type="datetime-local" id="startTime" v-model="startTime">
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label for="endTime">Ende</label>
                    <input type="datetime-local" id="endTime" v-model="endTime">
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <SimpleTypeahead
                      id="city_search"
                      placeholder="Suche nach Orten..."
                      :items="cities"
                      :min-input-length="1"
                      :item-projection="item => {return item.name}"
                      @select-item="item => {this.city = item}"
                      @on-input="loadCities"
                    />
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <input type="text" placeholder="Adresse" v-model="location">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pad-4">
            <div class="bgcolor-white pad-16 br-10 eventDataForm">
              <div class="grid-12">
                <div class="col-sp-12 col-tb-12 row-sp-12 pad-4">
                  <div class="field">
                    <div>Lounges</div>
                    <lounge-select :lounge-templates="loungeTemplates" :lounges="lounges" @add-lounge="addLounge" @remove-lounge="removeLounge" />
                  </div>
                </div>
              </div>
            </div>
            <div class="t-right">
              <p class="t-center color-red" style="font-size: 0.8em;" v-if="eventError != ''">
                {{ eventError }}
              </p>
              <div class="pad-4" />
              <button @click="publishEvent(0)" class="button bgcolor-second color-white" style="margin-right: 8px;">
                Als Entwurf speichern
              </button>
              <button @click="publishEvent(1)" class="button bgcolor-blue color-white">
                Veröffentlichen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VueModal :show="createTicketModal" cancel="Abbrechen" approve="Ticket Erstellen" @cancel="createTicketModal = false" @close="createTicketModal = false" @approve="createDraftTicket">
    <h3 style="padding-left: 16px; font-size: 1.4em; margin-top: 16px;">
      Ticket erstellen
    </h3>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-12 row-sp-12">
        <div class="pad-4">
          <div class="eventDataForm">
            <div class="grid-12">
              <div class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <input type="text" placeholder="Ticketname (max. 20 Zeichen)" maxlength="20" required v-model="ticketTitle">
                </div>
              </div>
              <div class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <textarea type="text" rows="4" placeholder="Beschreibung & Informationen" v-model="ticketDescription" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pad-4">
          <div class="eventDataForm">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                <div class="field">
                  <label>Verkaufsstart</label>
                  <input type="datetime-local" v-model="sellStart" required>
                </div>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                <div class="field">
                  <label>Verkaufsende</label>
                  <input type="datetime-local" v-model="sellEnd" required>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pad-4">
          <div class="eventDataForm">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                <div class="field">
                  <label for="price">Gebühr <span style="color: rgba(0,0,0,0.6); font-size: 0.8em;">(inkl. MwSt.)</span></label>
                  <select v-model="feeType">
                    <option value="none">
                      Keine
                    </option>
                    <option value="ticket">
                      Pro Ticket
                    </option>
                    <option value="order">
                      Pro Bestellung
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                <div class="field" v-if="feeType != 'none'">
                  <label for="fee">Gebühr</label>
                  <input type="number" min="0.00" max="10000.00" step="0.01" id="fee" required placeholder="Ticketgebühr (€)" v-model="fee">
                </div>
              </div>
            </div>
          </div>
          <div class="eventDataForm">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                <div class="field">
                  <label for="price">Brutto-Endpreis <span style="color: rgba(0,0,0,0.6); font-size: 0.8em;">(inkl. MwSt.)</span></label>
                  <input type="number" min="0.00" max="10000.00" step="0.01" id="price" required placeholder="Ticketpreis (€)" v-model="price">
                </div>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                <div class="field">
                  <label for="quantity">Verfügbare Tickets</label>
                  <input type="number" id="quantity" v-model="quantity" required placeholder="Anzahl an Verfügbaren Tickets">
                </div>
              </div>
              <div class="col-sp-12 row-sp-12 pad-8">
                <div class="field">
                  <input id="personal" type="checkbox" v-model="personal">
                  <label for="personal" style="font-size: 0.9em">  Tickets sind personengebunden und können nicht weitergegeben werden</label>
                </div>
              </div>
            </div>
          </div>
          <p class="t-center color-red" style="font-size: 0.8em;" v-if="ticketError != ''">
            {{ ticketError }}
          </p>
        </div>
      </div>
    </div>
  </VueModal>
  <VueModal :show="linkedTicketshopModal" cancel="löschen" approve="Okay" @cancel="ticketSaleLink = null; linkedTicketshopModal = false" @approve="linkedTicketshopModal = false">
    <h3 style="padding-left: 16px; font-size: 1.4em; margin-top: 16px;">
      Ticketshop verlinken
    </h3>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-12 row-sp-12">
        <div class="pad-4">
          <div class="bgcolor-white pad-8 br-10 eventDataForm">
            <div class="grid-12">
              <div class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <label for="ticketSaleLink">Link zum Ticketshop</label>
                  <input id="ticketSaleLink" type="text" placeholder="https://www.deinticketshop.de...." v-model="ticketSaleLink">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueModal>
  <VueModal :show="imageTemplatesModal" cancel="abbrechen" @close="imageTemplatesModal = false" @cancel="imageTemplatesModal = false">
    <h3 class="t-center" style="font-size: 1.4em; margin-top: 16px;">
      Titelbild aus Galerie auswählen
    </h3>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-12 row-sp-12">
        <div class="pad-4">
          <div class="bgcolor-white pad-8 br-10">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-01.jpg')">
                <img src="assets/images/events/templates/template-01.jpg" class="br-10 responsive-img">
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-02.jpg')">
                <img src="assets/images/events/templates/template-02.jpg" class="br-10 responsive-img">
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-03.jpg')">
                <img src="assets/images/events/templates/template-03.jpg" class="br-10 responsive-img">
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-04.jpg')">
                <img src="assets/images/events/templates/template-04.jpg" class="br-10 responsive-img">
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-05.jpg')">
                <img src="assets/images/events/templates/template-05.jpg" class="br-10 responsive-img">
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-06.jpg')">
                <img src="assets/images/events/templates/template-06.jpg" class="br-10 responsive-img">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueModal>
</template>

<script>
import SimpleTypeahead from "vue3-simple-typeahead";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import CropperUpload from "@/components/images/cropperUpload";
import ticketItems from "@/components/events/ticket";
import {aSyncData} from "@/modules/main";
import LoungeSelect from "@/components/lounges/loungeSelect";

export default {
  name: "eventCreate",
  title: 'Create Event',
  components: {
    LoungeSelect,
    CropperUpload,
    SimpleTypeahead,
    ticketItems: ticketItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      ticketSale: true,
      cities: [],
      city: {},
      searchString: '',
      title: '',
      subTitle: '',
      description: '',
      labels: '',
      category: '',
      ticketSaleLink: null,
      eventlyPay: null,
      startTime: null,
      endTime: null,
      titleImage: null,
      titleImageTemplate: null,
      createTicketModal: false,
      linkedTicketshopModal: false,
      draftId: Math.random().toString(36).substr(2),
      ticketTitle: '',
      ticketDescription: '',
      price: 0,
      quantity: 0,
      personal: '',
      ticketItems: '',
      public: 0,
      labelOptions: [],
      imageTemplatesModal: false,
      location: '',
      sellStart: null,
      sellEnd: null,
      feeType: 'none',
      fee: 0,
      loungeTemplates: [],
      lounges: [],
      barMenus: [],
      barMenu: null,
      ticketError: '',
      eventError: ''
    }
  },
  mounted() {
    this.config.loading = false
    this.getCategoryOptionItems()
    this.getLoungeTemplates()
    this.getBarMenus()
  },
  methods: {
    validateEventCreation(){
      this.eventError = '';
      if(!this.city.id){
        this.eventError = 'Bitte wähle eine Stadt aus der Liste aus.';
      }else{
        this.eventError = '';
        return true;
      }
      return false
    },
    publishEvent(publicState){
      if(!this.validateEventCreation()) return
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('ticketSale', this.ticketSale ? 1 : 0)
      fD.append('ticketSaleLink', this.ticketSaleLink)
      fD.append('title', this.title)
      fD.append('labels', this.labels)
      fD.append('subTitle', this.subTitle)
      fD.append('description', this.description)
      fD.append('category', this.category)
      fD.append('eventlyPay', this.eventlyPay)
      fD.append('startTime', this.startTime)
      fD.append('endTime', this.endTime)
      fD.append('city', this.city.id)
      fD.append('location', this.location)
      fD.append('public', publicState)
      fD.append('draftId', this.draftId)
      fD.append('barMenu', this.barMenu)
      if(this.titleImageTemplate) {
        fD.append('titleImageTemplate', this.titleImageTemplate)
      }
      else if(this.titleImage) {
        fD.append('titleImage', this.titleImage, 'titleImage')
      }
      fD.append('lounges',JSON.stringify(this.lounges))
      this.axios.post('events/createEvent', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(r => {
            console.log(r.data)
            if(r.status === 250){
              _this.$router.push('/events')
            }
          })
          .catch(e => {console.error(e)})
    },
    loadCities(event){
      const _this = this
      this.city = {}
      this.searchString = event.input
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('search', event.input)
      this.axios.post('events/cities', fD)
          .then(r => {
            if(r.status === 250 && _this.searchString === event.input) _this.cities = r.data
          })
          .catch(e => {console.error(e)})
    },
    createDraftTicket(){
      if (this.validateTicketCreation()){
        aSyncData('ticketcenter/createDraftTicket', {
          ticketTitle: this.ticketTitle,
          ticketDescription: this.ticketDescription,
          price: this.price,
          quantity: this.quantity,
          draftId: this.draftId,
          personal: this.personal,
          sellStart: this.sellStart,
          sellEnd: this.sellEnd,
          fee: this.fee,
          feeType: this.feeType
        })
            .then(r => {
              console.log(r)
              if(r.status === 250){
                this.ticketTitle = null;
                this.ticketDescription = null;
                this.price = null;
                this.quantity = null;
                this.personal = null;
                this.createTicketModal = false
                this.getDraftTicketItems()
              }
            })
            .catch(e => {console.error(e)})
      }
    },
    validateTicketCreation(){
      this.ticketError = '';
      if(!this.ticketTitle.length >= 1 && !this.ticketTitle.length <= 20){
        this.ticketError = 'Bitte gebe einen Ticket-Titel ein, der maximal 20 Zeichen beträgt.';
      }else if(this.sellStart == null || this.sellEnd == null){
        this.ticketError = 'Bitte gebe einen Verkaufsstart und Ende an.';
      }else if(!(this.quantity >= 0) || this.price === ''){
        this.ticketError = 'Bitte gebe einen Ticketpreis und Menge an.';
    }else{
        this.ticketError = '';
        return true;
      }
    },
    getDraftTicketItems(){
      console.log('Test')
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('draftId', this.draftId)
      this.axios.post('ticketcenter/getDraftTicketItems', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.ticketItems = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getBarMenus(){
      aSyncData('barMenu/getBarMenues', {})
          .then(r => {
            if(r.status === 250){
              this.barMenus = r.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getCategoryOptionItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('events/getCategoryOptionItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.labelOptions = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getLoungeTemplates(){
      const _this = this
      aSyncData('lounges/getTemplates', {organizerId: this.config.organizerId})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.loungeTemplates = r.data
            }
          })
    },
    eventImageTemplate(template){
      this.titleImageTemplate = template
      this.titleImage = false
      this.imageTemplatesModal = false
      this.$refs.cropper.reset()
    },
    addLounge(loungeId){
      if(!this.lounges.some(i => i.id === loungeId)) {
        this.loungeTemplates.forEach(i => {
          if(i.id === loungeId) this.lounges.push(i)
        })
      }
    },
    removeLounge(loungeId){
      this.lounges = this.lounges.filter(l => l.id !== loungeId)
    }
  }
}
</script>

<style>
#createEvent h1{
  font-size: 2em;
}
#createEvent h3{
  font-size: 1.2em;
}

#createEvent h4{
  font-size: 0.9em;
  color: rgba(0,0,0,0.6);
  margin-top: -4px;
  margin-bottom: 8px;
}
#uploadBox{
  border-radius: 10px;
  border: 2px dashed rgba(0,0,0,0.1);
  text-align: center;
  padding: 36px 32px;
}
#uploadBox h5{
  font-size: 0.9em;
  color: rgba(0,0,0,0.4);
  margin-bottom: 16px;
}
.eventDataForm .field input,
.eventDataForm .field textarea,
.eventDataForm .field select{
  display: block;
  padding: 12px;
  width: 100%;
  max-width: calc(100% - 20px);
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
}
.eventDataForm .field select{
  max-width: 100%;
}
.eventDataForm .field input[type=checkbox]{
  width: auto;
  display: inline;
  margin-right: 2px;
}
#ticketBox{
  border-radius: 10px;
  border: 2px dashed rgba(0,0,0,0.1);
  text-align: center;
  padding: 52px 32px;
  margin-top: 16px;
}
#ticketBox h5{
  font-size: 0.9em;
  color: rgba(0,0,0,0.4);
  margin-bottom: 16px;
}
#imageTemplatesModal img{
  transition: 0.3s;
  cursor: pointer;
}
#imageTemplatesModal img:hover{
  transform: scale(1.05);
}
</style>