<template>
  <div id="ticketCenter" class="content-1400">
    <div class="pad-8">
      <div class="grid-12">
        <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
          <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
            Übersicht
          </h2>
          <h1>Ticketcenter</h1>
        </div>
        <div class="col-sp-12 col-sd-4 row-sp-12 pad-4 t-right">
          <router-link :to="{name: 'ticketsales'}" class="button color-white secondary">Verkäufe</router-link>
        </div>
        <div class="col-sp-12 col-tb-8 row-sp-12 pad-4">
          <form>
            <input type="search" id="ticketSearch" placeholder="Suche nach Tickets..." v-model="searchQuery">
          </form>
          <div class="bgcolor-white br-10 pad-16" id="ticketResults">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-6 row-sp-12">
                <h3>Ticket-Varianten</h3>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12 t-right">
                <router-link :to="{name: 'TicketCreate'}" class="button bgcolor-blue color-white">
                  Ticket-Variante erstellen
                </router-link>
              </div>
            </div>
            <div>
              <ticketItems v-for="titems in filteredTickets" :ticket-items="titems" :customer="customer" :key="titems.id" />
            </div>
          </div>
        </div>
        <div class="col-sp-12 col-tb-4 row-sp-12 pad-4">
          <div class="bgcolor-white br-10 pad-16" id="statsBox">
            <div class="grid-12">
              <div class="col-sp-12 row-sp-12">
                <h3>Statistik</h3>
              </div>
              <div class="col-sp-12 row-sp-12">
                <h4>Tickets verkauft:</h4>
              </div>
              <div class="col-sp-12 row-sp-12 t-right">
                <h4>{{ soldCount }}</h4>
              </div>
              <div class="col-sp-12 row-sp-12">
                <h4>Gesamt-Umsatz:</h4>
              </div>
              <div class="col-sp-12 row-sp-12 t-right">
                <h4>{{ soldPrice.toFixed(2) }}€</h4>
              </div>
            </div>
          </div>
          <div class="pad-4" />
          <div class="bgcolor-blue pad-16 br-10">
            <h3 style="padding-left: 4px; font-size: 1.2em;" class="color-white">
              Promotion - Erreiche mehr Menschen
            </h3>
            <h4 style="padding-left: 4px; padding-top: 4px; margin-top: -4px; font-size: 0.9em; margin-bottom: 8px; color: rgba(255,255,255,0.7)">
              Möchtest du mehr Tickets verkaufen?
            </h4>
            <div class="t-right">
              <router-link to="/notificationservice" class="button bgcolor-second color-white">
                Promotion erstellen
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import ticketItems from "@/components/ticketcenter/ticket";
import {aSyncData} from "@/modules/main";

export default {
  name: "Ticketcenter",
  title: "Ticketcenter",
  components: {
    ticketItems: ticketItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      ticketItems: [],
      customer: [],
      searchQuery: ''
    }
  },
  computed: {
    filteredTickets(){
      const _this = this
      return this.ticketItems.filter(i => {
        return i.title.toLowerCase().includes(_this.searchQuery.toLowerCase()) || i.ticketTitle.toLowerCase().includes(_this.searchQuery.toLowerCase())
      })
    },
    soldCount(){
      return this.ticketItems.reduce( (a, b) =>{
        return a + b.soldCount
      }, 0)
    },
    soldPrice(){
      return this.ticketItems.reduce( (a, b) =>{
        return a + b.soldPrice
      }, 0)
    }
  },
  mounted() {
    this.config.loading = false
    this.getTicketItems()
  },
  methods: {
    getTicketItems(){
      aSyncData('ticketcenter/getTicketItems', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250){
              console.log(r.data)
              this.ticketItems = r.data.tickets
              this.customer = r.data.customer
            }
          })
    }
  }
}
</script>

<style scoped>
#ticketCenter{
  margin-top: 16px;
}
#ticketCenter h1{
  font-size: 2em;
}
#ticketSearch{
  padding: 12px;
  border-radius: 10px;
  border: none;
  font-size: 1em;
  margin-bottom: 16px;
  width: 100%;
}
#ticketResults h3,
#statsBox h3{
  font-size: 1.6em;
  margin-bottom: 16px;
  font-weight: 800;
}
#statsBox h4{
  font-size: 1.1em;
}
#statsBox .t-right h4{
  font-weight: 400;
  font-size: 1.5em;
  margin-bottom: 16px;
}
</style>