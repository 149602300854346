<template>
  <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-4" @mouseenter="hover = true" @mouseleave="hover = false">
    <article class="event" @click="$router.push({name: 'EventEdit', params: { eventId: eventItems.id }});">
      <div class="event-top">
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12 t-right">
            <div class="label button bgcolor-blue color-white">
              {{ eventItems.categories }}
            </div>
          </div>
        </div>
      </div>
      <img class="eventImage" :src="config.projectURL + 'assets/images/events/'+eventItems.image+'.jpg'">
      <div class="imageCover" />
      <div class="eventWrapper">
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12">
            <h4>{{ eventItems.title }}</h4>
            <h5>{{ eventItems.subTitle }}</h5>
          </div>
        </div>
      </div>
      <div class="copyWrapper" v-if="hover">
        <button @click.stop.prevent="copy" class="button tiny bgcolor-white" title="Kopieren" style="display: inline; width: auto; margin-right: 8px;">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 6V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4zm2 0h4a2 2 0 0 1 2 2v4h4V2H8v4zM2 8v10h10V8H2z" /></svg>
        </button>
        <button @click.stop.prevent="$router.push('/events/media/' + eventItems.id)" class="button tiny bgcolor-white" title="Medien verwalten" style="display: inline; width: auto;">
          <svg style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M457.6,140.2l-82.5-4l-4.8-53.8c-1-11.3-11.1-19.2-22.9-18.3L51.4,88.4c-11.8,1-20.3,10.5-19.4,21.7l21.2,235.8    c1,11.3,11.2,19.2,22.9,18.3l15-1.2l-2.4,45.8c-0.6,12.6,9.2,22.8,22.4,23.5L441.3,448c13.2,0.6,24.1-8.6,24.8-21.2L480,163.5    C480.6,151,470.7,140.8,457.6,140.2z M102.6,145.5l-7.1,134.8L78.1,305l-16-178c0-0.2,0-0.3,0-0.5s0-0.3,0-0.5    c0.5-5,4.3-9,9.5-9.4l261-21.4c5.2-0.4,9.7,3,10.5,7.9c0,0.2,0.3,0.2,0.3,0.4c0,0.1,0.3,0.2,0.3,0.4l2.7,30.8l-219-10.5    C114.2,123.8,103.2,133,102.6,145.5z M436.7,382.4l-46.7-55.3l-27.5-32.7c-2.4-2.9-6.3-5.3-10.6-5.5c-4.3-0.2-7.5,1.5-11.1,4.1    l-16.4,11.9c-3.5,2.1-6.2,3.5-9.9,3.3c-3.6-0.2-6.8-1.6-9.1-3.8c-0.8-0.8-2.3-2.2-3.5-3.4l-42.8-48.9c-3.1-3.9-8.2-6.4-13.8-6.7    c-5.7-0.3-11.2,2.1-14.8,5.6L129.4,359.8l-6.8,7.4l0.3-6.8l6.8-128.9l3.3-62.9c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.5    c1.4-5.4,6.2-9.3,11.9-9l204.2,9.8l28.7,1.4l58.3,2.8c5.8,0.3,10.3,4.7,10.4,10.2c0,0.2,0.3,0.3,0.3,0.5c0,0.2,0.3,0.3,0.3,0.5    L436.7,382.4z" /></g><path d="M373.2,262.3c19.4,0,35.2-15.8,35.2-35.2s-15.7-35.2-35.2-35.2c-19.4,0-35.2,15.7-35.2,35.2S353.7,262.3,373.2,262.3z" /></g></svg>
        </button>
      </div>
    </article>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {vFD} from "@/modules/main";

export default {
  name: "eventItems",
  props: ["eventItems"],
  emits: ['reload'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      timestamp: this.eventItems.timestamp,
      coverttime: '',
      eventid: '',
      hover: false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    copy(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId', this.eventItems.id)
      this.axios.post('/events/copy', fD)
          .then(r =>{
            console.log(r)
            if(r.status === 250){
              _this.$emit('reload')
            }
          })
          .catch(e => {})
    }
  }
}
</script>

<style scoped>
.event{
  position: relative;
  transition: 0.3s;
  cursor: pointer;
}
.event:hover{
  opacity: 0.8;
}
.event .event-top{
  margin-bottom: -16px;
  position: relative;
  z-index: 9;
  clear: both;
}
.event .event-top .label{
  padding: 10px 8px;
  text-transform: none;
  margin-right: 16px;
}
.event .eventImage{
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
}
.event .imageCover{
  position: absolute;
  width: 100%;
  height: calc(100% - 14px);
  border-radius: 10px;
  top: 8px;
  left: 2px;
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}
.event .eventWrapper{
  position: absolute;
  max-width: 100%;
  bottom: 32px;
  z-index: 4;
  left: 24px;
  right: 24px;
}
.event .eventWrapper h4{
  font-size: 1.2em;
  font-family: 'Helvetica';
  font-weight: 600;
  color: rgba(255,255,255,1);
  margin-bottom: 0px;
}
.event .eventWrapper h5{
  font-size: 1em;
  font-family: 'Helvetica';
  font-weight: 300;
  color: rgba(255,255,255,0.8);
}
.copyWrapper{
  position: absolute;
  top: 32px;
  left: 20px;
  width: 100%;
}
.copyWrapper button svg{
  width: 20px;

}
</style>