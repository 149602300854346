<template>
  <div id="ticketCenter" class="content-1400">
    <loader v-if="loading"></loader>
    <div class="pad-8">
      <div class="grid-12">
        <div class="col-sp-12 row-sp-12 pad-4">
          <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
            Übersicht
          </h2>
          <h1>Verkaufte Tickets</h1>
        </div>
        <div class="col-sp-12 row-sp-12 pad-4">
          <form>
            <input type="search" id="ticketSearch" placeholder="Suche nach Tickets..." v-model="searchQuery">
          </form>
          <div class="bgcolor-white br-10 pad-16" id="ticketResults">
            <div>
              <article class="ticket" v-for="titems in ticketsFiltered" :key="titems.id">
                <div class="grid-12">
                  <div class="col-sp-12 col-tb-2 row-sp-12">
                    <img class="ticketImage" :src="config.projectURL + 'assets/images/events/'+titems.image+'_thumb.jpg'">
                  </div>
                  <div class="col-sp-9 col-tb-7 row-sp-12 ticketContent">
                    <h6 />
                    <h4>
                      <span class="f-normal">{{ titems.title }} -</span> {{ titems.ticketTitle }}
                      <span class="button bgcolor-blue color-white tiny" style="padding: 4px;">{{ titems.price }}€</span>
                    </h4>
                    <div>
                      <div class="pad-4"></div>
                      <h5>Käufer:</h5>
                      <div v-if="titems.userId != null">
                        <router-link class="color-blue" :to="'/customerInfo/'+titems.userId">Nutzer: {{ titems.firstname }} {{ titems.lastname }}</router-link>
                      </div>
                      <div v-else>
                        <p>Gastbestellung</p>
                      </div>
                      <p>{{ titems.email }}</p>
                      <p>{{ titems.phone }}</p>
                    </div>
                  </div>
                  <div class="col-sp-3 row-sp-12 t-right ticketStats">
                    <div>
                      <div class="pad-8" />
                      <button class="bgcolor-blue color-white button tiny" style="margin-bottom: 8px;" @click="resendEmail(titems.id)">
                        Ticket erneut verschicken
                      </button>
                      <button class="bgcolor-black color-white button tiny" @click="download(titems.id)">
                        Ticket herunterladen
                      </button>
                    </div>
                    <div style="margin-top: 8px;">
                      <span class="label scan" v-if="tickets.scanTimestamp != null">
                        Gescant ({{ covertStartTime }})
                      </span>
                      <span class="label unscan" v-else>
                        Noch nicht gescant
                      </span>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VueModal :show="sendTicketModal" @close="sendTicketModal = false" @cancel="sendTicketModal = false" id="sendModal">
    <div class="t-center" s>
      <p>Ticket wurde erfolgreich versendet</p>
      <div class="pad-8"></div>
      <button class="button bgcolor-blue color-white"  @click="sendTicketModal = false">
        Okay
      </button>
    </div>
  </VueModal>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData, searchArray} from "@/modules/main";
import Loader from "@/components/basic/loader";

export default {
  name: "TicketSales",
  title: "TicketSales",
  components: {
    Loader
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      convertStatTime: '',
      tickets: [],
      searchQuery: '',
      loading: false,
      sendTicketModal: false
    }
  },
  computed: {
    ticketsFiltered(){
      return this.tickets.filter(t => {
        return searchArray([t.id, t.email, t.userId, t.ticketTitle, t.title], this.searchQuery)
      })
    }
  },
  mounted() {
    this.config.loading = false
    this.getAllSoldTickets()
  },
  methods: {
    getAllSoldTickets(){
      aSyncData('ticketcenter/getAllSoldTickets', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250){
              console.log(r.data)
              this.tickets = r.data
            }
          })
          .catch(e => {})
    },
    resendEmail(ticketId){
      this.loading = true
      aSyncData('ticketcenter/resendEmail', {ticketId: ticketId})
          .then(r => {console.log(r)})
          .catch(e => {})
          .finally(() => {this.loading = false; this.sendTicketModal = true;})

    },
    download(ticketId){
      this.loading = true
      aSyncData('ticketcenter/download', {ticketId: ticketId}, {
        responseType: 'blob',
      })
          .then(r => {
            if(r.status === 250){
              const link = document.createElement('a')
              link.href = URL.createObjectURL(r.data)
              link.setAttribute('download', ticketId + '-ticket.pdf')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              URL.revokeObjectURL(link.href)
            }
          })
          .catch(e => {})
          .finally(() => {this.loading = false})
    }
  }
}
</script>

<style scoped>
#ticketCenter{
  margin-top: 16px;
}
#ticketCenter h1{
  font-size: 2em;
}
#ticketSearch{
  padding: 12px;
  border-radius: 10px;
  border: none;
  font-size: 1em;
  margin-bottom: 16px;
  width: 100%;
}
.ticket{
  margin-top: 16px;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 16px;
  transition: 0.3s;
}
.ticket:hover{
  background-color: rgba(0,0,0,0.02);
}
.ticket .ticketContent{
  padding: 8px 8px 8px 16px;
}
.ticket .ticketImage{
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background-color: rgba(0,0,0,0.06);
  min-height: 80px;
}
.ticket .ticketContent h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  font-weight: 600;
  margin-top: 4px;
}
.ticket .ticketContent h5{
  font-size: 0.95em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketContent h6{
  font-size: 0.9em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketStats span{
  margin-bottom: 8px;
}
.field input,
.field textarea{
  display: block;
  width: calc(100% - 24px);
  max-width: calc(100% - 24px);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
.field select{
  display: block;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
.label{
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 0.9em;
}
.label.scan{
  background-color: #9bf2d233;
  color: #67cba6;
}
.label.unscan{
  background-color: #2068ff33;
  color: #2068ff;
}
</style>
<style>
#sendModal .vueModalInner{
  min-height: 100px!important;
}
#sendModal .t-center.pad-8{
  overflow-y: hidden!important;
}
</style>