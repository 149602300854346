<template>
  <div>
    <main>
      <section v-if="step === 1" id="GetStarted">
        <div class="content-500">
          <div class="t-center pad-32">
            <h2 class="f-normal">
              Willkommen bei
            </h2>
            <h1>
              event<span class="color-orange">ly</span>
            </h1>
          </div>
          <img id="avatar-selection" :src="avatar" class="centered circular width-150 bgcolor-second is-square">
          <div class="t-center pad-16" v-if="avatarBlob">
            <button class="button bgcolor-second color-white circular" @click="step = 2">
              Weiter
            </button>
          </div>
          <div class="t-center pad-16" v-else>
            <button class="button bgcolor-blue color-white circular" @click="avatarModal = true">
              Profilbild hochladen
            </button>
            <div class="pad-4" />
            <button class="button bgcolor-second color-white circular" @click="step = 2">
              Überspringen
            </button>
          </div>
        </div>
      </section>
      <section v-if="step === 2" id="NameSelection">
        <div class="content-500">
          <img :src="avatar" class="centered circular width-150 bgcolor-second is-square">
          <div class="t-center pad-32">
            <h1 class="f-normal">
              Hallo!
            </h1>
            <h2 class="color-orange">
              Wie dürfen wir dich nennen?
            </h2>
          </div>
          <div class="t-center">
            <div class="field simple pad-8">
              <input type="text" name="firstname" placeholder="Vorname" required v-model="firstname">
            </div>
            <div class="field simple pad-8">
              <input type="text" name="lastname" placeholder="Nachname" required v-model="lastname">
            </div>
          </div>
          <div class="t-center pad-16">
            <button class="button bgcolor-orange circular color-white" @click="step = 3" v-if="firstname.length > 0 && lastname.length > 0">
              Weiter ➔
            </button>
          </div>
        </div>
      </section>
      <section v-if="step === 3" id="EmailSelection">
        <div class="content-500">
          <img :src="avatar" class="centered circular width-150 bgcolor-second is-square">
          <div class="t-center pad-32">
            <h1 class="f-normal">
              Hallo {{ firstname }}!
            </h1>
            <h2 class="color-orange">
              Gleich kann's losgehen!
            </h2>
          </div>
          <div class="t-center">
            <div class="field simple pad-8">
              <input type="email" name="email" placeholder="Deine E-Mail-Adresse" required v-model="email">
            </div>
            <div class="field simple pad-8">
              <input type="password" name="password" placeholder="Passwort" required v-model="password">
            </div>
            <div class="field simple pad-8">
              <input type="password" name="password2" placeholder="Passwort wiederholen" required v-model="password2">
            </div>
            <p class="feedbackmessage t-center pad-8 color-orange" v-if="status === 'email'">
              Es gibt bereits einen Account mit dieser E-Mail.
              <router-link to="/login" class="color-black">
                Jetzt einloggen.
              </router-link>.
            </p>
            <p class="feedbackmessage t-center pad-8 color-orange" v-if="!emailCheck && email.length > 0">
              Bitte überprüfe deine E-Mail-Adresse
            </p>
            <p class="feedbackmessage t-center pad-8 color-orange" v-if="!passwordCheck && password2.length > 0">
              Das Passwort ist nicht identisch oder kürzer als 8 Zeichen.
            </p>
          </div>
          <div class="t-center pad-16">
            <button @click="register" class="button color-white bgcolor-orange circular" v-if="passwordCheck && emailCheck">
              Los gehts!
            </button>
          </div>
        </div>
      </section>
    </main>
    <VueModal :show="avatarModal" @close="avatarModal = false" approve="ok" @approve="avatarModal = false">
      <cropper-upload :init="true" :ratio="1" @cropped="c => this.avatarBlob = c" />
    </VueModal>
  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from '@/store/authStore';
import {configStore} from "@/store/configStore";
import CropperUpload from "@/components/images/cropperUpload";

export default {
  name: "Register",
  components: {CropperUpload},
  title: "Register",
  setup(){
    const auth = authStore()
    const config = configStore()

    return {auth, config}
  },
  data(){
    return{
      step: 1,
      avatar: 'default.svg',
      avatarBlob: null,
      email: '',
      password: '',
      password2: '',
      firstname: '',
      lastname: '',
      status: '',
      avatarModal: false,
    }
  },
  computed:{
    passwordCheck(){
      return this.password.length > 7 && this.password === this.password2
    },
    emailCheck(){
      return this.email.match(/(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g)
    },
    avatar(){
      return this.avatarBlob ?  URL.createObjectURL(this.avatarBlob) : '/assets/images/users/default.svg'
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    register(){
      const _this = this
      this.auth.register(this.email, this.firstname, this.lastname, this.password, this.avatarBlob, this.$route.query.invite)
          .then((response) => {
            if(response === 250){
              _this.status = 'ok'
              _this.$router.push('/dashboard')
            }else if(response === 251){
              _this.status = 'email'
            }
          })
          .catch(() => {
            _this.status = 'fail'
          })
    },
  }
}
</script>

<style scoped>
.field.simple input{
  border-radius: 8px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  color: #000000;
}
#GetStarted, #NameSelection, #EmailSelection{
  padding-top: 20vh;
}
#GetStarted h2{
  color: rgba(0,0,0,0.8);
}
#SelectionPad{
}
#SelectionPad .grid-12{
  max-width: 240px;
  margin: 0 auto;
  padding-bottom: 32px;
}
#SelectionPad img{
  border: 2px solid rgba(0,0,0,0.2);
  transition: 0.3s;
  opacity: 0.8;
}
#SelectionPad img:hover,
#SelectionPad img.active{
  border-color: rgba(0,0,0,0.4);
  opacity: 1;
}
#Register{
  padding-top: 20vh;
}
#GetStarted h2{
  font-size: 1.4em;
}
#GetStarted h1{
  font-size: 1.2em;
}
.feedbackmessage{
  font-size: 0.8em;
}
.vueModalInner{
  background-color: #fde8db!important;
}
</style>