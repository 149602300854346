<template>
  <main id="dashBoard">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Hey {{ user.firstname }},
        </h2>
        <h1 style="padding-left: 4px;">
          Willkommen im Dashboard
        </h1>
        <div v-if="user.checkPermission('viewSales')">
          <div class="pad-8" />
          <div class="grid-12">
            <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
              <div class="bgcolor-white pad-16 br-10">
                <h3 style="padding-top: 2px; font-size: 1.4em;">
                  Guthaben: {{ config.getOrganizerCredits }}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="pad-8" />
        <div class="grid-12">
          <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <h3 style="padding-top: 2px; font-size: 1.4em;">
                Umsatz im aktuellen Monat
              </h3>
              <div class="pad-8" />
              <div class="pad-40 t-center br-10 bgcolor-lightgrey">
                <div class="pad-40" />
                <div class="pad-40" />
                <p>Hier folgt eine Grafik zum Umsatz</p>
                <div class="pad-40" />
                <div class="pad-40" />
              </div>
              <div class="t-right">
                <div class="pad-4" />
                <router-link to="/kundeninfo" class="button bgcolor-blue color-white">
                  Mehr Informationen
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <div class="grid-12">
                <div class="col-sp-8 row-sp-12">
                  <h3 style="padding-top: 2px;">
                    Deine nächsten Events
                  </h3>
                </div>
                <div class="col-sp-4 row-sp-12 t-right">
                  <router-link to="/events/create" class="button bgcolor-blue tiny color-white">
                    Erstellen
                  </router-link>
                </div>
              </div>
              <eventItems v-for="eitems in eventItems" :event-items="eitems" :key="eitems.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import { authStore } from '@/store/authStore'
import { configStore } from '@/store/configStore'
import { userStore } from '@/store/userStore'
import eventItems from '@/components/dashboard/event'
import {aSyncData} from "@/modules/main";
import {notificationStore} from "@/store/notificationStore";

export default {
  name: 'Dashboard',
  title: 'Dashboard',
  components: {
    eventItems: eventItems
  },
  setup () {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const notification = notificationStore()

    return { auth, config, user, notification }
  },
  data () {
    return {
      eventItems: []
    }
  },
  mounted () {
    this.config.loading = false
    this.user.getGeo()
    //this.getEvents()
    if(this.auth.isAuth) this.notification.pushToken()
  },
  methods: {
    getEvents () {
      const _this = this
      aSyncData('dashboard/geteventItems', {organizerId: this.config.organizerId})
        .then(r => {
          if (r.status === 250) {
            _this.eventItems = r.data
          }
        })
    }
  }
}
</script>

<style>
  main#dashBoard{
    /*padding-bottom: 80px;*/
  }
  #dashBoard h1{
    font-size: 2em;
  }
  #dashBoard h3{
    font-size: 1.2em;
  }
  .vueModal{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(20px) brightness(0.5);
  }
  .vueModalInner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 20px;
    border-radius: 10px;
    background-color: white;
  }
</style>
