<template>
  <div class="template" v-if="userInfo.id">
    <div id="customerinfo-wrapper">
      <div>
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Kundenübersicht
        </h2>
        <h1 style="padding-left: 4px;">
          Umsätze & Kundeninformation
        </h1>
      </div>
      <div class="field" id="backButton">
        <router-link class="button bgcolor-black color-white" to="/kundeninfo">
          Zurück zur Übersicht
        </router-link>
      </div>
    </div>
    <div class="userinfo-wrapper">
      <div class="pad-4">
        <div class="bgcolor-white pad-16 br-10">
          <div class="info">
            <div class="info-wrapper">
              <h3>Vorname:</h3>
              <div>{{ userInfo.firstname }}</div>
            </div>
            <div class="info-wrapper">
              <h3>Nachname:</h3>
              <div>{{ userInfo.lastname }}</div>
            </div>
            <div class="info-wrapper">
              <h3>Geburtstag:</h3>
              <div>{{ userInfo.dob }}</div>
            </div>
            <div class="info-wrapper">
              <h3>E-Mail:</h3>
              <div>{{ userInfo.email }}</div>
            </div>
            <div class="info-wrapper">
              <h3>Registriert am:</h3>
              <div>{{ userInfo.register_timestamp }}</div>
            </div>
          </div>
          <div id="details">
            <hr>
            <div class="grid-12">
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <span>Event</span>
              </div>
              <div class="col-sp-12 col-tb-3 row-sp-12">
                <span>Datum Event</span>
              </div>
              <div class="col-sp-12 col-tb-3 row-sp-12">
                <span>Datum Kauf</span>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <span>Umsatz</span>
              </div>
              <div class="col-sp-12 col-tb-2 row-sp-12">
                <span>Typ</span>
              </div>
            </div>
            <sale-events v-for="event in sales" :key="event.eventId" :event="event" />
            <hr>
          </div>
          <div class="map">
            <h3>Tracking Auswahl</h3>
            <div class="mapButtonWrapper">
              <div @click="gps = !gps" :class="{active: gps}" id="button-check">
                GPS
              </div>
              <div @click="ip = !ip" :class="{active: ip}" id="button-check">
                IP
              </div>
            </div>
            <ol-map :load-tiles-while-animating="true" :load-tiles-while-interacting="true" style="height:400px; width: 100%">
              <ol-view
                ref="view" :center="center" :rotation="rotation" :zoom="zoom"
                :projection="projection"
              />
              <ol-tile-layer>
                <ol-source-osm />
              </ol-tile-layer>
              <ol-interaction-clusterselect @select="featureSelected" :point-radius="20">
                <ol-style>
                  <ol-style-stroke color="green" :width="5" />
                  <ol-style-fill color="rgba(255,255,255,0.5)" />
                  <ol-style-icon src="assets/images/icons/marker.png" :scale="0.05" />
                </ol-style>
              </ol-interaction-clusterselect>
              <ol-animated-clusterlayer :animation-duration="500" :distance="40">
                <ol-source-vector ref="vectorsource">
                  <ol-feature v-for="(position, index) in positionsGPS" :key="index">
                    <ol-geom-point
                      :coordinates="[position.lon, position.lat]"
                      v-if="gps"
                    />
                  </ol-feature>
                  <ol-feature v-for="(position, index) in positionsIP" :key="index">
                    <ol-geom-point
                      :coordinates="[position.lon, position.lat]"
                      v-if="ip"
                    />
                  </ol-feature>
                </ol-source-vector>

                <ol-style :override-style-function="overrideStyleFunction">
                  <ol-style-stroke color="red" :width="2" />
                  <ol-style-fill color="rgba(255,255,255,0.1)" />

                  <ol-style-circle :radius="20">
                    <ol-style-stroke color="black" :width="15" :line-dash="[]" line-cap="butt" />
                    <ol-style-fill color="black" />
                  </ol-style-circle>

                  <ol-style-text>
                    <ol-style-fill color="white" />
                  </ol-style-text>
                </ol-style>
              </ol-animated-clusterlayer>
            </ol-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {aSyncData} from "@/modules/main";
import {configStore} from "@/store/configStore";
import SaleEvents from "@/components/sales/saleEvents";

export default {
  name: "userInfo",
  components: {SaleEvents},
  props:{
    userId: Number
  },
  setup(){
    const config = configStore()
    return {config}
  },
  data(){
    return{
      userInfo: {},
      positionsGPS: [],
      positionsIP: [],
      sales: [],
      center: [10.9254728, 50.6843502],
      projection: 'EPSG:4326',
      zoom: 6,
      rotation: 0,
      gps: true,
      ip: false
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo(){
      aSyncData('sales/getCustomerInfo', {
        organizerId: this.config.organizerId,
        userId: this.userId
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.userInfo = r.data.user
              this.positionsGPS = r.data.positionsGPS
              this.positionsIP = r.data.positionsIP
              this.sales = r.data.sales
            }
          })
          .catch(e => console.error(e))
    },
    featureSelected(event) {
      console.log(event)
    },
    overrideStyleFunction(feature, style) {

      let clusteredFeatures = feature.get('features');
      let size = clusteredFeatures.length;

      let color = size > 20 ? "192,0,0" : size > 8 ? "255,128,0" : "0,128,0";
      let radius = Math.max(8, Math.min(size, 20));
      let dash = 2 * Math.PI * radius / 6;
      let calculatedDash = [0, dash, dash, dash, dash, dash, dash];

      style.getImage().getStroke().setLineDash(dash);
      style.getImage().getStroke().setColor("rgba(" + color + ",0.5)");
      style.getImage().getStroke().setLineDash(calculatedDash);
      style.getImage().getFill().setColor("rgba(" + color + ",1)");

      style.getImage().setRadius(radius)

      style.getText().setText(size.toString());

    }
  }
}
</script>

<style scoped>

.active{
    background-color: #1F68FF !important ;
    color: white;
  }

  .map h3{
    margin-top: 10px;
    font-weight: 600;
  }

  .mapButtonWrapper{
    display: flex;
    column-gap: 12px;
    margin: 6px 0 20px;
  }

  #button-check{
    display: block;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    width: 100px;
    text-align: center;
    cursor: pointer;
  }



  #customerinfo-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  #backButton button{
    display: block;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }

  #customerinfo-wrapper h1{
    font-size: 2em;
  }
  #customerinfo-wrapper h3{
    font-size: 1.4em;
  }

  .info{
    margin-bottom: 12px;
  }

  .info-wrapper{
    display: flex;
    column-gap: 10px;
    margin: 3px 0;
  }


  .info-wrapper h3{
    font-weight: 600;
    flex: 1;
  }

  .info-wrapper div{
    flex: 6.5;
  }

  #details span{
    font-weight: 600;
    margin: 4px 0 2px;
    display: block;
  }

  @media (max-width: 660px){
    #customerinfo-wrapper{
      flex-wrap: wrap;
    }

    .template{
      width: 100% !important;
    }
  }

</style>