<template>
  <main id="appPay">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-blue">
          Übersicht
        </h2>
        <h1 style="padding-left: 4px;">
          AppPay
        </h1>
        <div class="pad-8" />
        <div class="pad-8">
          <form>
            <div class="field">
              <label style="padding-left: 4px;">Event Auswählen</label>
              <select v-model="eventId" @change="getTransactions()">
                <option value="-1" disabled>
                  Event...
                </option>
                <option v-for="event in events" :key="event.id" :value="event.id">
                  {{ event.title + ' - ' + event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="grid-12" v-if="eventId != -1">
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-8">
            <section class="bgcolor-blue t-center color-white br-10 pad-16" @click="scanner = true">
              <img src="assets/images/icons/scanner-white.svg" class="width-100">
              <h2 style="font-size: 2em;">
                Neue Zahlung
              </h2>
            </section>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 pad-8">
            <section class="bgcolor-white br-10" v-if="eventId != -1">
              <h2 class="pad-16" style="font-size: 1.4em;">
                Letzte Transaktionen
              </h2>
              <div style="overflow-x: scroll;">
                <table style="width: 100%;">
                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>Name</td>
                      <td>Zeitstempel</td>
                      <td>Betrag</td>
                    </tr>
                  </thead>
                  <tbody>
                    <transactionItems v-for="titems in transactionItems" :transaction-items="titems" :key="titems.id" />
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
        <section id="Scanner" v-show="scanner">
          <transition name="scanned">
            <div id="payCheck" class="overlay green" v-if="payed">
              <div class="t-center">
                <div class="pad-24" />
                <img src="assets/images/icons/check-white.svg" class="width-100">
                <h5 style="font-size: 1.5em; margin-bottom: 16px; margin-top: 8px">
                  Zahlung erfolgreich
                </h5>
                <button @click="stopScanner();payed = false;" class="bgcolor-white color-black button">
                  Okay
                </button>
              </div>
            </div>
            <div class="overlay blue" v-else-if="cancel || start" />
          </transition>
          <div v-show="scan" class="videoContainer">
            <video ref="scanner" />
            <button type="button" class="button bgcolor-blue color-white circular" @click="cancelScan">
              Abbrechen
            </button>
            <transition id="payError" name="error">
              <div class="error t-center" v-if="error">
                <div class="pad-24" />
                <img src="assets/images/icons/error-white.svg" class="width-100">
                <h5 style="font-size: 1.5em; margin-bottom: 16px; margin-top: 8px">
                  {{ error }}
                </h5>
                <button @click="error = false" class="bgcolor-white color-black button">
                  Okay
                </button>
              </div>
            </transition>
          </div>
          <div v-if="!scan">
            <div class="grid-12">
              <div class="col-sp-12 col-sd-12 row-sp-2 input">
                {{ (credits / 100).toFixed(2) }} €
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(7)">
                7
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(8)">
                8
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(9)">
                9
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(4)">
                4
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(5)">
                5
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(6)">
                6
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(1)">
                1
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(2)">
                2
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(3)">
                3
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number special" @click="add(0); add(0)">
                00
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number" @click="add(0)">
                0
              </div>
              <div class="col-sp-4 col-sd-4 row-sp-2 number delete" @click="del" style="font-size: 1.5em;">
                Löschen
              </div>
              <div class="col-sp-12 row-sp-2 number approve bgcolor-blue" :class="{disabled: credits === 0}" @click="startScanner" :disabled="credits === 0">
                OK
              </div>
              <div class="col-sp-12 row-sp-2 number color-white bgcolor-second" @click="scanner = false">
                Abbrechen
              </div>
            </div>
          </div>
          <VueModal :show="result" @approve="result = null; initScanner()" approve="Ok">
            {{ JSON.stringify(result, null, 4) }}
          </VueModal>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import transactionItems from "@/components/eventlypay/transaction";
import QrScanner from 'qr-scanner';

export default {
  name: "eventlyPay",
  title: 'eventlyPay',
  components: {
    transactionItems: transactionItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      qrScanner: null,
      paymentIntent: null,
      result: null,
      credits: 0,
      scan: false,
      error: false,
      payed: false,
      cancel: false,
      start: false,
      scanner: false,
      eventId: -1,
      transactionItems: [],
      events: [],
    }
  },
  mounted() {
    this.initScanner()
    this.getTransactions()
    this.getEvents()
  },
  beforeUnmount() {
    try {
      this.qrScanner.stop()
      this.qrScanner.destroy();
      this.qrScanner = null;
    }catch (e) {}
  },
  methods: {
    add(number){
      this.credits = this.credits*10 + number
    },
    del(){
      this.credits = Math.floor(this.credits/10)
    },
    initScanner(){
      const _this = this
      this.qrScanner = new QrScanner(
          this.$refs.scanner,
          _this.scanned,
          {
            highlightScanRegion: true,
            highlightCodeOutline: true
          })
      this.qrScanner.setInversionMode('both');
    },
    startScanner(){
      const _this = this
      this.qrScanner.start()
      this.scan = true
      _this.start = true
      setTimeout(() => {
        _this.start = false
      }, 1000)
    },
    cancelScan(){
      const _this = this
      _this.cancel = true
      setTimeout(() => {
        _this.cancel = false
        this.stopScanner()
      }, 1000)
    },
    stopScanner(){
      try {
        this.credits = 0;
        this.scan = false
        this.qrScanner.stop()
      }catch (e) {}
    },
    scanned(r){
      this.qrScanner.stop()
      const _this = this
      this.paymentIntent = r.data
      const fD = new FormData()
      console.log(this.credits)
      fD.append('sessionID', this.auth.sessionID)
      fD.append('paymentIntent', btoa(this.paymentIntent))
      fD.append('credits', this.credits)
      //fD.append('organizerId', this.config.organizerId) nicht gebraucht da über event geholt
      fD.append('eventId', this.eventId)
      this.axios.post('payment/pay', fD)
          .then(r => {
            console.log(r.data, r.status)
            if(r.status === 250) {
              _this.payed = true
              _this.transactions.push(r.data)
              _this.getTransactions()
              setTimeout(() => {
                _this.stopScanner()
                _this.payed = false
              }, 12000)
            }else{
              _this.error = r.data
              this.qrScanner.start()
              setTimeout(() => {
                _this.error = false
              }, 13000)
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getTransactions() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId',  this.eventId)
      this.axios.post('eventlyPay/getTransactions', fD)
          .then(r => {
            if(r.status === 250) {
              console.log(r.data)
              _this.transactionItems = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    getEvents(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId',  this.config.organizerId)
      this.axios.post('ticketCenter/getEvents', fD)
          .then(r => {
            console.log(r.data, r.status)
            if(r.status === 250) {
              _this.events = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    }
  },
}
</script>

<style scoped>
#appPay h1{
  font-size: 2em;
}
#appPay h3{
  font-size: 1.2em;
}
main#appPay{
  padding-bottom: 80px;
}
section#Scanner{
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  transform: translateX(-50%);
}
.green{
  background-color: #00cc66;
}
.blue{
  background-color: #2068ff;
}
section#Scanner video{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
section#Scanner .videoContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%,-50%);
}
section#Scanner .videoContainer button{
  position: fixed;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
}
section#Scanner .grid-12{
  height: calc(100vh - 80px);
}
section#Scanner .number{
  text-align: center;
  background-color: rgba(255,255,255,1);
  display: flex;
  width: 99%;
  height: 98%;
  margin: auto;
  border: 2px solid rgba(0,0,0,0.05);
  align-items:center;
  justify-content:center;
  font-size: 2.5em;
  cursor: pointer;
}
section#Scanner .input{
  text-align: center;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  align-items:center;
  justify-content:center;
  color: white;
  font-size: 4em;
}
section#Scanner .delete{
  background-color: rgba(0,0,0,0.05);
}
section#Scanner .disabled{
  background-color: grey !important;
}
section#Scanner .button:active{
  top: auto;
}
.scanned-enter-active,
.scanned-leave-active{
  transition: opacity 1s ease;
}
.scanned-enter-from,
.scanned-leave-to {
  opacity: 0;
}

.error-enter-active{
  animation: bounce-in 0.5s;
}
.error-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
#appPay .field select{
  display: block;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: rgba(255,255,255,1);
  padding: 12px;
  margin-bottom: 8px;
}
table thead{
  background-color: rgba(0,0,0,0.05);
  font-weight: bold;
}
table thead tr td{
  padding: 4px;
}
#Scanner{
  position: relative;
}
#Scanner #payCheck{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #00cc66;
  border-radius: 10px;
  z-index: 9;
  padding-top: 20vh;
}
#Scanner #payError{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #BD081C;
  border-radius: 10px;
  z-index: 9;
  padding-top: 20vh;
}
</style>