import { defineStore } from 'pinia'
import {aSyncData} from "@/modules/main";
import {configStore} from "@/store/configStore";
import {authStore} from "@/store/authStore";

export const userStore = defineStore('user', {
    state: () => ({
        id: -1,
        firstname: '',
        lastname: '',
        email: '',
        avatar: '',
        credits: 0,
        geo: false,
        geoCity: {},
        ipCity: {}
    }),
    actions: {
        getGeo() {
            const _this = this
            navigator.geolocation.getCurrentPosition(geo => {
                _this.geo = geo
                console.log(geo)
                aSyncData('dashboard/getCityByGPS', {lat: geo.coords.latitude, lon: geo.coords.longitude})
                    .then(r => {
                        if(r.status === 250) _this.geoCity = r.data
                    })
                    .catch( e => {
                        console.error(e)
                    })
            }, e => {
                console.log(e)
            })
        },
        checkPermission(permission){
            const auth = authStore()
            const config = configStore()
            try {
                return auth.permissions[config.organizerId][permission] === 1
            }catch (e) {
                return  false
            }
        }
    }
})