<template>
  <article class="ticket" @click="$router.push({name: 'TicketEdit', params: { id: ticketItems.id }});">
    <div class="grid-12">
      <div class="col-sp-12 col-tb-2 row-sp-12">
        <img class="ticketImage" :src="config.projectURL + 'assets/images/events/'+ticketItems.eventImage+'_thumb.jpg'">
      </div>
      <div class="col-sp-9 col-tb-7 row-sp-12 ticketContent">
        <h6>{{ covertTime }}</h6>
        <h4>
          <span class="f-normal">{{ ticketItems.title }} -</span> {{ ticketItems.ticketTitle }}
          <span class="button bgcolor-blue color-white tiny" style="padding: 4px;">{{ ticketItems.price }}€</span>
        </h4>
        <h5>{{ ticketItems.ticketDescription }}</h5>
      </div>
      <div class="col-sp-3 row-sp-12 t-right ticketStats">
        <div>
          <span class="bgcolor-lightgrey button tiny">
            {{ ticketItems.soldCount }} / {{ ticketItems.quantity }}
          </span>
        </div>
        <div>
          <span class="bgcolor-blue color-white button tiny">
            {{ ticketItems.soldPrice.toFixed(2) }}€
          </span>
        </div>
        <div>
          <span class="bgcolor-blue color-white button tiny" @click.stop.prevent="sendTicketModal = true; sendStatus = false">
            Ticket verschicken
          </span>
          <span v-if="sendStatus" class="color-green">Ticket versendet</span>
        </div>
      </div>
    </div>
  </article>
  <VueModal :show="sendTicketModal" @close="sendTicketModal = false" @cancel="sendTicketModal = false">
    <div class="pad-8 t-center">
      <h3 class="t-center f-bold" style="font-size: 1.2em;">Freitickets senden</h3>
      <div class="field">
        <select v-model="sendUserId">
          <option :value="0">
            Manuell
          </option>
          <option v-for="single in customer" :key="single.userId" :value="single.userId">
            {{ single.lastname }}, {{ single.firstname }} ({{ single.userId }})
          </option>
        </select>
      </div>
      <div class="field">
        <div v-if="sendUserId < 1">
          <label>E-Mail-Adresse</label>
          <input type="email" v-model="sendEmail" placeholder="E-Mail-Adresse">
        </div>
      </div>
      <div class="field">
        <label>Anzahl</label>
        <input type="number" v-model="sendQuantity" :max="ticketItems.quantity - ticketCount">
      </div>
      <button class="button bgcolor-blue color-white" :disabled="sending" :class="{disabled: sending}" @click="sendTicket">
        Senden
      </button>
    </div>
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "ticketItems",
  props: ["ticketItems", "customer"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertStartTime: '',
      sendTicketModal: false,
      sendQuantity: 1,
      sendEmail: '',
      sendStatus: false,
      sendUserId: 0,
      sending: false
    }
  },
  computed: {
    covertTime(){
      let timestampArr = this.ticketItems.startTime.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      return dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    sendTicket(){
      this.sending = true
      aSyncData('ticketcenter/sendTicket', {
        quantity: this.sendQuantity,
        email: this.sendEmail,
        userId: this.sendUserId,
        ticketId: this.ticketItems.id
      })
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.sendEmail = ''
              this.sendQuantity = 1
              this.sendTicketModal = false
              this.sendStatus = true
              this.sendUserId = 0
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.sending = false
          })
    }
  }
}
</script>

<style scoped>
.ticket{
  margin-top: 16px;
  cursor: pointer;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 16px;
  transition: 0.3s;
}
.ticket:hover{
  background-color: rgba(0,0,0,0.02);
}
.ticket .ticketContent{
  padding: 8px 8px 8px 16px;
}
.ticket .ticketImage{
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background-color: rgba(0,0,0,0.06);
  min-height: 80px;
}
.ticket .ticketContent h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  font-weight: 600;
  margin-top: 4px;
}
.ticket .ticketContent h5{
  font-size: 0.95em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketContent h6{
  font-size: 0.9em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketStats span{
  margin-bottom: 8px;
}
.field input,
.field textarea{
  display: block;
  width: calc(100% - 24px);
  max-width: calc(100% - 24px);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
.field select{
  display: block;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
</style>