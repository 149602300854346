<template>
  <section>
    <div class="grid-12">
      <div class="col-sp-12 row-sp-12 pad-4">
        <h2 class="color-blue" style="font-size: 1.2em;">
          Promotion Center
        </h2>
        <h1>Geburtstag Nachricht</h1>
      </div>
    </div>
  </section>
  <div class="pad-16" />
  <section class="bgcolor-white">
    <div class="grid-12" @input="saved = false">
      <div class="col-sp-6 col-sd-6 row-sp-12">
        <div class="grid-12">
          <div @click="mailActive = !mailActive; saved = false" :class="{active: mailActive}" class="button color-white br-0 col-sp-12 col-sd-12 row-sp-12">
            Mail
          </div>
          <div class="col-sp-12 col-sd-12 row-sp-12 pad-16">
            <transition>
              <div v-if="mailActive">
                <div class="pad-16" />
                <div class="field simple">
                  <label>E-Mail-Design*</label>
                  <select v-model="mail.templateId" style="max-width: 100%;">
                    <option v-for="template in mailTemplates" :key="template.id" :value="template.id">
                      {{ template.name }}
                    </option>
                  </select>
                </div>
                <div class="pad-16" />
                <div class="field simple">
                  <label for="mailSubject">Betreff*</label>
                  <input type="text" v-model="mail.subject" name="mailSubject" style="max-width: calc(100% - 16px);">
                </div>
                <div class="pad-16" />
                <div class="field simple">
                  <label for="mailGreeting">Begrüßung*</label>
                  <input type="text" v-model="mail.greeting" name="mailGreeting" placeholder="Na mein kleiner" style="max-width: calc(100% - 16px);">
                </div>
                <div class="pad-16" />
                <div class="field simple">
                  <label for="mailBody">Text*</label>
                  <textarea v-model="mail.body" name="mailBody" style="max-width: 100%;" />
                </div>
                <div class="pad-16" />
                <div class="field simple">
                  <label for="mailEnding">Grußformel*</label>
                  <textarea v-model="mail.ending" name="mailEnding" placeholder="Hauste rein Schnucki <3,&#10;dein Hasipups" style="max-width: 100%;" />
                </div>
                <div class="pad-16" />
                <div class="field simple">
                  <label for="mailEvents">Events</label>
                  <select v-model="mail.events" multiple name="mailEvents" style="max-width: 100%;">
                    <option v-for="event in events" :value="event.id" :key="event.id">
                      {{ dateString(event.startTime) + ' - ' + event.title }}
                    </option>
                  </select>
                </div>
                <div class="pad-16" />
                <div class="field simple">
                  <label for="mailCoupons">Coupons</label>
                  <select v-model="mail.coupons" multiple name="mailCoupons" style="max-width: 100%;">
                    <option v-for="coupon in coupons" :key="coupon.id" :value="coupon.id">
                      {{ coupon.title + ' - ' + coupon.code }}
                    </option>
                  </select>
                </div>
                <div>
                  <div class="pad-16" />
                  <button @click="mailPreview" :disabled="!checkPreview" class="button tiny bgcolor-blue color-white" :class="{disabled: !checkPreview}">
                    E-Mail Vorschau
                  </button>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="col-sp-6 col-sd-6 row-sp-12">
        <div class="grid-12">
          <div @click="pushActive = !pushActive; saved = false" :class="{active: pushActive}" class="button color-white br-0 col-sp-12 col-sd-12 row-sp-12">
            Push
          </div>
          <div class="col-sp-12 col-sd-12 row-sp-12 pad-16">
            <transition name="filter-master">
              <div v-if="pushActive">
                <div class="pad-16" />
                <div class="field simple">
                  <label for="pushTitle">Titel*</label>
                  <input type="text" v-model="push.title" name="pushTitle" maxlength="64" style="max-width: calc(100% - 16px);">
                </div>
                <div class="pad-16" />
                <div class="field simple">
                  <label for="pushBody">Text*</label>
                  <input type="text" v-model="push.body" name="pushBody" maxlength="256" style="max-width: calc(100% - 16px);">
                </div>
                <div class="pad-16" />
                <div class="field simple">
                  <label for="pushLink">Link</label>
                  <input type="text" v-model="push.link" name="pushLink" style="max-width: calc(100% - 16px);">
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <button class="col-sp-12 col-sd-12 row-sp-12 bgcolor-blue color-white button br-0" @click="$router.push({name: 'NotificationService'})">
        Zurück
      </button>
      <div class="pad-8" />
      <button @click="save" class="col-sp-12 col-sd-12 row-sp-12 bgcolor-blue color-white button br-0" :disabled="!checkSend || saved" :class="{disabled: !checkSend || saved}">
        {{ saved ? 'gespeichert' : 'speichern' }}
      </button>
    </div>
  </section>
  <VueModal :show="mailPreviewModal" approve="schließen" @approve="mailPreviewModal = false" @close="mailPreviewModal = false">
    <div v-html="mailPreviewHTML" />
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData, stringToDate} from "@/modules/main";

export default {
  name: "birthday",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      saved: true,
      events: [],
      coupons: [],
      mailTemplates: [],
      mailPreviewModal: false,
      mailPreviewHTML: '',
      mailActive: false,
      pushActive: false,
      mail: {
        templateId: 1,
        subject: '',
        greeting: '',
        body: '',
        ending: '',
        events: [],
        coupons: []
      },
      push: {
        title: '',
        body: ''
      }
    }
  },
  computed: {
    checkSend(){
      return (this.mailActive || this.pushActive) && (!this.mailActive || (this.mail.subject.length && this.mail.greeting.length && this.mail.body.length && this.mail.ending.length)) && (!this.pushActive || (this.push.title.length && this.push.body.length))
    },
    checkPreview(){
      return this.mail.greeting.length && this.mail.body.length && this.mail.ending.length
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      const _this = this
      aSyncData('notificationService/getBirthdayData', {organizerId: this.config.organizerId})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.events = r.data.events
              _this.coupons = r.data.coupons
              _this.mailTemplates = r.data.mailTemplates
              if(r.data.birthdayMailTemplate){
                _this.mailActive = r.data.birthdayMailTemplate.mailActive
                _this.pushActive = r.data.birthdayMailTemplate.pushActive
                _this.mail = JSON.parse(r.data.birthdayMailTemplate.mail)
                _this.push = JSON.parse(r.data.birthdayMailTemplate.push)
              }
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    dateString(eventDate){
      let date = stringToDate(eventDate)
      return date.getDate() + '.' + (date.getMonth()+1)
    },
    mailPreview(){
      const _this = this
      aSyncData('notificationService/mailPreview', {
        organizerId: this.config.organizerId,
        mailTemplate: this.mail.template,
        mailGreeting: this.mail.greeting,
        mailBody: this.mail.body,
        mailEnding: this.mail.ending,
        mailEvents: JSON.stringify(this.mail.events),
        mailCoupons: JSON.stringify(this.mail.coupons)
      })
          .then(r => {
            if(r.status === 250){
              _this.mailPreviewHTML = r.data
              _this.mailPreviewModal = true
            }else{
              console.log(r)
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    save(){
      const _this = this
      aSyncData('notificationService/saveBirthdayTemplate', {
        organizerId: this.config.organizerId,
        mailActive: this.mailActive,
        pushActive: this.pushActive,
        mail: JSON.stringify(this.mail),
        push: JSON.stringify(this.push)
      })
          .then(r => {
            console.log(r)
            if(r.status === 250)_this.saved = true
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style scoped>
#notificationService h1{
  font-size: 2em;
}
.active{
  background-color: #2068ff;
}
button, .button{
  text-align: center;
}
</style>