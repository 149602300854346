<template>
  <div class="grid-12">
    <div class="col-sp-4 col-tb-4 row-sp-12">
      {{ date }}
    </div>
    <div class="col-sp-4 col-tb-4 row-sp-12">
      {{ (-transaction.amount/100).toFixed(2) }} €
    </div>
    <div class="col-sp-4 col-tb-4 row-sp-12">
      <button @click="detailsModal = !detailsModal">
        Details
      </button>
    </div>
  </div>
  <VueModal :show="detailsModal" approve="ok" @close="detailsModal = false" @approve="detailsModal = false">
    <div>
      <div class="o-pf-container-bg o-pf-container-bg--pd-mg">
        <ul class="o-pf-list">
          <li v-for="(value, propName) in metaMe" :key="propName" class="o-pf-list__item o-pf-list__item--border o-pf-list__item--line-height">
            <label> {{ propName }}: </label>
            <div class="pull-right">
              {{ value }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </VueModal>
</template>

<script>
import {dateToString, stringToDate} from "@/modules/main";

export default {
  name: "transaction",
  props: {
    transaction: Object
  },
  data(){
    return {
      detailsModal: false
    }
  },
  computed: {
    date(){
      return dateToString(stringToDate(this.transaction.date))
    },
    metaMe(){
      try {
        return JSON.parse(this.transaction.meta)
      }catch (e) {
        return {}
      }
      console.log(this.transaction.meta)
    }
  }
}
</script>

<style scoped>
.o-pf-container-bg {
  background-color: #FFFFFF;
}

.o-pf-container-bg--pd-mg {
  padding: 15px;
  margin-bottom: 20px;
}

.o-pf-container-bg--mg {
  margin-bottom: 20px;
}


.o-pf-list {
  z-index: 1;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.o-pf-list__item {
  padding: 3px 0px;
}

.o-pf-list__item--icon {
  width: 16px;
  height: 16px;
  padding-right: 5px;
  color: #C30017;
}

.o-pf-list__item--icon-q {
  width: 16px;
  height: 16px;
  line-height: 0.9;
  text-align: -webkit-center;
  border: 1px solid #414141;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.o-pf-list__item--border {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #F5F5F5;
}

.o-pf-list__item--line-height {
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 3;
}



.o-pf-list + label,
.o-pf-list div {
  -webkit-box-flex: 1;
  flex: 1;
  display: inline-table;
  word-break: break-all;
}

.pull-right {
  float: right !important;
}
</style>